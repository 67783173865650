import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { Infrastructure } from '@app/@shared/model/infrastructure.model';
import { InfrastructureService } from '@app/@shared/services/map-elements-services/infrastructure.service';
import { DeviceService } from '@app/@shared/services/map-elements-services/device.service';
import { PlatformService } from '@app/auth/platform.service';
import { TranslateService } from '@ngx-translate/core';
import { plainToClass } from 'class-transformer';
import { firstValueFrom } from 'rxjs';

@Component({
  selector: 'app-infrastructure-home',
  templateUrl: './infrastructure-home.component.html',
  styleUrls: ['./infrastructure-home.component.scss'],
})

export class InfrastructureHomeComponent implements OnInit {
  loading: boolean = false;
  loadingModal: boolean = false;
  selectedInfrastructure: Infrastructure;
  infrastructureList: Infrastructure[] = [];
  modalTitle: string;
  infrastructureEditableData: { label, field }[] = [];

  popups = {
    confirmDelete: false,
    createOrEdit: false,
  };
  filter: any;
  entityTypeModels: any[];

  constructor(
    private activatedRoute: ActivatedRoute,
    private platformService: PlatformService,
    private translateService: TranslateService,
    private infrastructureService: InfrastructureService,
  ) { }
  ngOnInit(): void {
    this.infrastructureEditableData = [
      { label: 'infrastructure.grid-header.id', field: 'ID' },
      { label: 'infrastructure.grid-header.name', field: 'Name' },
      { label: 'infrastructure.grid-header.description', field: 'Description' },
    ]
    this.reload();
  }
  async reload() {
    this.loadingModal = true;
    this.infrastructureService.getWithFilter().subscribe(
      (valor) => {
        this.infrastructureList = valor;
        this.entityTypeModels = this.getElementTypeNames();
        this.loadingModal = false;
      }
    );
  }
  closeModal() {
    this.popups.createOrEdit = false;
    this.selectedInfrastructure = undefined;
  }
  openModalAddInfrastructure() {
    const platformID: number = this.platformService.platformID;
    this.modalTitle = this.translateService.instant('devices.add-device');
    this.selectedInfrastructure = plainToClass(Infrastructure, { platformID });
    this.popups.createOrEdit = true;
  }
  // async deleteInfrastructure(infrastructure: Infrastructure) {
  //   try {
  //     await firstValueFrom(this.infrastructureService.delete(infrastructure?.infrastructureID));
  //   } catch (ex) {
  //     this.popups.confirmDelete = false;
  //     return;
  //   } finally {
  //     this.popups.confirmDelete = false;
  //     await this.reload();
  //     this.selectedInfrastructure = undefined;
  //   }
  // }

  async deleteInfrastructure(infrastructure: Infrastructure) {
    this.popups.confirmDelete = false;

    await this.reload();
    this.selectedInfrastructure = undefined;
  }
  
  async openModalEditInfrastructure(infrastructure: Infrastructure) {
    this.modalTitle = this.translateService.instant('infrastructure.edit-device');
    this.modalTitle += ` #${infrastructure.infrastructureID}`;
    this.loadingModal = true;
    this.selectedInfrastructure = await firstValueFrom(this.infrastructureService.getByID(infrastructure?.infrastructureID));
    this.loadingModal = false;
    this.popups.createOrEdit = true;
  }
  openPromptDeleteInfrastructure(infrastructure: Infrastructure) {
    this.selectedInfrastructure = infrastructure;
    this.popups.confirmDelete = true;
  }
  async saveInfrastructure(infrastructure: Infrastructure) {
    this.loadingModal = true;

    try {
      if (this.selectedInfrastructure?.infrastructureID) {
        const updatedDevice = await firstValueFrom(this.infrastructureService.update(infrastructure));
        const index = this.infrastructureList.findIndex(x => x.infrastructureID === updatedDevice.infrastructureID);
        this.infrastructureList[index] = updatedDevice;
      } else {
        const newInfrastructure = await firstValueFrom(this.infrastructureService.create(infrastructure));
        this.infrastructureList = [newInfrastructure, ...this.infrastructureList];
      }
    } catch (ex) {
      return;
    } finally {
      this.loadingModal = false;
      this.popups.createOrEdit = false;
      this.selectedInfrastructure = undefined;
    }
    console.log(this.infrastructureList);
  }

  search($event) {
    this.filter = { ...$event };
  }

  getElementTypeNames(): { name: string, elementTypeID: number }[] {
    const elementTypes = this.infrastructureList
      .map(infrastructure => {
        return {
          name: infrastructure.elementType?.name,
          elementTypeID: infrastructure.elementType?.elementTypeID
        };
      })
      .filter(element => element.name !== undefined && element.elementTypeID !== undefined);

    const uniqueElementTypes = new Map(
      elementTypes.map(item => [item.elementTypeID, item])
    );

    return Array.from(uniqueElementTypes.values());
  }

}