import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { Infrastructure } from '@app/@shared/model/infrastructure.model';
import { Device } from '@app/@shared/model/device.model';
import { ElementType } from '@app/@shared/model/elementType.model';
import { Splitter } from '@app/@shared/model/splitter.model';
import { SplitterType } from '@app/@shared/model/splitterType.model';
import { DataSourceService } from '@app/@shared/services/aux-services/datasources.service';
import { TranslateService } from '@ngx-translate/core';
import { plainToInstance } from 'class-transformer';
import DataSource from 'devextreme/data/data_source';
import { ElementTypeService } from '@app/@shared/services/element-type.service';
import { firstValueFrom } from 'rxjs';
import { InfrastructureService } from '@app/@shared/services/map-elements-services/infrastructure.service';

@Component({
  selector: 'app-create-edit-infrastructure-modal',
  templateUrl: './create-edit-infrastructure-modal.component.html',
  styleUrls: ['./create-edit-infrastructure-modal.component.scss'],
})

export class CreateEditInfrastructureModalComponent implements OnInit {

  labels = {
    model: '',
    infrastructure: '',
    name: '',
  };



  infrastructureDataSource: DataSource;
  elementTypeDataSource: DataSource;
  splitters: Splitter[] = [];
  splitterTypes: SplitterType[] = [];
  infrastructure: Infrastructure;
  device: Device;
  ports: any[] = [];

  address: string;

  disableSave: boolean = true;
  @Input() infrastructureList: any[];
  @Input() selectedInfrastructure: Infrastructure;
  @Output() save = new EventEmitter<Infrastructure>();
  @Output() cancel = new EventEmitter<void>();
  equipmentPortData: any;
  portSelected: any[] = [];
  currentIndex: number = 0;
  testports: any[] = [];
  portsInODF: any[] = [];

  constructor(
    private allDatasources: DataSourceService,
    private translateService: TranslateService,
    private elementTypeService: ElementTypeService,
    private infrastructureService: InfrastructureService
  ) { }

  ngOnInit(): void {
    this.setMandatoryLabels();

    this.assingServicesToEquipmentPorts()
    const infrastructure: Infrastructure[] = this.allDatasources.allInfrastructures;
    this.infrastructureDataSource = new DataSource({ store: infrastructure });

    this.selectedInfrastructure.equipment = this.selectedInfrastructure.equipment.map((equipment, index) => {
      return {
        ...equipment,
        rowIndex: index + 1,
      };
    });
    this.loadElementTypes();
  }

  async assingServicesToEquipmentPorts() {
    this.equipmentPortData = await firstValueFrom(this.infrastructureService.getODF(this.selectedInfrastructure.infrastructureID));

    this.selectedInfrastructure.equipment.forEach((equipment, equipmentIndex) => {
      equipment.equipmentPort.forEach((port, portIndex) => {
        for (let i = 0; i < this.equipmentPortData.length; i++) {
          const foundPort = this.equipmentPortData[i].children.find((dataPort: any) => dataPort.fiberID === port.fiberID);

          if (foundPort) {
            this.selectedInfrastructure.equipment[equipmentIndex].equipmentPort[portIndex].service = foundPort.descr;
          }
        }
      });
    });

  }
  handleSeletedODF(data: any): void {
    const equipment = data.key;

    if (equipment && equipment.equipmentPort && !this.portsInODF[equipment.equipmentID]) {
      this.portsInODF[equipment.equipmentID] = this.removeAttributes(equipment.equipmentPort);
    }
  }

  getEquipmentPorts(equipment: any): any[] {
    return this.portsInODF[equipment.key.equipmentID] || [];
  }

  removeAttributes(data: any): any[] {
    return data.map(item => {
      const { equipmentPortAttributes, equipmentID, equipmentPortID, fiberID,
        portIDToShow = item.internalPortID !== 0 ? item.internalPortID : item.fiberID,
        ...rest } = item;
      return {
        name: rest.name,
        fiber: rest.fiber.name,
        service: rest.service || ""

      }
    });
  }

  async loadElementTypes() {
    this.elementTypeService.getElementsTypeWithFilter().subscribe(
      (valor) => {
        this.elementTypeDataSource = valor;
      }
    );
  }

  setMandatoryLabels() {
    this.labels = {
      model: this.translateService.instant('infrastructure.add-edit.model') + '*',
      infrastructure: this.translateService.instant('infrastructure.add-edit.infrastructure') + '*',
      name: this.translateService.instant('infrastructure.add-edit.name') + '*',
    };
  }

  fullAddressChange(fullAddress: any) {
    // console.log('Infrastructure: ', this.selectedInfrastructure);
    // this.device.deviceAttributes.address = `${fullAddress.street} ${fullAddress.number}`;
    this.selectedInfrastructure.locationData = fullAddress.location;
    this.updateDisableSaveButton();
  }

  infrastructureChange(event: { selectedItem: Infrastructure }) {
    this.updateDisableSaveButton();
  }

  elementTypeChange(event: { selectedItem: ElementType }) {
    const selectedElementType = event.selectedItem;
    this.selectedInfrastructure.elementTypeID = selectedElementType.elementTypeID;

    this.updateDisableSaveButton();
  }

  nameChange(event: { value: string }) {
    this.selectedInfrastructure.name = event.value;
    this.updateDisableSaveButton();
  }

  odfNameChange(event: any): void {
    event.key.name = event.newData.name;
    // this.selectedInfrastructure.equipment;
  }

  updateDisableSaveButton() {
    this.disableSave = !this.selectedInfrastructure.elementTypeID || !this.selectedInfrastructure.infrastructureID || !this.selectedInfrastructure.name || !this.selectedInfrastructure.locationData;
    this.disableSave = !this.selectedInfrastructure.elementTypeID || !this.selectedInfrastructure.infrastructureID || !this.selectedInfrastructure.name;
  }

  saveInfrastructure() {
    this.selectedInfrastructure.__modified = true;
    this.save.emit(this.selectedInfrastructure);
  }

  onRowPrepared(e: any) {
    if (e.rowType === 'data') {
      e.data.rowIndex = e.rowIndex;
    }
  }

}
