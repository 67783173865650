import { Injectable } from '@angular/core';
import { plainToInstance } from 'class-transformer';
import { delay, map, Observable, of } from 'rxjs';

import { Client } from '@app/@shared/model/client.model';
import { Cons } from '../cons/cons';
import { CoordinatesServices } from './aux-services/coordinates-service';
import { MainService } from './main.service';
import { FeasibilityStudy } from '../model/feasibilityStudy.model';
import { PlatformService } from '@app/auth/platform.service';
import { InvokerSystem } from '../model/invokerSystem.model';
import { IQueryViewConfiguration } from '../model/interface/iQuery-view-configuration.model';
import { GeoElement } from '../model/geoElement.model';

@Injectable({ providedIn: 'root' })
export class FeasibilityService {

  constructor(
    private coordinatesServices: CoordinatesServices,
    private main: MainService,
    private platformService: PlatformService
  ) {}

  getClientById(id: any): Observable<Client> {
    this.main.chooseAPI(Cons._FEASIBILITY_STUDY);

    return this.main.getByID(Cons._CLIENT, id).pipe(
      map((res) => {
        try {
          res.commonID = res.feasibilityID;
          res.entityName = Cons._CLIENT;
          return plainToInstance(Client, res);
        } catch (ex) {
          return (<any>res)._body.toString();
        }
      })
    );
  }

  getFeasibilityById(id: any): Observable<FeasibilityStudy> {
    this.main.chooseAPI(Cons._FEASIBILITY_STUDY);

    return this.main.getByID(Cons._FEASIBILITY_STUDY, id).pipe(
      map((res) => {
        try {
          res.commonID = res.feasibilityStudyID;
          res.entityName = Cons._FEASIBILITY_STUDY;
          return plainToInstance(FeasibilityStudy, res);
        } catch (ex) {
          return (<any>res)._body.toString();
        }
      })
    );
  }

  getClientsWithFilter(entity?: any): Observable<Client[]> {
    this.main.chooseAPI(Cons._FEASIBILITY_STUDY);
    
    return this.main.getWithFilter(Cons._CLIENT, entity).pipe(
      map((res) => {
        try {
          return res.responseData.map(x => plainToInstance(Client, x));
        } catch (ex) {
          return <string>(<any>res)._body.toString();
        }
      })
    );
  }

  getFeasibilityWithFilter(entity?: any): Observable<FeasibilityStudy[]> {
    this.main.chooseAPI(Cons._FEASIBILITY_STUDY);
    
    return this.main.getWithFilter(Cons._FEASIBILITY_STUDY, entity).pipe(
      map((res) => {
        try {
          return res.responseData.map((x) => {
            plainToInstance(FeasibilityStudy, x);
            x.commonID = x.feasibilityStudyID;
            x.entityName = Cons._FEASIBILITY_STUDY;
            x.clientName = x.client?.name;
            return <FeasibilityStudy>x;
          });
        } catch (ex) {
          return <string>(<any>res)._body.toString();
        }
      })
    );
  }

  getInvokerSystemWithFilter(entity?: any): Observable<InvokerSystem[]> {
    this.main.chooseAPI(Cons._FEASIBILITY_STUDY);
    
    return this.main.getWithFilter(Cons._INVOKERSYSTEM, entity).pipe(
      map((res) => {
        try {
          return res.responseData.map((x) => {
            plainToInstance(InvokerSystem, x);
            x.commonID = x.invokerSystemCode;
            x.entityName = Cons._INVOKERSYSTEM;
            return <InvokerSystem>x;
          });
        } catch (ex) {
          return <string>(<any>res)._body.toString();
        }
      })
    );
  }

  getCoveragePolygons(xyCoordinates?: any[], locationCriteria?): Observable<any> {
    this.main.chooseAPI(Cons._FEASIBILITY_STUDY);
    
    const queryViewConfiguration: IQueryViewConfiguration = {
      xyCoordinates,
      locationCriteria,
    };

    return this.main
      .queryView(`${Cons._FEASIBILITY_STUDY}/${this.platformService.platformID}/CoverageArea`, {}, queryViewConfiguration)
      .pipe(
        map((res) => {
          try {
            return res.responseData.map((x) => {
              x.commonID = x.geoElementID;
              x.entityName = Cons._GEOELEMENT;
              return <GeoElement>x;
            });
          } catch (ex) {
            return <string>(<any>res)._body.toString();
          }
        })
      );
  }

  saveClient(id : number, client : Client) {
    this.main.chooseAPI(Cons._FEASIBILITY_STUDY);
    client.platformID = this.platformService.activePlatform.platformID;

    return this.main.put(`${Cons._CLIENT}/${id}`, undefined, client).pipe(
      map((res) => {
        try {
          return res.responseData;
        } catch (ex) {
          return <string>(<any>res)._body.toString();
        }
      })
    );
  }

  checkFeasibility(feasibilityStudy: FeasibilityStudy) {
    this.main.chooseAPI(Cons._FEASIBILITY_STUDY);

    const locationData = feasibilityStudy.locationData;

    if (locationData.format === 'WKT') {
      locationData.format = 'GEOJSON';
      const { coordinates } = this.coordinatesServices.coordsToGeoJson(locationData.coordinates.values);
      locationData.coordinates.values = [{ x: coordinates[0], y: coordinates[1] }];;
    }

    if(!feasibilityStudy.platformID || feasibilityStudy.platformID == 0){
      feasibilityStudy.platformID = this.platformService.getActivePlatform()?.platformID;
    }
    
    return this.main.post('FeasibilityStudy', undefined, feasibilityStudy).pipe(
      map((res) => {
        try {
          return res.responseData.map((x) => {
            plainToInstance(FeasibilityStudy, x);
            x.commonID = x.feasibilityStudyID;
            x.entityName = Cons._FEASIBILITY_STUDY;
            x.clientName = x.client?.name;
            return <FeasibilityStudy>x;
          });
        } catch (ex) {
          return <string>(<any>res)._body.toString();
        }
      })
    );
  }

  allocateFeasibility(entity : FeasibilityStudy) {
    this.main.chooseAPI(Cons._FEASIBILITY_STUDY);

    return this.main.put(`${Cons._FEASIBILITY_STUDY}/Allocation/${entity.platformID}/${entity.feasibilityStudyID}`, undefined, {}).pipe(
      map((res) => {
        try {
          return res.responseData;
        } catch (ex) {
          return <string>(<any>res)._body.toString();
        }
      })
    );
  }
}
