<div #content id="top" class="device-box-container py-4 px-5">




  <dx-load-panel shadingColor="rgba(0,0,0,0.4)" [visible]="loading" [showIndicator]="true" [showPane]="true"
    [shading]="true" [closeOnOutsideClick]="false">
  </dx-load-panel>

  <div class="py-3 mt-1 mb-3" fxLayout="row" style="border-bottom: solid 1px gainsboro;">
    <div fxFlex="30">
      <strong class="device-title-header">{{ 'comp.search-panel.devices' | translate }}</strong>
    </div>

    <div *ngIf="devices && devices.length > 0" fxFlex="70" fxLayout="row" fxLayoutAlign="space-between center">
      <dx-button fxFlex="20" *ngIf="devices.length > 1" icon="fas fa-file-download" class="button main-background-theme"
        [text]="'device.box.download-all' | translate" (click)="downloadAllDevicesGraph()">
      </dx-button>
      <div fxFlex="75" fxLayout="row">
        <div *ngFor="let d of devices" class="tag-box" (click)="openDiagram(d)">
          {{d.name}}
        </div>
      </div>
      <!-- <dx-tag-box fxFlex="75" [elementAttr]="{ class: 'remove-tagbox-border remove-dx-tag-button' }" [items]="devices"
        [value]="devices" [searchEnabled]="true" [showClearButton]="false"
        [placeholder]="'device.box.device-selector' | translate" (onValueChanged)="deviceChange($event)"
        valueExpr="deviceID" displayExpr="name">
      </dx-tag-box> -->
    </div>
  </div>

  <div *ngFor="let selectedDevice of selectedDevices;">

    <div fxLayout="column" style="border-bottom: dashed 1px gray;">
      <strong class="device-title">{{ selectedDevice.device.name }}</strong>

      <div fxLayout="row" class="my-3">
        <dx-button icon="fas fa-external-link-alt" class="button main-background-theme"
          [text]="'device.box.view-device-box-v1' | translate" (click)="viewDeviceBoxV1(selectedDevice.device)">
        </dx-button>

        <dx-button icon="fas fa-file-download" class="button main-background-theme mx-3"
          [text]="'general.download' | translate" (click)="downloadGraph(selectedDevice)">
        </dx-button>


      </div>
    </div>

    <dx-tab-panel [(selectedIndex)]="selectedTab" (onOptionChanged)="onTabChanged()">
      <dxi-item title="Imagen de Box 1"></dxi-item>
      <dxi-item title="Imagen de Box 2"></dxi-item>
    </dx-tab-panel>

    <div *ngIf="selectedTab==0">

      <div class="floating-container">

        <div class="search">
          <input type="text" [(ngModel)]="searchTerm" placeholder="Buscar..." (input)="highlightMatches()" />

          <div class="buttons-container">
            <dx-button icon="fas fa-angle-left" (click)="previousMatch()"></dx-button>
            <p class="match-info" *ngIf="match.length > 0">{{ currentMatchIndex + 1 }}/{{ match.length }}</p>
            <dx-button icon="fas fa-angle-right" (click)="nextMatch()"></dx-button>
          </div>

        </div>

        <div class="fiberNameButtonContainer" fxLayout="row wrap" fxLayoutAlign="center center">
          <div *ngFor="let name of concatenatedFibers; let i = index" fxFlex="0 1 auto">
            <dx-button class="fiberNameButton" [ngClass]="{'fiber-diagram-visible': isFiberDiagramVisible(i)}"
              (click)="scrollToFiberDiagram(i)">
              <div>
                <div>{{ name.name1 }}</div>
                <p></p>
                <br />
                <p>{{ 'general.to' | translate }}</p>
                <br />
                <div>{{ name.name2 }}</div>
              </div>
            </dx-button>
          </div>
        </div>
      </div>

      <div class="mt-3" fxLayout="column" fxLayoutAlign="start center"
        *ngFor="let fiberData of selectedDevice.fiberData; let i = index">




        <div class="mt-4">
          <dx-button class="button main-background-theme mb-2"
            [icon]="fiberData.hideUnselectedFibers ? 'fas fa-eye' : 'far fa-eye-slash'"
            [text]="(fiberData.hideUnselectedFibers ? 'device.box.show-unselected-fibers' : 'device.box.hide-unselected-fibers') | translate"
            (click)="fiberData.hideUnselectedFibers = !fiberData.hideUnselectedFibers">
          </dx-button>
          <dx-data-grid [dataSource]="fiberData.gridFibers" [(selectedRowKeys)]="fiberData.selectedFibers"
            [showBorders]="true" [showRowLines]="true" [allowColumnResizing]="true">
            <dxo-selection selectAllMode="allPages" showCheckBoxesMode="always" mode="multiple"></dxo-selection>

            <dxo-pager [visible]="true" [displayMode]="'full'" [showPageSizeSelector]="false" [showInfo]="false"
              [showNavigationButtons]="true">
            </dxo-pager>



            <dxi-column dataType="string" [caption]="'device.box.grid-header.side' | translate"
              dataField="side"></dxi-column>





            <dxi-column dataField="mainAttr" [caption]="'device.box.grid-header.fiber-name' | translate"
              cellTemplate="mainTemplate">
              <div *dxTemplate="let cellInfo of 'mainTemplate'">
                {{ cellInfo.value.name }}
              </div>
            </dxi-column>

            <dxi-column dataType="string" [caption]="'device.box.grid-header.description' | translate"
              dataField="mainAttr.description"></dxi-column>

            <dxi-column [caption]="'device.box.grid-header.fiber-order' | translate" dataField="mainAttr.fiberOrder"
              width="100"></dxi-column>
            <dxi-column dataField="bufferIndex" [caption]="'NB2' | translate" width="50"
              cellTemplate="bufferIndexTemplate">
              <div *dxTemplate="let cellInfo of 'bufferIndexTemplate'">
                {{ cellInfo.data.bufferIndex }}
              </div>
            </dxi-column>

            <dxi-column dataField="mainAttr" [caption]="'BFF2' | translate" width="60" cellTemplate="bufferTemplate">
              <div *dxTemplate="let cellInfo of 'bufferTemplate'">
                {{cellInfo.value.buffer}}
              </div>
            </dxi-column>

            <dxi-column [caption]="'NF2' | translate" cellTemplate="fiberIndexTemplate" width="50">
              <div *dxTemplate="let cellInfo of 'fiberIndexTemplate'">
                <ng-container *ngIf="cellInfo.data && cellInfo.data.parent && cellInfo.data.parent.children">
                  {{ findFiberIndex(cellInfo.data.fiberID, cellInfo.data.parent.children) }}
                </ng-container>
              </div>
            </dxi-column>

            <dxi-column dataField="mainAttr" [caption]="'F2' | translate" width="50" cellTemplate="fiberTemplate">
              <div *dxTemplate="let cellInfo of 'fiberTemplate'">
                {{cellInfo.value.color}}
              </div>
            </dxi-column>

            <dxi-column dataType="string" [caption]="'device.box.grid-header.services' | translate"
              dataField="mainAttr.service"></dxi-column>


            <dxi-column dataField="importAttr" [caption]="'device.box.grid-header.fiber-name' | translate"
              cellTemplate="importTemplate">
              <div *dxTemplate="let cellInfo of 'importTemplate'">
                <div *ngIf="cellInfo.value && cellInfo.value.name != ''">{{ cellInfo.value.name }}</div>
              </div>
            </dxi-column>

            <dxi-column dataType="string" [caption]="'device.box.grid-header.description' | translate"
              dataField="importAttr.description"></dxi-column>


            <dxi-column [caption]="'device.box.grid-header.fiber-order' | translate" dataField="importAttr.fiberOrder"
              width="100"></dxi-column>
            <dxi-column dataType="string" [caption]="'device.box.grid-header.side' | translate"
              dataField="importSide"></dxi-column>
          </dx-data-grid>
        </div>



        <div class="mt-4 mt-xl-0" style="width: 100%;" #fiberDiagram [id]="'fiber-diagram-' + i">
          <app-fiber-diagram [serviceName]="searchTerm" [data]="fiberData.fibers"
            [(selectedData)]="fiberData.selectedFibers" [hideUnselectedData]="fiberData.hideUnselectedFibers"
            (fiberNameOutput)="handleFiberName($event)"></app-fiber-diagram>
        </div>

      </div>

    </div>

    <div class="mt-3" fxLayout="column" fxLayoutAlign="start center" *ngFor="let fiberData of selectedDevice.fiberData">
      <div class="mt-4" *ngIf="selectedTab === 1">
        <dx-button class="button main-background-theme mb-2"
          [icon]="fiberData.hideUnselectedFibers ? 'fas fa-eye' : 'far fa-eye-slash'"
          [text]="(fiberData.hideUnselectedFibers ? 'device.box.show-unselected-fibers' : 'device.box.hide-unselected-fibers') | translate"
          (click)="fiberData.hideUnselectedFibers = !fiberData.hideUnselectedFibers">
        </dx-button>
        <dx-data-grid [dataSource]="fiberData.gridFibers" [(selectedRowKeys)]="fiberData.selectedFibers"
            [showBorders]="true" [showRowLines]="true" [allowColumnResizing]="true">
            <dxo-selection selectAllMode="allPages" showCheckBoxesMode="always" mode="multiple"></dxo-selection>

            <dxo-pager [visible]="true" [displayMode]="'full'" [showPageSizeSelector]="false" [showInfo]="false"
              [showNavigationButtons]="true">
            </dxo-pager>



            <dxi-column dataType="string" [caption]="'device.box.grid-header.side' | translate"
              dataField="side"></dxi-column>





            <dxi-column dataField="mainAttr" [caption]="'device.box.grid-header.fiber-name' | translate"
              cellTemplate="mainTemplate">
              <div *dxTemplate="let cellInfo of 'mainTemplate'">
                {{ cellInfo.value.name }}
              </div>
            </dxi-column>

            <dxi-column dataType="string" [caption]="'device.box.grid-header.description' | translate"
              dataField="mainAttr.description"></dxi-column>

            <dxi-column [caption]="'device.box.grid-header.fiber-order' | translate" dataField="mainAttr.fiberOrder"
              width="100"></dxi-column>
            <dxi-column dataField="bufferIndex" [caption]="'NB2' | translate" width="50"
              cellTemplate="bufferIndexTemplate">
              <div *dxTemplate="let cellInfo of 'bufferIndexTemplate'">
                {{ cellInfo.data.bufferIndex }}
              </div>
            </dxi-column>

            <dxi-column dataField="mainAttr" [caption]="'BFF2' | translate" width="60" cellTemplate="bufferTemplate">
              <div *dxTemplate="let cellInfo of 'bufferTemplate'">
                {{cellInfo.value.buffer}}
              </div>
            </dxi-column>

            <dxi-column [caption]="'NF2' | translate" cellTemplate="fiberIndexTemplate" width="50">
              <div *dxTemplate="let cellInfo of 'fiberIndexTemplate'">
                <ng-container *ngIf="cellInfo.data && cellInfo.data.parent && cellInfo.data.parent.children">
                  {{ findFiberIndex(cellInfo.data.fiberID, cellInfo.data.parent.children) }}
                </ng-container>
              </div>
            </dxi-column>

            <dxi-column dataField="mainAttr" [caption]="'F2' | translate" width="50" cellTemplate="fiberTemplate">
              <div *dxTemplate="let cellInfo of 'fiberTemplate'">
                {{cellInfo.value.color}}
              </div>
            </dxi-column>

            <dxi-column dataType="string" [caption]="'device.box.grid-header.services' | translate"
              dataField="mainAttr.service"></dxi-column>


            <dxi-column dataField="importAttr" [caption]="'device.box.grid-header.fiber-name' | translate"
              cellTemplate="importTemplate">
              <div *dxTemplate="let cellInfo of 'importTemplate'">
                <div *ngIf="cellInfo.value && cellInfo.value.name != ''">{{ cellInfo.value.name }}</div>
              </div>
            </dxi-column>

            <dxi-column dataType="string" [caption]="'device.box.grid-header.description' | translate"
              dataField="importAttr.description"></dxi-column>


            <dxi-column [caption]="'device.box.grid-header.fiber-order' | translate" dataField="importAttr.fiberOrder"
              width="100"></dxi-column>
            <dxi-column dataType="string" [caption]="'device.box.grid-header.side' | translate"
              dataField="importSide"></dxi-column>
          </dx-data-grid>
      </div>
      <div *ngIf="selectedTab === 1" class="mt-4 mt-xl-0" style="width: 100%;">
        <app-d3-circular-hierarchy-tree  [id]="fiberData.id" [data]="fiberData.fibers"
          [(selectedData)]="fiberData.selectedFibers" [hideUnselectedData]="fiberData.hideUnselectedFibers"
          (componentReady)="deviceReady(selectedDevice, fiberData)"></app-d3-circular-hierarchy-tree>
      </div>
      <!-- <app-fiber-diagram *ngIf="fiberData.fibers.length < 20" [serviceName]="searchTerm" [data]="fiberData.fibers"
        [(selectedData)]="fiberData.selectedFibers" [hideUnselectedData]="fiberData.hideUnselectedFibers"
        (fiberNameOutput)="handleFiberName($event)"></app-fiber-diagram> -->
    </div>
  </div>

  <div *ngIf="noDevicesFound">No devices found</div>

  <div class="floating-button-container" *ngIf="selectedTab==0">
    <dx-button icon="fas fa-angle-up" class="floating-button" (click)="scrollToTop()">
    </dx-button>
  </div>

</div>