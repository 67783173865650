class PREFERENCES{
  public _HIDE_HELP_ON_INIT = "HIDE_HELP_ON_INIT";
  public MAX_ELEMENTS_PER_LAYER = 5000;
}

class OLSTYLES{
  public $primaryColor = "#771c5a";
  public $primaryBackground = "#771c5a1c";
  public $acceptedColor = "#1c5a1c";
  public $acceptedBackground = "#ddedcf";
  public $traceDefaultColor = 'blue';
}

class SrIDs{
  public S3857 = "3857";
  public S4326 = "4326";
}

class STATES_CODES{
  _FEASIBLE : number = 1;
  _NOT_FEASIBLE : number = 2;
  _RESERVED : number = 3;
  _ACTIVE : number = 4
}

class RegionalData{
  getRegionalDatasource(){
    return [ { cultureID: 'es-AR', name: 'es-AR', icon: 'assets/icons/lang/es-AR.svg' }, { cultureID: 'en-US', name: 'en-US', icon: 'assets/icons/lang/en-US.svg'}]
  }
}

export class Cons {
  
  public static _TRACE = 'TRACE';
  public static _DEVICE = 'DEVICE';
  public static _CONTAINER = 'CONTAINER';
  public static _CONNECTION = 'CONNECTION';
  public static _INFRASTRUCTURE = 'INFRASTRUCTURE';
  public static _SERVICE = 'SERVICE';
  public static _SPLITTER = 'SPLITTER';
  public static _SERVICETRACE = 'SERVICETRACE';
  public static _AUTHENTICATION = 'AUTHENTICATION';
  public static _USERPROFILE = 'UserProfile';
  public static _PLOT = 'PLOT';
  public static _PLATFORM_PLOT = 'PLATFORMPLOT';
  public static _FIBER = 'FIBER';
  public static _FILE = 'FILE';
  public static _FEASIBILITY_STUDY = 'FEASIBILITYSTUDY';
  public static _CLIENT = 'Client';
  public static _INVOKERSYSTEM = 'INVOKERSYSTEM';
  public static _GEOELEMENT = 'GEOELEMENT';
  public static _SERVICECONFIG = 'SERVICECONFIG';
  public static _EQUIPMENT = 'EQUIPMENT';
  public static _ELEMENTTYPE = 'ElementType';
  public static _SPLITTERTYPE = 'SplitterType';
  public static _EQUIPMENTtYPE = "EquipmentType";
  public static _TRACETYPE = 'Trace';
  public static _SHAREDVIEW = 'SHAREDVIEW';


  public static _WKT = 'WKT';
  public static _GEOJSON = 'GEOJSON';

  public static PREFERENCES : PREFERENCES = new PREFERENCES();
  public static OLSTYLES : OLSTYLES = new OLSTYLES();
  public static SRIDS : SrIDs = new SrIDs();
  public static STATE_CODES  : STATES_CODES = new STATES_CODES();
  public static REGIONALDATA : RegionalData = new RegionalData()
}

export class APIs {
  public static _POSITIONSTACK = 'http://api.positionstack.com/v1/';
  public static _POSITIONSTACK_APIKEY = '69a0db55bb7f5dccbe8b68f86235e882';

  public static _MAPBOX = 'https://api.mapbox.com/geocoding/v5/';
  public static _MAPBOX_APIKEY = 'pk.eyJ1IjoicGxhY2lvcHBhIiwiYSI6ImNsbzV1dDFqYTBnZGsycm12eHJqZDg4NzAifQ.1fJRW9d5feNioigGlYsI_w';
}

