<dx-accordion #accordion_element [dataSource]="ds_accordion_element" [collapsible]="true" [multiple]="false"
  [animationDuration]="300" [selectedItems]="accordion_element_selected" id="accordion-element-container"
  style="background-color: #f8f8f8">
  <div *dxTemplate="let item of 'title'">
    <h1 class="main-secondary-text">
      {{ item.title }}
    </h1>

  </div>
  <div *dxTemplate="let item of 'item'">
    <div fxLayout="row wrap" class="row-clearance">
      <!-- <div fxFlex="50" class="form-field custom-disabled-text-box">
        <dx-text-box [(value)]="item.data.traceID" [disabled]="true" labelMode="floating" [label]="'ID'"></dx-text-box>
      </div> -->
      <div fxFlex="50" class="form-field custom-disabled-text-box">
        <dx-text-box [(value)]="item.data.sectionAttributes.mslink" [disabled]="true" labelMode="floating"
          [label]="'general.bentley.mslink' | translate">
        </dx-text-box>
      </div>
      <div fxFlex="50" class="form-field custom-disabled-text-box">
        <dx-text-box [(value)]="item.data.elementType.name" [disabled]="true" labelMode="floating"
          [label]="'general.model' | translate"></dx-text-box>
      </div>
    </div>
    <div fxLayout="row wrap" class="row-clearance">
      <div fxFlex="50" class="form-field custom-disabled-text-box">
        <dx-text-box [(value)]="item.data.name" [disabled]="true" labelMode="floating"
          [label]="'general.name' | translate"></dx-text-box>
      </div>
      <div fxFlex="50" class="form-field custom-disabled-text-box">
        <dx-text-box [(value)]="item.data.description" [disabled]="true" labelMode="floating"
          [label]="'general.description2' | translate"></dx-text-box>
      </div>
    </div>

    <!--BENTLEY-->
    <div fxLayout="row wrap" class="row-clearance">
      <!-- <div fxFlex="50" class="form-field custom-disabled-text-box">
        <dx-text-box
          [(value)]="item.data.sectionAttributes.mslink"
          [disabled]="true"
          labelMode="floating"
          [label]="'general.bentley.mslink' | translate"
        >
        </dx-text-box>
      </div> -->
      <div fxFlex="50" class="form-field custom-disabled-text-box">
        <dx-text-box [(value)]="item.data.sectionAttributes.xfmID" [disabled]="true" labelMode="floating"
          [label]="'general.bentley.xfmid' | translate">
        </dx-text-box>
      </div>
      <div fxFlex="50" class="form-field custom-disabled-text-box">
        <dx-text-box [(value)]="item.data.sectionAttributes.poleno" [disabled]="true" labelMode="floating"
          [label]="'general.bentley.poleno' | translate">
        </dx-text-box>
      </div>
    </div>

    <div fxLayout="row wrap" class="row-clearance">

      <div fxFlex="50" class="form-field custom-disabled-text-box">
        <dx-text-box [(value)]="item.data.sectionAttributes.owner" [disabled]="true" labelMode="floating"
          [label]="'general.bentley.owner' | translate">
        </dx-text-box>
      </div>

      <div fxFlex="50" class="form-field custom-disabled-text-box">
        <dx-text-box [(value)]="item.data.sectionAttributes.jobDesc" [disabled]="true" labelMode="floating"
          [label]="'general.bentley.jobDesc' | translate">
        </dx-text-box>
      </div>
    </div>

    <div fxLayout="row wrap" class="row-clearance">


      <div fxFlex="100" class="form-field custom-disabled-text-box">
        <dx-text-box [(value)]="item.data.sectionAttributes.jobName" [disabled]="true" labelMode="floating"
          [label]="'general.bentley.jobName' | translate">
        </dx-text-box>
      </div>

    </div>

    <!---------------------------------->


  </div>
</dx-accordion>

<br />
<dx-accordion #accordion_element [dataSource]="ds_accordion_element" [collapsible]="true" [multiple]="false"
  [animationDuration]="300" [selectedItems]="accordion_element_selected" id="accordion-element-container"
  style="background-color: #f8f8f8">
  <div *dxTemplate="let item of 'title'">
    <h1 class="main-secondary-text">
      {{ 'trace.occupation-percentage' | translate}}
    </h1>
    <div fxLayout="row wrap" class="row-clearance">
      <div fxFlex="50" class="form-field">
        <dx-text-box class="cancelOpacity" id="textBoxPersent" [(value)]="statusFiber.fibersPersentText"
          [disabled]="true" labelMode="floating" [label]="'comp.search-panels.percentage-occupation'| translate"
          [style.backgroundColor]="getBackgroundColor(statusFiber.fibersPersent)">
        </dx-text-box>
      </div>
      <div fxFlex="50" class="form-field">
        <dx-text-box class="cancelOpacity" [(value)]="statusFiber.fibersTraceCantDisp" [disabled]="true"
          labelMode="floating" [label]="'comp.search-panels.available-fibers' | translate">
        </dx-text-box>
      </div>
    </div>
  </div>

</dx-accordion>

<br>
<dx-accordion *ngIf="entity && entity.elementType?.specAttributes?.showFiber" [dataSource]="ds_accordion_fiber"
  [collapsible]="true" [multiple]="false" [animationDuration]="300" [selectedItems]="ds_accordion_fiber"
  id="accordion-element-container" style="background-color: #f8f8f8">
  <div *dxTemplate="let item of 'title'">
    <h1 class="main-secondary-text">
      {{ item.title }}
    </h1>
  </div>
  <div *dxTemplate="let item of 'item'">
    <dx-data-grid id="gridContainer" style="max-height:50vh;" [dataSource]="item.data" [remoteOperations]="false"
      [allowColumnReordering]="true" [rowAlternationEnabled]="true" [showColumnLines]="false" [showBorders]="false"
      [columnAutoWidth]="true" (onExporting)="onExporting($event)">
      <dxo-search-panel [visible]="true" [highlightCaseSensitive]="true"></dxo-search-panel>
      <dxo-grouping [autoExpandAll]="false"></dxo-grouping>
      <dxi-column dataField="fiberAttributes.buffer" dataType="string" width="60" caption=""
        cellTemplate="bufferTemplate">
        <div *dxTemplate="let cellInfo of 'bufferTemplate'" style="text-align: center">
          <div [class]="'fiber left bk-' + cellInfo.value.toLowerCase()">
            {{ cellInfo.value }}
          </div>
        </div>
      </dxi-column>
      <dxi-column dataField="fiberAttributes.color" dataType="string" width="60" caption=""
        cellTemplate="colorTemplate">
        <div *dxTemplate="let cellInfo of 'colorTemplate'" style="text-align: center">
          <div [class]="'fiber right bk-' + cellInfo.value.toLowerCase()">
            {{ cellInfo.value }}
          </div>
        </div>
      </dxi-column>
      <dxi-column dataField="fiberOrder" dataType="string" caption=""></dxi-column>
      <dxi-column [visible]="isClientNameVisible" [caption]="'comp.search-panel.client-name' | translate"
        dataField="fiberAttributes.clientName" dataType="string"></dxi-column>
      <dxi-column dataField="serviceTrace[0].service.name" [caption]="'comp.search-panel.services' | translate"
        cellTemplate="cellTemplate">
        <div *dxTemplate="let cellInfo of 'cellTemplate'" style="text-align: center">
          <div>
            {{ cellInfo.value }}
          </div>
        </div>
      </dxi-column>

      <dxo-export [enabled]="true"></dxo-export>
    </dx-data-grid>
  </div>
</dx-accordion>

<div class="asociateDuctData">
  <dx-accordion *ngIf="filterData.length > 0" [collapsible]="true">
    <dxi-item class="main-secondary-text" title="Duct Data">
      <dx-data-grid [selection]="{ mode: 'single' }" [dataSource]="filterData" [remoteOperations]="false" [allowColumnReordering]="true"
        [rowAlternationEnabled]="true" [showColumnLines]="false" [showBorders]="false" [columnAutoWidth]="true"
        style="max-height:50vh;" (onRowClick)="onRowClicked($event)" (onExporting)="onExportingAsociatedDuct($event)">

        <dxi-column dataField="containerAttributes.mslink" dataType="number" caption="MsLink"></dxi-column>
        <dxi-column dataField="name" dataType="string" caption="Name"></dxi-column>
        <dxo-export [enabled]="true"></dxo-export>

      </dx-data-grid>
    </dxi-item>
  </dx-accordion>
</div>

<div class="projectDetail">
  <project-detail [projectDataEntityName]="projectDataEntityName"
    [projectDataDetail]="projectDataDetail"></project-detail>
</div>