import { Component, OnInit } from "@angular/core";
import { AppRoutingService } from "@app/app-routing.service";
import { PlatformService } from "@app/auth/platform.service";

import { CardItemsService } from '@app/@shared/services/card-items.service';
import { ContainerService } from '@app/@shared/services/map-elements-services/container.service';
import { FileService } from '@app/@shared/services/aux-services/file.service';
import { OverlappedRingsService } from '@app/@shared/services/overlapped-rings.service';

import { forkJoin, Observable } from "rxjs";
import { TranslateService } from '@ngx-translate/core';
import { CardItem } from "../overlapping-rings/card-item";

@Component({
  selector: 'main-screen',
  templateUrl: './main-screen.component.html',
  styleUrls: ['./main-screen.component.scss'],
})
export class MainScreenComponent implements OnInit {
  _cardItemsService : CardItemsService;
  loading: boolean = false;
  _cardItems: CardItem[];

  constructor(
    private appRoutingService : AppRoutingService,
    private platformService : PlatformService,
    private cardItemsService : CardItemsService,
    private containerService: ContainerService,
    private fileService: FileService,
    private overlappedRingsService: OverlappedRingsService,
    private trans: TranslateService

  ) {
    this.platformID = this.platformService.activePlatform.platformID;
    this._cardItemsService = cardItemsService;
  }

  platformID;
  ngOnInit(): void {
    this.loadCards();
  }

  loadCards(): void {
    this.loading = true;
    let _platformID = this.platformService.platformID;
    let dataObs: Observable<any>[] = [];
    dataObs.push(this.overlappedRingsService.getOverloppedStats(_platformID));
    dataObs.push(this.overlappedRingsService.getDuctStats(_platformID));
    dataObs.push(this.fileService.getTotalOfContainers(_platformID.toString()));

    let cards: CardItem[] = [];

    forkJoin(dataObs).subscribe({
      next: (response) => {
        response.forEach(res => {
          console.log(res);
          let name = res.userFile.name.replace('.xlsx', '').toLowerCase();
          // this.cardItemsService.addCard(
          cards.push(
            {
              formattedDate: this.trans.instant('general.last-update') + ': ' + (res.userFile.creationDate ? this.formatDate(res.userFile.creationDate) : 'no data'),
              ringTitle: `${name}-stats.home.title`,
              pieRatio: [
                { metric: this.trans.instant(`${name}-stats.home.description2`), value: res.stats._with },
                { metric: this.trans.instant(`${name}-stats.home.description1`), value: res.stats._without  }
              ],
              overlappedRingsFile: res,
              endpoint: res.endpoint
            }
          );
        });

        this._cardItems = cards;
        this.loading = false;
        
      },
      error: (err) => {
        this.loading = false;
      },
      complete: () => {
        this.loading = false;
      }
    });
  }

  formatDate(date) {
    const options : Intl.DateTimeFormatOptions = {
      day: '2-digit',
      month: '2-digit',
      year: 'numeric',
      hour: '2-digit',
      minute: '2-digit',
      hour12: false, // Use 24-hour format
    };

    return new Intl.DateTimeFormat('es-AR', options).format(new Date(date));
  }
}
