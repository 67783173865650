import { Injectable } from "@angular/core";
import { MainService } from "../main.service";
import { catchError, map, Observable, throwError } from "rxjs";
import { Cons } from "@app/@shared/cons/cons";

@Injectable({
    providedIn: 'root',
  })
export class SharedViewService {
  constructor(private main: MainService) {}




  post(data: any): Observable<any> {
    this.main.chooseAPI(Cons._SHAREDVIEW);
    const requestBody = { data: data };
    return this.main.post('', undefined, requestBody).pipe(
      map((x) => x.responseData),
      catchError((ex) => throwError(() => new Error(ex)))
    );
}
}