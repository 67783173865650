import { Component, EventEmitter, Input, OnChanges, Output, SimpleChanges } from '@angular/core';

@Component({
  selector: 'app-infrastructure-list',
  templateUrl: './infrastructure-list.component.html',
  styleUrls: ['./infrastructure-list.component.scss'],
})
export class InfrastructureListComponent implements OnChanges {
  @Input() loading: boolean = false;
  @Input() infrastructure: any[] = []; 
  @Input() filter: any;

  @Output() editItem = new EventEmitter<any>();
  @Output() deleteItem = new EventEmitter<any>();

  filteredInfrastructure: any[] = [];
  originalInfrastructure: any[] = [];

  constructor() { }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes['infrastructure'] && changes['infrastructure'].currentValue && this.infrastructure.length > 0) {
      this.originalInfrastructure = [...this.infrastructure];
      this.applyFilter(); 
    }

    if (changes['filter']) {
      this.applyFilter(); 
    }
  }

  ngOnInit(): void {
    if (this.infrastructure.length > 0) {
      this.originalInfrastructure = [...this.infrastructure];
      this.applyFilter(); 
    }
  }

  applyFilter(): void {
    if (!this.originalInfrastructure || this.originalInfrastructure.length === 0) {
      this.filteredInfrastructure = [];
      return;
    }

    if (!this.filter || (this.filter.name === '' && this.filter.description === '' && this.filter.elementTypeID === undefined)) {
      this.filteredInfrastructure = [...this.originalInfrastructure];
      return;
    }

    this.filteredInfrastructure = [];

    this.originalInfrastructure.forEach(item => {
      const hasName = this.filter.name && this.filter.name.trim().length > 0;
      const hasDescription = !!(this.filter.description && this.filter.description.trim().length > 0);
      const hasElementTypeID = this.filter.elementTypeID !== undefined; 

      const matchesName = hasName ? item.name?.toLowerCase().includes(this.filter.name.toLowerCase()) : true;
      const matchesDescription = hasDescription
        ? item.description?.toLowerCase().includes(this.filter.description.toLowerCase())
        : true;
      const matchesElementTypeID = hasElementTypeID ? item.elementTypeID === this.filter.elementTypeID : true;

      if (!hasName && !hasDescription && hasElementTypeID) {
        if (matchesElementTypeID) {
          this.filteredInfrastructure.push(item);
        }
      }
      else if (hasName && !hasDescription && !hasElementTypeID) {
        if (matchesName) {
          this.filteredInfrastructure.push(item);
        }
      }
      else if (!hasName && hasDescription && !hasElementTypeID) {
        if (matchesDescription) {
          this.filteredInfrastructure.push(item);
        }
      }
      else if (hasName && hasDescription && hasElementTypeID) {
        if (matchesName && matchesDescription && matchesElementTypeID) {
          this.filteredInfrastructure.push(item);
        }
      }
      else if (hasName && hasDescription && !hasElementTypeID) {
        if (matchesName && matchesDescription) {
          this.filteredInfrastructure.push(item);
        }
      }
      else if (hasName && !hasDescription && hasElementTypeID) {
        if (matchesName && matchesElementTypeID) {
          this.filteredInfrastructure.push(item);
        }
      }
      else if (!hasName && hasDescription && hasElementTypeID) {
        if (matchesDescription && matchesElementTypeID) {
          this.filteredInfrastructure.push(item);
        }
      }
    });
  }

}
