import { Component, Input, OnChanges, OnDestroy, OnInit, SimpleChanges, ViewChild } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { exportDataGrid } from 'devextreme/excel_exporter';
import { Workbook, Worksheet } from 'exceljs';
import { ExcelJSHelperService } from '@app/@shared/services/aux-services/excelJS-helper.service';
import { Connection } from '@app/@shared/model/connection.model';
import * as saveAs from 'file-saver';
import { ContainerService } from '@app/@shared/services/map-elements-services/container.service';
import { Container } from '@app/@shared/model/container.model';
import { forkJoin } from 'rxjs';
import { MapInteractionService } from '@app/@shared/services/map-elements-services/map-interaction.service';

@Component({
  selector: 'trace-detail',
  templateUrl: './trace-detail.component.html',
  styleUrls: ['./trace-detail.component.scss'],
})
export class TraceDetailComponent implements OnDestroy {
  ds_accordion_element = [];
  ds_accordion_fiber = [];

  filterData: any[] = [];

  ignoreFirstResult: boolean = true;

  accordion_element_selected = [];
  isClientNameVisible: boolean = true;
  projectDataDetail: any;
  projectDataEntityName: any;
  traceSectionData: any;

  _entity: any;
  statusFiber: any;//{ fibersCant: number; fibersTraceCant: number; fibersPersent: number; };
  @Input('entity')
  get entity() {
    return this._entity;
  }
  set entity(value) {
    if (value == this._entity) {
      return;
    }
    this._entity = value;
    this.traceSectionData = value.sectionData;
    this.projectDataEntityName = value.entityName;
    this.projectDataDetail = value.sectionAttributes;

    this.getContainer_DuctIDfromTraceSectionData();

    this.loadEntity();
  }

  colorRanges: { color: string, min: number, max: number, colorText: string }[] = [
    { color: "#dd4444cc", colorText: "white", min: 80, max: 100 },//red
    { color: "#dd8944cc", colorText: "white", min: 50, max: 79 },//orange
    { color: "#ddc644cc", colorText: "white", min: 20, max: 49 },//yellow
    { color: "#62dd44cc", colorText: "white", min: 0, max: 19 }//green
  ];




  constructor(private trans: TranslateService, private excelJSHelperService: ExcelJSHelperService, private containerService: ContainerService, private mapInteractionService: MapInteractionService) { }

 

  ngOnDestroy(): void {
    this.changeAllContainerDuctToDimgray();
  }



  loadEntity() {
    // Close search panel
    this.ds_accordion_element = [];
    console.log(this.entity.fiber);
    let fibersAux: any[] = this.entity.fiber;
    const fibersCant: number = fibersAux.length;
    const fibersTraceCant: number = fibersAux.filter(obj => obj.serviceTrace.length > 0).length;

    let fibersPersentText: string = " ";

    // Check if there are fibers available before calculating percentage
    if (fibersCant > 0) {
      const fibersPersent = Math.floor((fibersTraceCant / fibersCant) * 100);
      fibersPersentText = `${fibersPersent} %`;
    }

    this.statusFiber = {
      fibersCant: fibersCant,
      fibersTraceCant: fibersTraceCant,
      fibersTraceCantDisp: fibersCant - fibersTraceCant,
      fibersPersentText: fibersPersentText,
      fibersPersent: fibersPersentText.trim() !== "" ? parseInt(fibersPersentText) : null,
      title: "comp.search-panels.availability"
    };

    console.log(this.statusFiber);

    this.ds_accordion_element.push({
      title:
        String.prototype.upperFirstLetter(this.entity.entityName) +
        ' ' +
        this.trans.instant('general.review'),
      data: this.entity,
    });

    this.accordion_element_selected = this.ds_accordion_element;

    this.isClientNameVisible = this.entity.fiber.some(x => x.fiberAttributes.clientName);

    this.ds_accordion_fiber = [];
    this.ds_accordion_fiber.push({
      title: this.trans.instant('comp.search-panel.fibers'),
      data: this.entity.fiber,
    });
    console.log(this.ds_accordion_fiber);
  }


  getBackgroundColor(fibersPersent: number): string {

    if (this.entity && this.entity.fiber && this.entity.fiber.length === 0) {
      return 'transparent'; // Return transparent if there are no fibers
    }

    const range = this.colorRanges.find(range => fibersPersent >= range.min && fibersPersent <= range.max);

    const color = range ? range.color : 'defaultColor';
    // Convert the color to RGBA with 0.4 opacity
    return this.hexToRgba(color, 0.4);
  }

  hexToRgba(hex: string, alpha: number): string {
    // Remove '#' if present
    hex = hex.replace(/^#/, '');

    let r, g, b;


    if (hex.length === 3) {

      r = parseInt(hex.charAt(0) + hex.charAt(0), 16);
      g = parseInt(hex.charAt(1) + hex.charAt(1), 16);
      b = parseInt(hex.charAt(2) + hex.charAt(2), 16);
    } else {

      r = parseInt(hex.substr(0, 2), 16);
      g = parseInt(hex.substr(2, 2), 16);
      b = parseInt(hex.substr(4, 2), 16);
    }


    return `rgba(${r}, ${g}, ${b}, ${alpha})`;
  }



  getTextColor(fibersPersent: number): string {
    const range = this.colorRanges.find(range => fibersPersent > range.min && fibersPersent <= range.max);
    return range ? range.colorText : 'defaultColor';
  }
  onExporting(e) {
    const workbook = new Workbook();
    const worksheet = workbook.addWorksheet('Sheet1');

    exportDataGrid({
      component: e.component,
      worksheet,
      autoFilterEnabled: true,
    }).then(() => {
      this.initializeExcelHeaders(worksheet);
      this.writeExcelData(worksheet);
      this.excelJSHelperService.adjustColumnsWidth(worksheet);
      workbook.xlsx.writeBuffer().then((buffer) => {
        saveAs(
          new Blob([buffer], { type: 'application/octet-stream' }),
          this.entity.name + '_FIBERS_.xlsx'
        );
      });
    });
    e.cancel = true;
  }

  initializeExcelHeaders = (worksheet: Worksheet) => {
    const bufferLabel = this.trans.instant('comp.search-panel.excel-header.buffer')?.toUpperCase();
    const colorLabel = this.trans.instant('comp.search-panel.excel-header.color')?.toUpperCase();
    const serviceLabel = this.trans.instant('comp.search-panel.excel-header.service')?.toUpperCase();
    const fiberOrderLabel = this.trans.instant('comp.search-panel.excel-header.fiber-order')?.toUpperCase();

    const headers = [
      bufferLabel,
      colorLabel,
      fiberOrderLabel,
      serviceLabel
    ];

    this.excelJSHelperService.writeHeaders(worksheet, headers, 1);
    this.centerTextExcel(worksheet, 1, [1, 2, 3, 4]);
  };

  writeExcelData = (worksheet: Worksheet) => {
    const connections: any[] = this.ds_accordion_element[0].data.fiber;

    const excelData: string[][] = connections.map((x) => {

      const buffer1 = x.fiberAttributes.buffer;
      const color1 = x.fiberAttributes.color;
      const fiberOrder = x.fiberOrder;
      const service = x.serviceTrace[0]?.service.name || "";


      return [buffer1, color1, fiberOrder, service];
    });

    this.excelJSHelperService.writeData(worksheet, 2, excelData);
    this.centerTextExcel(worksheet, 2, [1, 2, 3, 4], connections.length);
  };

  centerTextExcel(worksheet: Worksheet, startRow: number, cells: number[], rowCount: number = 1) {
    for (let i = 0; i < rowCount; i++) {
      const row = worksheet.getRow(startRow + i);
      cells.forEach((cell) => {
        row.getCell(cell).alignment = { horizontal: 'center' };
      });
    }
  }

  onExportingAsociatedDuct(e) {
    const workbook = new Workbook();
    const worksheet = workbook.addWorksheet('Sheet1');

    exportDataGrid({
      component: e.component,
      worksheet,
      autoFilterEnabled: true,
    }).then(() => {
      this.initializeExcelHeadersAsociatedDuct(worksheet);
      this.writeExcelDataAsociatedDuct(worksheet);
      this.excelJSHelperService.adjustColumnsWidth(worksheet);
      workbook.xlsx.writeBuffer().then((buffer) => {
        saveAs(
          new Blob([buffer], { type: 'application/octet-stream' }),
          this.entity.name + '_DUCTS_.xlsx'
        );
      });
    });
    e.cancel = true;
  }

  initializeExcelHeadersAsociatedDuct = (worksheet: Worksheet) => {
    const entityName = this.entity.name;

    worksheet.getCell(1, 1).value = entityName;
    worksheet.getCell(1, 1).font = { bold: true };
    worksheet.getCell(1, 1).alignment = { horizontal: 'center' };
    worksheet.mergeCells(1, 1, 1, 2);

    const mslinkLabel = "MSLINK";
    const nameLabel = this.trans.instant('comp.search-panel.excel-header.name')?.toUpperCase();

    worksheet.getCell(2, 1).value = mslinkLabel;
    worksheet.getCell(2, 1).font = { bold: true };
    worksheet.getCell(2, 1).fill = {
      type: 'pattern',
      pattern: 'solid',
      fgColor: { argb: 'ADD8E6' }
    };

    worksheet.getCell(2, 2).value = nameLabel;
    worksheet.getCell(2, 2).font = { bold: true };
    worksheet.getCell(2, 2).fill = {
      type: 'pattern',
      pattern: 'solid',
      fgColor: { argb: 'ADD8E6' }
    };

    worksheet.autoFilter = null;

    this.centerTextExcelAsociatedDuct(worksheet, 2, [1, 2]);
  };

  writeExcelDataAsociatedDuct = (worksheet: Worksheet) => {
    const connections: any[] = this.ds_accordion_element[0].data.fiber;

    const excelData: string[][] = this.filterData.map((x) => {
      const mslink = x.containerAttributes.mslink;
      const name = x.name || "";
      return [mslink, name];
    });

    this.excelJSHelperService.writeData(worksheet, 3, excelData);
    this.centerTextExcelAsociatedDuct(worksheet, 3, [1, 2], connections.length);
  };

  centerTextExcelAsociatedDuct(worksheet: Worksheet, startRow: number, cells: number[], rowCount: number = 1) {
    for (let i = 0; i < rowCount; i++) {
      const row = worksheet.getRow(startRow + i);
      cells.forEach((cell) => {
        row.getCell(cell).alignment = { horizontal: 'center' };
      });
    }
  }

  onRowClicked(event: any) {
    const rowData = event.data;
    const selectedColor = 'cyan';
    const defaultColor = 'dimgray';

    this.filterData.forEach(row => {
      if (row === rowData) {
        if (row.elementType?.specAttributes) {
          row.elementType.specAttributes.color = selectedColor;
        } else {
          row.elementType = { specAttributes: { color: selectedColor } };
        }
      } else {
        if (row.elementType?.specAttributes) {
          row.elementType.specAttributes.color = defaultColor;
        } else {
          row.elementType = { specAttributes: { color: defaultColor } };
        }
      }
    });

    this.filterData.forEach(row => {
      this.mapInteractionService.updateElementClicked([row]);
    });

    this.searchInMapContainerDuctSelected(rowData);

  }

  searchInMapContainerDuctSelected(data: any): void {
    this.mapInteractionService.searchClicked(data);
  }



  changeAllContainerDuctToDimgray() {
    const defaultColor = 'dimgray';

    this.filterData.forEach(row => {
      if (row.elementType?.specAttributes) {
        row.elementType.specAttributes.color = defaultColor;
      } else {
        row.elementType = { specAttributes: { color: defaultColor } };
      }
    });

    this.filterData.forEach(row => {
      this.mapInteractionService.updateElementClicked([row]);
    });
  }

  //Find ducts related to a trace 
  getContainer_DuctIDfromTraceSectionData() {
    try {
      this.filterData = [];
      const parsedData = JSON.parse(this.traceSectionData);

      if (parsedData && parsedData.containerid) {
        let containerID = parsedData.containerid
          .replace(/[\[\]]/g, '')
          .split(',')
          .map(Number);

        if (containerID.length > 0 && containerID.some(id => !isNaN(id))) {
          this.getContainer_DuctfromContainerID(containerID);
        }
      }
    } catch (error) {
      console.error('Error parsing sectionData:', error);
    }
  }

  getContainer_DuctfromContainerID(containerID: number[]) {
    containerID.forEach(id => {
      this.containerService.getByID(id).subscribe(
        (response) => {
          this.filterData.push(response);
        },
        (error) => {
          console.error('Error retrieving container data:', error);
        }
      );
    });
  }


}
