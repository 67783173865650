<div class="rack-header">
  <div class="row">
    <div class="col-6" style="width: auto;">
      <h3 *ngIf="showTitle" class="mb-4" style="font-size: 1.5rem;">
        {{equipment.name}} / {{equipment?.equipmentType?.name}}
      </h3>
    </div>

    <div class="col-1 d-flex flex-column align-items-center p-0" style="width: auto;">
      <dx-button class="button main-background-theme" (click)="openPopup('qr')" [icon]="qrIcon"></dx-button>
      <p class="static-qr">{{'infrastructure-odf.front-view.static-qr' | translate}}</p>
    </div>

    <div class="col-1 d-flex justify-content-center p-0" style="width: auto;">
      <dx-button class="button main-background-theme" (click)="openPopup('camera')" [icon]="cameraIcon"></dx-button>
    </div>

    <div class="col-1 d-flex flex-column align-items-center p-0" style="width: auto;">
      <dx-button class="button main-background-theme" (click)="openPopup('dynamicQR')" [icon]="dynamicQrIcon"></dx-button>
      <p class="static-qr">{{'infrastructure-odf.front-view.dynamic-qr' | translate}}</p>
    </div>

  </div>
</div>

  <div class="rack-svg">
    <dx-tab-panel [(selectedIndex)]="selectedTab">
      <dxi-item [title]="'Back'" *ngIf="backPortsArray">
        <dx-scroll-view #scrollView id="scrollview" [scrollByContent]="true" [scrollByThumb]="true"
          [showScrollbar]="'always'" [bounceEnabled]="false" direction="horizontal">
          <div *ngFor="let row of rowsArray; let rowIndex = index" class="rack-row">
            <div *ngFor="let port of backPortsArray[row]" class="rack-column">
              <img *ngIf="port && port.equipmentPortID" class="port" [src]="getPortSVG(port)"
                [id]="'e' + equipment.equipmentID + '_' + port?.equipmentPortID" (click)="selectPort(port, 'B', true)"
                (mouseenter)="selectPort(port, 'B', false)" />
              <b>{{port?.name.replace("B","")}}</b>
            </div>
          </div>
        </dx-scroll-view>
      </dxi-item>
      <dxi-item [title]="'Front'" *ngIf="frontPortsArray">
        <dx-scroll-view #scrollView_2 id="scrollview" [scrollByContent]="true" [scrollByThumb]="true"
          [showScrollbar]="'always'" [bounceEnabled]="false" direction="horizontal">
          <div *ngFor="let row of rowsArray; let rowIndex = index" class="rack-row">
            <div *ngFor="let port of frontPortsArray[row]" class="rack-column">
              <img *ngIf="port && port.equipmentPortID" class="port" [src]="getPortSVG(port)"
                [id]="'e' + equipment.equipmentID + '_' + port?.equipmentPortID" (click)="selectPort(port, 'F', true)"
                (mouseenter)="selectPort(port, 'F', false)" />
              <b>{{port?.name.replace("F","")}}</b>
            </div>
          </div>
        </dx-scroll-view>
      </dxi-item>
    </dx-tab-panel>
  </div>


<dx-popup *ngIf="popupVisible" [visible]="!!popupVisible" (visibleChange)="onVisibleChange($event)"
  [closeOnOutsideClick]="true" [title]="modalTitle" [showTitle]="true" [height]="175" [width]="300"
  [dragEnabled]="false">
  <div *dxTemplate="let data of 'content'">
    <app-port-details-modal [port]="selectedPort"></app-port-details-modal>
  </div>
</dx-popup>

<dx-popup *ngIf="showPopup" [visible]="showPopup" (visibleChange)="onPopupVisibleChange($event)"
  [closeOnOutsideClick]="true" [minHeight]="'auto'" [minWidth]="'auto'" [dragEnabled]="false">
  <div *dxTemplate="let data of 'content'" class="qr-popup-content" style="max-height: 100%; overflow-y: auto;">
    <div *ngFor="let qr of qrDataArray; let i = index" class="qr-container" style="margin-bottom: 10px;">
      <div style="text-align: center;">
        <h3 style="font-size: 1.5rem;" *ngIf="i > 0">{{ 'infrastructure-odf.download.rack' | translate }} {{ i }}</h3>
        <app-qrcode   [infrastructureFullName]="infrastructureFullName" [qrData]="qr" [index]="i" [firstPort1]="firstPorts1[i-1]" [lastPort1]="lastPorts1[i-1]"
          #qrcodeComponent></app-qrcode>
      </div>
    </div>
    <div style="text-align: center; margin-top: 20px;">
      <dx-button class="button main-background-theme" (click)="downloadAllQRCodes()"
        [text]="'infrastructure-odf.download.all' | translate"></dx-button>
    </div>
  </div>
</dx-popup>

<div>
  <dx-popup class="custom-gallery-popup" *ngIf="cameraPopupVisible" [visible]="cameraPopupVisible"
    (visibleChange)="onCameraPopupVisibleChange($event)" [closeOnOutsideClick]="true" [title]="'Camera Popup'"
    [showTitle]="true" [dragEnabled]="false">
    <div *dxTemplate="let data of 'content'">
      <div class="popup-content">
        <app-gallery-viewer [equipmentID]="_equipment?.equipmentID"></app-gallery-viewer>
      </div>
    </div>
  </dx-popup>
</div>

<div>
  <dx-popup class="custom-dynamicQR-popup" *ngIf="dynamicQRVisible" [visible]="dynamicQRVisible"
    [closeOnOutsideClick]="true" [title]="'Dynamic QR'" [showTitle]="true" [dragEnabled]="false"
    (visibleChange)="onDynamicQRvisibleChange($event)" [width]="200" [height]="300">

    <div class="qr-popup-content">
      <app-qrcode [infrastructureFullName]="infrastructureFullName"   [nombreEquipoConcatenado]="nombreEquipoConcatenado"  [isDynamicQR]="isDynamicQR" [equipmentID]="equipmentID" #qrcodeComponent></app-qrcode>
    </div>

  </dx-popup>
</div>

<dx-tooltip [target]="target" showEvent="mouseenter" hideEvent="mouseleave">
  <div *dxTemplate="let data of 'content'">
    <app-port-details-modal [port]="selectedPort"></app-port-details-modal>
  </div>
</dx-tooltip>