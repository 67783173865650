import { Injectable } from '@angular/core';
import { Cons } from '@app/@shared/cons/cons';
import { D3CircularHierarchyTreeData } from '@app/@shared/model/d3/circular-hierarchy-tree.model';
import { Device } from '@app/@shared/model/device.model';
import { catchError, map, Observable, throwError } from 'rxjs';
import { MainService } from '../main.service';
import { CredentialsService } from '@app/auth';

import { PlatformService } from '@app/auth/platform.service';
import { IQueryViewConfiguration } from '@app/@shared/model/interface/iQuery-view-configuration.model';

@Injectable({ providedIn: 'root' })
export class DeviceService {
  constructor(
    private main: MainService,
    private platformService: PlatformService,
    private credentialsService: CredentialsService
  ) {}

  getWithFilter(entity?: any, limit? : number, offset?: number, clause? : string): Observable<any> {
    this.main.chooseAPI(Cons._DEVICE);
    return this.main.getWithFilter('Device', entity, true, limit, offset, clause).pipe(
      map((res) => {
        try {
          return res.responseData.map((x) => {
            x.commonID = x.deviceID;
            x.entityName = Cons._DEVICE;
            x.multipleSearchField = "";
            if(x.name){ x.multipleSearchField += x.name; }
            if(x.description){ x.multipleSearchField += ' (' + x.description + ") "; }
            //if(x.deviceAttributes && x.deviceAttributes.descr2){ x.multipleSearchField += '- ' + x.deviceAttributes.descr2; }
            return <Device>x;
          });
        } catch (ex) {
          return <string>(<any>res)._body.toString();
        }
      })
    );
  }

  queryView(entity: any, xyCoordinates?: any[], locationCriteria?, networkType? : string): Observable<any> {
    this.main.chooseAPI(Cons._DEVICE);

    const queryViewConfiguration: IQueryViewConfiguration = {
      xyCoordinates,
      locationCriteria,
    };

    return this.main
      .queryView('Device', entity, queryViewConfiguration)
      .pipe(
        map((res) => {
          try {
            return res.responseData.filter(x => networkType ? x.deviceAttributes?.poleno !== undefined ? x.deviceAttributes.poleno.replace(/[^a-zA-Z]/g, '').toUpperCase().startsWith(networkType) : false : true).map((x) => {
              x.commonID = x.deviceID;
              x.entityName = Cons._DEVICE;
              networkType ? x.deviceAttributes.poleno.startsWith(networkType) : null;
              return <Device>x;
            });
          } catch (ex) {
            return <string>(<any>res)._body.toString();
          }
        })
      );
  }

  getByID(id: any): Observable<any> {
    this.main.chooseAPI(Cons._DEVICE);
    return this.main.getByID('Device', id).pipe(
      map((res) => {
        try {
          res.commonID = res.deviceID;
          res.entityName = Cons._DEVICE;
          return <Device>res;
        } catch (ex) {
          return <string>(<any>res)._body.toString();
        }
      })
    );
  }

  getByMslink(id: any): Observable<any> {
    this.main.chooseAPI(Cons._DEVICE);
    return this.main.getByID('Device/ByMslink', id).pipe(
      map((res) => {
        try {
          res.commonID = res.deviceID;
          res.entityName = Cons._DEVICE;
          return <Device>res;
        } catch (ex) {
          return <string>(<any>res)._body.toString();
        }
      })
    );
  }

  create(device: Device): Observable<any> {
    this.main.chooseAPI(Cons._DEVICE);
    return this.main.post(Cons._DEVICE, undefined, device).pipe(
      map((x) => x.responseData),
      catchError((ex) => throwError(() => new Error(ex)))
    );
  }

  update(device: Device): Observable<any> {
    this.main.chooseAPI(Cons._DEVICE);
    return this.main.put(Cons._DEVICE, undefined, device).pipe(
      map((x) => x.responseData),
      catchError((ex) => throwError(() => new Error(ex)))
    );
  }

  getDeviceBox(deviceID: number): Observable<D3CircularHierarchyTreeData[][]> {
    const platformID = this.platformService.platformID;
    this.main.chooseAPI(Cons._DEVICE);

    return this.main.get('DEVICEBOX', `${platformID}/${deviceID}`).pipe(
      map((x) => Array.isArray(x.responseData[0]) ? x.responseData : [x.responseData]),
      catchError((ex) => throwError(() => new Error(ex)))
    );
  }


  getDeviceBoxByMslink(mslink: number): Observable<D3CircularHierarchyTreeData[][]> {
    const platformID = this.platformService.platformID;
    this.main.chooseAPI(Cons._DEVICE);

    return this.main.get('DEVICEBOX', `ByMslink/${platformID}/${mslink}`).pipe(
      map((x) => Array.isArray(x.responseData[0]) ? x.responseData : [x.responseData]),
      catchError((ex) => throwError(() => new Error(ex)))
    );
  }

  getDeviceBoxByContainerMslink(mslink: number): Observable<D3CircularHierarchyTreeData[][]> {
    const platformID = this.platformService.platformID;
    this.main.chooseAPI(Cons._DEVICE);

    return this.main.get('DEVICEBOX', `ByContainerMslink/${platformID}/${mslink}`).pipe(
      map((x) => Array.isArray(x.responseData[0]) ? x.responseData : [x.responseData]),
      catchError((ex) => throwError(() => new Error(ex)))
    );
  }
}
