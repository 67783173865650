import { geojsonToWKT } from "@terraformer/wkt"

export class LocationData {
  inputSrID: number;
  outputSrID: number;
  format: string;
  coordinates: any;

  setCoordinates(xyCoordinates) {
    this.coordinates = { values: xyCoordinates };
  }


  constructor (format : string, geometry : any, inputSrID : number, outputSrID? : number){
    this.inputSrID = inputSrID;
    this.outputSrID = outputSrID;
    this.format = format;

    if(!outputSrID){
      this.outputSrID = inputSrID;
    }
    
    if(geometry && typeof geometry == 'string'){
      this.constructWithStringCoordinates(geometry, format);
    }
    else if(geometry && typeof geometry == 'object' && geometry.coordinates){
      this.constructWithArrayCoordinates(geometry.coordinates, format, geometry.type);
    }
    else{
      console.warn("Undefined geometry " + geometry + ".");
    }
  }

  constructWithStringCoordinates(coordinates, format : string){
    if(coordinates.toUpperCase().includes("POINT")){
      const geojsonPoint = {
        "type": "Point",
        "coordinates": coordinates
      }
      
      if(format != ""){
        this.coordinates = { geographyType: "Point", values: coordinates };
      }
    }
    if(coordinates.toUpperCase().includes("LINESTRING")){
      if(format != ""){
        this.coordinates = { geographyType: "LineString", values: coordinates };
      }
    }
  }

  constructWithArrayCoordinates(coordinates, format : string, type: string){
    if(type.toUpperCase().includes("POINT")){
      
      if(format != ""){
        this.coordinates = { geographyType: "Point", values: `POINT (${coordinates.join(' ')})` };
      }
    }

    if(type.toUpperCase().includes("LINESTRING")){
      if(format != ""){
        this.coordinates = { geographyType: "LineString", values: `LINESTRING (${coordinates.join(' ')})` };
      }
    }
  }
}
