<div class="stepper-container" [ngClass]="{ 'vertical': vertical }" [style.height.px]="vertical ? height : 'auto'">

  <div *ngFor="let step of steps; index as i" class="step-container">
    <ng-container *ngIf="!step.hidden">
      <div class="progress" [ngClass]="{'complete': step.id <= currentStep }" *ngIf="(i+1) < steps.length"></div>
      <div [ngClass]="{'complete': step.id <= currentStep, 'step-circle': (step.type != 'CELDAS' && step.type != 'Edificio_Lindo'), 'step-header': (step.type == 'CELDAS'), 'step-client': (step.type == 'Edificio_Lindo')}">
      </div>
      <div class="step-text d-flex flex-column align-items-start">
        <span *ngIf="step.title" class="step-title" [ngClass]="{'complete': step.id <= currentStep }">{{ step.title
          }}</span>
        <span *ngIf="step.subtitle" class="step-subtitle">{{ step.subtitle }}</span>
      </div>
    </ng-container>
  </div>
</div>