import { Injectable } from '@angular/core';
import { saveAs } from 'file-saver';
import { float } from 'html2canvas/dist/types/css/property-descriptors/float';

interface D3DownloadConfiguration {
  svg: SVGElement,
  fileName?: string,
  extension?: string,
  diameter?: number,
  height?: number,
  width?: number,
}

interface SVGIconData {
  name: string;
  svgImageBase64: string;
}

@Injectable({ providedIn: 'root' })
export class D3GraphHelperService {
  async downloadCircularHierarchyTree(d3DownloadConfiguration: D3DownloadConfiguration): Promise<void> {
    const svgIdentifier = 'svg.circular-hierarchy-tree';
    this.downloadGraph(d3DownloadConfiguration, svgIdentifier);
  }

  async downloadHorizontalTree(d3DownloadConfiguration: D3DownloadConfiguration): Promise<void> {
    const svgIdentifier = 'svg.horizontal-tree';
    this.downloadGraph(d3DownloadConfiguration, svgIdentifier, true);
  }

  private async downloadGraph({ svg, fileName = 'ExportedGraphic', extension, diameter = 950, height, width }: D3DownloadConfiguration, svgIdentifier: string, isHorizontalGraph: boolean = false): Promise<void> {
    const styleElement = document.createElement("style");

    if (isHorizontalGraph) {
      if (diameter < 950) {
        diameter = 950; //min diameter
      }
    }

    let viewportX = Math.ceil(diameter * 2);
    let viewportY = Math.ceil(diameter);

    if (!isHorizontalGraph) {
      viewportX = width
    }
    
    if (isHorizontalGraph) {
      viewportY = height;
    }

    const svgString = this.getSVGString(svg, svgIdentifier, styleElement).replace('width="100%"', 'width="100%" viewBox="0 0 ' + viewportX + ' ' + viewportY + '"');
    const canvas = await this.svgStringToImage(svgString, viewportX, viewportY, extension, fileName);
    this.cleanUpDomElements(styleElement, canvas);
  }

  private save(dataBlob, fileName = 'ExportedGraphic', extension = 'jpg') {
    saveAs(dataBlob, `${fileName}.${extension}`);
  }

  private getSVGString(svgNode: SVGElement, svgIdentifier: string, styleElement: HTMLStyleElement) {
    const getCSStyles = (svgNode: SVGElement, svgIdentifier: string): string => {
      const allIDsAndClasses: Set<string> = new Set();

      for (let c = 0; c < svgNode.classList.length; c++) {
        allIDsAndClasses.add(`.${svgNode.classList[c]}`);
      }

      const nodes = svgNode.getElementsByTagName("*");

      for (let i = 0; i < nodes.length; i++) {
        if (nodes[i].id) {
          allIDsAndClasses.add(`#${nodes[i].id}`);
        }

        const classes = nodes[i].classList;
        for (let c = 0; c < classes.length; c++) {
          allIDsAndClasses.add(`.${classes[c]}`);
        }
      }

      const idsAndClasses = Array.from(allIDsAndClasses);
      return extractCSSRules(svgIdentifier, idsAndClasses);
    }

    const extractCSSRules = (svgIdentifier: string, allIDsAndClasses: string[]) => {
      // Extract CSS Rules
      let extractedCSSText: string = '';

      for (let i = 0; i < document.styleSheets.length; i++) {
        const s = document.styleSheets[i];

        try {
          if (!s.cssRules) continue;
        } catch (e) {
          if (e.name !== 'SecurityError') throw e; // for Firefox
          continue;
        }

        const cssRules = s.cssRules;

        for (let r = 0; r < cssRules.length; r++) {
          const selectorText = (<any>cssRules[r]).selectorText;

          if (allIDsAndClasses.map(x => `${svgIdentifier} ${x}`).includes(selectorText)) {
            extractedCSSText = extractedCSSText + cssRules[r].cssText;
          }
        }
      }

      return extractedCSSText;
    }

    const appendCSS = (cssText, element, styleElement: HTMLStyleElement): void => {
      styleElement.setAttribute("type", "text/css");
      styleElement.innerHTML = cssText;
      const refNode = element.hasChildNodes() ? element.children[0] : null;
      element.insertBefore(styleElement, refNode);
    }

    svgNode.setAttribute('xlink', 'http://www.w3.org/1999/xlink');
    const cssStyleText = getCSStyles(svgNode, svgIdentifier);
    appendCSS(cssStyleText, svgNode, styleElement);

    var serializer = new XMLSerializer();
    var svgString = serializer.serializeToString(svgNode);
    svgString = svgString.replace(/(\w+)?:?xlink=/g, 'xmlns:xlink='); // Fix root xlink without namespace
    svgString = svgString.replace(/NS\d+:href/g, 'xlink:href'); // Safari NS namespace fix

    return svgString;
  }

  private svgStringToImage(svgString, width, height, extension?: string, fileName?: string): Promise<HTMLCanvasElement> {
    return new Promise((resolve, reject) => {
      const ext = extension || 'png';
      var imgsrc = 'data:image/svg+xml;base64,' + btoa(unescape(encodeURIComponent(svgString))); // Convert SVG string to data URL
      var canvas = document.createElement("canvas");
      var context = canvas.getContext("2d");

      canvas.width = width;
      canvas.height = height;

      const image = new Image();
      const backgroundColor = '#CEDAE2'; // Background color

      image.onload = () => {
        // Draw background rectangle with the specified color
        context.fillStyle = backgroundColor;
        context.fillRect(0, 0, width, height);

        context.drawImage(image, 0, 0, width, height);

        canvas.toBlob((blob) => {
          this.save(blob, fileName, ext);
          resolve(canvas);
        });
      };

      image.onerror = (error) => {
        reject(error);
      };

      image.src = imgsrc;
    })
  }

  private cleanUpDomElements(styleElement: HTMLStyleElement, canvas: HTMLCanvasElement) {
    [styleElement, canvas].forEach(x => x?.parentNode?.removeChild(x));
  }

  loadSVGIcons = async (iconsToImport: string[]): Promise<Map<string, string>> => {
    const svgIcons = new Map<string, string>();
    const promises = iconsToImport.map(x => this.loadSVGImageAsBase64(x));
    const allIconsData: SVGIconData[] = await Promise.all(promises);

    allIconsData.forEach(({ name, svgImageBase64 }: SVGIconData) => {
      svgIcons.set(name, svgImageBase64);
    });

    return svgIcons;
  }

  loadSVGImageAsBase64 = async (name: string): Promise<SVGIconData> => {
    const response = await fetch(`./assets/icons/${name}.svg`);
    const arrayBuffer = await response.arrayBuffer();
    const base64 = btoa(String.fromCharCode(...new Uint8Array(arrayBuffer)));
    const svgImageBase64: string = `data:image/svg+xml;base64,${base64}`;
    return { name, svgImageBase64 };
  }
}
