import { DOCUMENT } from '@angular/common';
import { AfterViewInit, Component, ElementRef, EventEmitter, Inject, Input, OnChanges, Output, SimpleChanges, ViewChild, ViewEncapsulation } from '@angular/core';
import { DraggableDirective } from '@app/@shared/directives/draggable.directive';
import { D3CircularHierarchyTreeData } from '@app/@shared/model/d3/circular-hierarchy-tree.model';
import { ID3CircularDiameterData } from '@app/@shared/model/interface/iD3CircularDiameterData';
import { D3GraphHelperService } from '@app/@shared/services/d3/download-d3-graph.service';
import { verify } from 'crypto';
import { HierarchyNode } from 'd3';
import * as d3 from 'd3';

@Component({
  selector: 'app-d3-circular-hierarchy-tree',
  templateUrl: './circular-hierarchy-tree.component.html',
  styleUrls: ['./circular-hierarchy-tree.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class CircularHierarchyTreeComponent implements OnChanges, AfterViewInit {
  @ViewChild('svgContainer', { read: ElementRef, static: true }) svgContainerRef!: ElementRef<SVGElement>;

  @Input() id?: string;

  @Input() data: D3CircularHierarchyTreeData[];
  @Input() selectedData: D3CircularHierarchyTreeData[];
  @Input() hideUnselectedData: boolean = false;

  @Output() selectedDataChange = new EventEmitter<D3CircularHierarchyTreeData[]>();
  @Output() componentReady = new EventEmitter<boolean>();

  isRendered = false;
  svgWidth: number;

  __ACTIVE_DIAMETER: ID3CircularDiameterData;
  __DIAMETER_PROP: any = {
    XXS: {
      value: 600,
      segmentClass: "segment segment-conn-xxs",
      bufferClass: "buffer-xxs",
      bridgeClass: "bridge-xxs",
      bridgeDY: 43,
      bridgeDX: 10,
      bridgeDY_180: 60,
      bridgeDX_180: 30
    },
    XS: {
      value: 600,
      segmentClass: "segment segment-conn-sm",
      bufferClass: "buffer-xs",
      bridgeClass: "bridge-xs",
      bridgeDY: 43,
      bridgeDX: 10,
      bridgeDY_180: 60,
      bridgeDX_180: 30
    },
    SM: {
      value: 650,
      segmentClass: "segment segment-conn-sm",
      bufferClass: "buffer-sm",
      bridgeClass: "bridge",
      bridgeDY: 43,
      bridgeDX: 10,
      bridgeDY_180: 60,
      bridgeDX_180: 30
    },
    ME: {
      value: 950,
      segmentClass: "segment segment-conn-md",
      bufferClass: "buffer-md",
      bridgeClass: "bridge-md",
      bridgeDY: 43,
      bridgeDX: 10,
      bridgeDY_180: 60,
      bridgeDX_180: 30
    },
    LA: {
      value: 1280,
      segmentClass: "segment segment-conn-lg",
      bufferClass: "buffer",
      bridgeClass: "bridge",
      bridgeDY: 43,
      bridgeDX: 5,
      bridgeDY_180: 60,
      bridgeDX_180: 30
    }
  };

  _document;
  constructor(
    private d3GraphHelperService: D3GraphHelperService,
    @Inject(DOCUMENT) private document: Document
  ) {
    this._document = document;
  }

  ngOnChanges(changes: SimpleChanges) {
    if (!this.isRendered) {
      return;
    }

    if (changes['data']) {
      this.render(this.data);
    }

    if (changes['selectedData']) {
      this.updateFiberSelection();
    }

    if (changes['hideUnselectedData']) {
      this.updateLinksVisibility();
    }
  }

  ngAfterViewInit(): void {
    this.render(this.data);

    if (this.selectedData.length) {
      this.updateFiberSelection();
    }

    this.isRendered = true;
    this.componentReady.emit(true);
  }

  updateFiberSelection() {
    const svg = d3.select(this.svgContainerRef.nativeElement);
    const link = svg.selectAll('.link');

    const removeClassToAllElements = () => {
      ['link--target--clicked', 'link--source--clicked'].forEach(className => {
        link.classed(className, (l: any) => {
          l.source.clicked = false;
          l.target.clicked = false;
          return false;
        });
      });
    };

    const addClassToSelectedElements = () => {
      link.classed('link--target--clicked', (l: any) => {
        if (this.selectedData.some(x => x === l.target.data) || l.source.clicked) {
          l.source.clicked = true;
          return true;
        } else {
          return false;
        }
      }).classed('link--source--clicked', (l: any) => {
        if (this.selectedData.some(x => x === l.source.data) || l.target.clicked) {
          l.target.clicked = true;
          return true;
        } else {
          return false;
        }
      });
    }

    removeClassToAllElements();
    addClassToSelectedElements();

    if (this.hideUnselectedData) {
      this.updateLinksVisibility(link);
    }
  }

  updateLinksVisibility(link?) {
    if (!link) {
      const svg = d3.select(this.svgContainerRef.nativeElement);
      link = svg.selectAll('.link');
    }

    link.classed('invisible-link', (l: any) => this.hideUnselectedData && (!l.target.clicked && !l.source.clicked));
  }

  render(data: D3CircularHierarchyTreeData[]) {
    d3.select(this.svgContainerRef.nativeElement).selectAll('*').remove();

    if (data.length > 240) {
      this.__ACTIVE_DIAMETER = this.__DIAMETER_PROP.LA;
    } else if (data.length <= 48) {
      this.__ACTIVE_DIAMETER = this.__DIAMETER_PROP.XXS;
    }
    else if (data.length <= 72) {
      this.__ACTIVE_DIAMETER = this.__DIAMETER_PROP.XS;
    }
    else if (data.length <= 120) {
      this.__ACTIVE_DIAMETER = this.__DIAMETER_PROP.SM;
    }
    else {
      this.__ACTIVE_DIAMETER = this.__DIAMETER_PROP.ME;
    }

    //Dynamic diameter (For buffer position to fit each other)
    const diameter = this.__ACTIVE_DIAMETER.value;
    const radius = diameter / 2;
    const innerRadius = radius - 120;
    const cluster = d3.cluster().size([360, innerRadius]);

    const line = d3.lineRadial<any>()
      .curve(d3.curveBundle.beta(0.85))
      .radius(function (d) { return (<any>d).y; })
      .angle(function (d) { return (<any>d).x / 180 * Math.PI; });

    // Pareciera que offsetWidth no existe en un elemento SVG, por lo que siempre el offset era radius.
    // const offsetWidth = this.svgContainerRef.nativeElement?.offsetWidth;
    // const offset = offsetWidth ? offsetWidth / 2 : radius;
     this.svgWidth = this.svgContainerRef.nativeElement.clientWidth * 6;
    

    const offset = this.svgWidth / 2;

    const svg = d3.select(this.svgContainerRef.nativeElement)
      .attr('width', this.svgWidth)
      .attr('height', diameter)
      //.attr('style', 'margin-top: -50px')
      .append('g')
      .attr('transform', 'translate(' + (offset) + ',' + radius + ')');

    let link = svg.append('g').selectAll('.link');
    let node = svg.append('g').selectAll('.node');


    let segName = svg.append('g').selectAll('.segName');
    let segName2 = svg.append('g').selectAll('.segName');

    const root = this.packageHierarchy(data).sum((d) => d.size);
    cluster(root);

    link = link
      .data(packageImports(root.leaves()))
      .enter()
      .append('path')
      .each((d) => { d.source = d[0], d.target = d[d.length - 1]; })
      .attr('class', (d) => 'link fi-' + d.source.data.key.toLowerCase())
      .attr('d', line);

    const mouseclick = (d) => {
      const nodeData = d.target.__data__;
      const targetData = nodeData?.data;

      let l1 = link.filter((l) => (<any>l).target === nodeData || (<any>l).source === nodeData);

      if (l1.empty()) {
        return;
      }

      if (!this.selectedData.find(x => x === targetData)) {
        // l1.attr('class', l1.attr('class') + ' link--target--clicked')
        this.selectedDataChange.emit([...this.selectedData, targetData]);
      } else {
        this.selectedDataChange.emit([...this.selectedData.filter(x => x !== targetData)]);
      }
    };

    //Renderización de fibras
    node = node
      .data(root.leaves())
      .enter()
      .append('text')
      //.attr('class', 'node')
      .attr('class', (d: HierarchyNode<D3CircularHierarchyTreeData>) => 'node bf-' + d.data.key.toLowerCase())
      .attr('dy', '0.31em')
      .attr('connection', (d: HierarchyNode<D3CircularHierarchyTreeData>) => d.data.mainAttr.name)
      .attr('transform', (d) => 'rotate(' + ((<any>d).x - 90) + ')translate(' + (((<any>d).y) + 8) + ',0)' + ((<any>d).x < 180 ? '' : 'rotate(180)'))
      .attr('text-anchor', (d) => (<any>d).x < 180 ? 'start' : 'end')
      .text((d: HierarchyNode<D3CircularHierarchyTreeData>) => d.data.key)
      .attr('dx-value', (d) => (<any>d).x)
      .attr('dy-value', (d) => (<any>d).y)
      .on('mouseover', mouseovered)
      .on('mouseout', mouseouted)
      .on('click', mouseclick)
      .append('title').text((d: HierarchyNode<D3CircularHierarchyTreeData>) => d.data.mainAttr.service || '');


    //Get max order by buffer
    const mappedData = root.leaves().reduce((result, node) => {
      const { traceID, fiberOrder } = node.data.mainAttr;

      if (traceID in result) {
        result[traceID] = Math.max(result[traceID], fiberOrder / node.data.bufferLength);
      } else {
        result[traceID] = fiberOrder / node.data.bufferLength;
      }

      return result;
    }, {});

    const mappedArray = Object.entries(mappedData);
    //console.log(mappedArray);

    /***
 *    d8888b. db    db d88888b d88888b d88888b d8888b. .d8888. 
 *    88  `8D 88    88 88'     88'     88'     88  `8D 88'  YP 
 *    88oooY' 88    88 88ooo   88ooo   88ooooo 88oobY' `8bo.   
 *    88~~~b. 88    88 88~~~   88~~~   88~~~~~ 88`8b     `Y8b. 
 *    88   8D 88b  d88 88      88      88.     88 `88. db   8D 
 *    Y8888P' ~Y8888P' YP      YP      Y88888P 88   YD `8888Y' 
 */


    if (data.length <= 72) {
      let buffer = svg.append('g').selectAll('.buffer');
      buffer = buffer
        .data(root.leaves())
        .enter()
        .append('g') // crea un nuevo elemento g para cada nodo
        .attr('class', (d: HierarchyNode<D3CircularHierarchyTreeData>) => {
          return this.__ACTIVE_DIAMETER.bufferClass + ' bf-' + d.data.parent.key.toLowerCase() + ' fi-' + d.data.parent.key.toLowerCase();
        })
        .attr('transform', function (d: HierarchyNode<D3CircularHierarchyTreeData>) {
          return 'rotate(' + ((<any>d).x - 90) + ')translate(' + ((<any>d).x > 180 ? (<any>d).y + 35 : (<any>d).y + 35) + ',' + ((<any>d).x > 180 ? '0' : '0') + ')' + ((<any>d).x <= 180 ? '' : 'rotate(180)');
        })
        .each(function (d: HierarchyNode<D3CircularHierarchyTreeData>) {
          d3.select(this)
            .append('circle')
            .attr('r', '5')
            .attr("x_value", (d: any) => { return d.x; })
            .attr("y_value", (d: any) => { return d.y; })
            .attr("fiberOrder", function (d: HierarchyNode<D3CircularHierarchyTreeData>) { return d.data.mainAttr.fiberOrder; })
            .attr("fiberID", function (d: HierarchyNode<D3CircularHierarchyTreeData>) { return d.data.mainAttr.fiberID; })
            .attr('buffer', function (d: HierarchyNode<D3CircularHierarchyTreeData>) { return d.data.mainAttr.buffer + '-' + d.data.bufferIndex; });

          if (d.data.mainAttr.fiberOrder === 1) {
            d3.select(this)
              .append('circle')
              .attr('class', 'special-circle')
              .attr('r', '8')
              .attr('stroke', 'none')
              .attr('stroke-width', '2')
              .attr('fill', 'white')
              .attr('transform', function () {
                return (<any>d).x < 180 ? 'translate(15, -15)' : 'translate(-15, 15)';
              });
          }
        });

    } else {

      let buffer = svg.append('g').selectAll('.buffer');
      buffer = buffer
        .data(root.leaves())
        .enter().append('rect')
        .attr('class', (d: HierarchyNode<D3CircularHierarchyTreeData>) => {
          return this.__ACTIVE_DIAMETER.bufferClass + ' bf-' + d.data.parent.key.toLowerCase() + ' fi-' + d.data.parent.key.toLowerCase();
        })
        .attr('width', '10')
        .attr("x_value", (d: any) => { return d.x; })
        .attr("y_value", (d: any) => { return d.y; })
        .attr("fiberOrder", function (d: HierarchyNode<D3CircularHierarchyTreeData>) { return d.data.mainAttr.fiberOrder; })
        .attr("fiberID", function (d: HierarchyNode<D3CircularHierarchyTreeData>) { return d.data.mainAttr.fiberID; })
        .attr('buffer', function (d: HierarchyNode<D3CircularHierarchyTreeData>) { return d.data.mainAttr.buffer + '-' + d.data.bufferIndex; })
        .attr('transform', function (d: HierarchyNode<D3CircularHierarchyTreeData>) { return 'rotate(' + ((<any>d).x - 90) + ')translate(' + ((<any>d).x > 180 ? (<any>d).y + 40 : (<any>d).y + 30) + ',' + ((<any>d).x > 180 ? '5' : '-5') + ')' + ((<any>d).x <= 180 ? '' : 'rotate(180)'); });


    }



    /***
 *    .d8888. d88888b  d888b  .88b  d88. d88888b d8b   db d888888b .d8888. 
 *    88'  YP 88'     88' Y8b 88'YbdP`88 88'     888o  88 `~~88~~' 88'  YP 
 *    `8bo.   88ooooo 88      88  88  88 88ooooo 88V8o 88    88    `8bo.   
 *      `Y8b. 88~~~~~ 88  ooo 88  88  88 88~~~~~ 88 V8o88    88      `Y8b. 
 *    db   8D 88.     88. ~8~ 88  88  88 88.     88  V888    88    db   8D 
 *    `8888Y' Y88888P  Y888P  YP  YP  YP Y88888P VP   V8P    YP    `8888Y' 
 */
    let segment = svg.append('g').selectAll('.segment');

    if (data.length <= 48) {

      segment = segment
        .data(root.leaves())
      svg.append('circle')
        .attr('cx', 0)
        .attr('cy', 0)
        .attr('r', diameter / 2.6)
        .attr('fill', 'none')
        .attr('stroke', 'black')
        .attr('stroke-width', 5)
        .lower();
       } else if (data.length <= 72) {

        segment = segment
          .data(root.leaves())
        svg.append('circle')
          .attr('cx', 0)
          .attr('cy', 0)
          .attr('r', diameter / 2.6)
          .attr('fill', 'none')
          .attr('stroke', 'black')
          .attr('stroke-width', 5)
          .lower();

       } else {

      segment = segment
        .data(root.leaves())
        .enter().append('rect')
        .attr("data", (d: HierarchyNode<D3CircularHierarchyTreeData>) => { return d.data.mainAttr.traceID + "_" + d.data.mainAttr.name + "_" + d.data.mainAttr.fiberOrder; })
        .attr("bix", (d: HierarchyNode<D3CircularHierarchyTreeData>) => { return d.data.bufferIndex; })
        .attr("blh", (d: HierarchyNode<D3CircularHierarchyTreeData>) => { return d.data.bufferLength; })
        .attr("traceID", (d: any) => { return d.data.traceID })
        .attr("x_value", (d: any) => { return d.x; })
        .attr("y_value", (d: any) => { return d.y; })
        .attr('class', (d: HierarchyNode<D3CircularHierarchyTreeData>) => {
          //        let maxOrder = this.getMaxOrderFromBuffer(mappedArray, d.data);
          return this.__ACTIVE_DIAMETER.segmentClass;
        })
        .attr('width', '15')
        .attr('trace', (d: HierarchyNode<D3CircularHierarchyTreeData>) => d.data.mainAttr.name)
        .attr('transform', function (d: any) {
          if (d.x > 180) {
            return 'rotate(' + (d.x - 90) + ')translate(' + (d.y + 60) + ',5)rotate(180)';
          }
          else {
            return 'rotate(' + (d.x - 90) + ')translate(' + (d.y + 45) + ',-5)';
          }
        })
        .lower();


    }
    /***
*    .d8888. d88888b  d888b  .88b  d88. d88888b d8b   db d888888b      d8888b. d8888b. d888888b d8888b.  d888b  d88888b 
*    88'  YP 88'     88' Y8b 88'YbdP`88 88'     888o  88 `~~88~~'      88  `8D 88  `8D   `88'   88  `8D 88' Y8b 88'     
*    `8bo.   88ooooo 88      88  88  88 88ooooo 88V8o 88    88         88oooY' 88oobY'    88    88   88 88      88ooooo 
*      `Y8b. 88~~~~~ 88  ooo 88  88  88 88~~~~~ 88 V8o88    88         88~~~b. 88`8b      88    88   88 88  ooo 88~~~~~ 
*    db   8D 88.     88. ~8~ 88  88  88 88.     88  V888    88         88   8D 88 `88.   .88.   88  .8D 88. ~8~ 88.     
*    `8888Y' Y88888P  Y888P  YP  YP  YP Y88888P VP   V8P    YP         Y8888P' 88   YD Y888888P Y8888D'  Y888P  Y88888P 
*/

    // if (data.length <= 48) {


    //Get the very last position of each buffer inside a cable to add a "link bridge" between the buffers
    const maxValuesByGroup: { [key: string]: any } = {};
    segment.data().filter((x: any) => x.data.bufferIndex != x.data.bufferLength).forEach((item: any) => {
      if (maxValuesByGroup[item.data.mainAttr.buffer + "_" + item.data.traceID] === undefined || item.data.mainAttr.fiberOrder > maxValuesByGroup[item.data.mainAttr.buffer + "_" + item.data.traceID].data.mainAttr.fiberOrder) {
        maxValuesByGroup[item.data.mainAttr.buffer + "_" + item.data.traceID] = item;
      }
    });

    let bridge = svg.append("g").selectAll('.' + this.__ACTIVE_DIAMETER.bridgeClass);
    bridge = bridge
      .data(Object.values(maxValuesByGroup))
      .enter().append('rect')
      .attr("x_value", (d: any) => { return d.x; })
      .attr("y_value", (d: any) => { return d.y; })
      .attr("traceID", (d: any) => { return d.data.traceID })

      .attr("type", (d: HierarchyNode<D3CircularHierarchyTreeData>) => { return "bridge"; })
      .attr('class', (d: HierarchyNode<D3CircularHierarchyTreeData>) => { return this.__ACTIVE_DIAMETER.bridgeClass; })
      //        let maxOrder = this.getMaxOrderFromBuffer(mappedArray, d.data);
      .attr('transform', (d: any) => {
        if (d.x > 180) {
          return `rotate(${(d.x - 90)})translate(${(d.y + this.__ACTIVE_DIAMETER.bridgeDY_180)},${this.__ACTIVE_DIAMETER.bridgeDX_180})rotate(180)`;
        }
        else {
          return `rotate(${(d.x - 90)})translate(${(d.y + this.__ACTIVE_DIAMETER.bridgeDY)}, ${this.__ACTIVE_DIAMETER.bridgeDX})`;
        }
      });
    // } else {
    // //Get the very last position of each buffer inside a cable to add a "link bridge" between the buffers
    // const maxValuesByGroup: { [key: string]: any } = {};
    // segment.data().filter((x: any) => x.data.bufferIndex != x.data.bufferLength).forEach((item: any) => {
    //   if (maxValuesByGroup[item.data.mainAttr.buffer + "_" + item.data.traceID] === undefined || item.data.mainAttr.fiberOrder > maxValuesByGroup[item.data.mainAttr.buffer + "_" + item.data.traceID].data.mainAttr.fiberOrder) {
    //     maxValuesByGroup[item.data.mainAttr.buffer + "_" + item.data.traceID] = item;
    //   }
    // });

    // let bridge = svg.append("g").selectAll('.' + this.__ACTIVE_DIAMETER.bridgeClass);
    // bridge = bridge
    //   .data(Object.values(maxValuesByGroup))
    //   .enter().append('circle')
    //   .attr("x_value", (d: any) => { return d.x; })
    //   .attr("y_value", (d: any) => { return d.y; })
    //   .attr("traceID", (d: any) => { return d.data.traceID })
    //   .attr("type", (d: HierarchyNode<D3CircularHierarchyTreeData>) => { return "bridge"; })
    //   .attr('class', (d: HierarchyNode<D3CircularHierarchyTreeData>) => { return this.__ACTIVE_DIAMETER.bridgeClass; })
    //   .attr('r', '6')
    //   .attr('transform', (d: any) => {
    //     if (d.x > 180) {
    //       return `rotate(${(d.x - 90)})translate(${(d.y + this.__ACTIVE_DIAMETER.bridgeDY_180) - 10},${this.__ACTIVE_DIAMETER.bridgeDX_180 - 15})rotate(180)`;
    //     }
    //     else {
    //       return `rotate(${(d.x - 90)})translate(${(d.y + this.__ACTIVE_DIAMETER.bridgeDY) + 7}, ${this.__ACTIVE_DIAMETER.bridgeDX + 5})`;
    //     }
    //   });
    // }
    /***
     // }
    /***
*    .d8888. d88888b  d888b  .88b  d88. d88888b d8b   db d888888b      d8b   db  .d8b.  .88b  d88. d88888b 
*    88'  YP 88'     88' Y8b 88'YbdP`88 88'     888o  88 `~~88~~'      888o  88 d8' `8b 88'YbdP`88 88'     
*    `8bo.   88ooooo 88      88  88  88 88ooooo 88V8o 88    88         88V8o 88 88ooo88 88  88  88 88ooooo 
*      `Y8b. 88~~~~~ 88  ooo 88  88  88 88~~~~~ 88 V8o88    88         88 V8o88 88~~~88 88  88  88 88~~~~~ 
*    db   8D 88.     88. ~8~ 88  88  88 88.     88  V888    88         88  V888 88   88 88  88  88 88.     
*    `8888Y' Y88888P  Y888P  YP  YP  YP Y88888P VP   V8P    YP         VP   V8P YP   YP YP  YP  YP Y88888P 
*/

    /*
    const tracesInDiagram: { [key: number]: any } = {};
    segment.data().forEach((item: any) => {
      if (tracesInDiagram[item.data.traceID] === undefined) {
        tracesInDiagram[item.data.traceID] = {
          traceID: item.data.traceID, 
          traceMslink: item.data.traceMslink, 
          side: item.data.side, 
          name: item.data.mainAttr?.name,
          dX: undefined,
          dY: undefined, 
          initialX: undefined,
          initialY: undefined,
          endX: undefined,
          endY: undefined 
        };
      }
    });

    Object.values(tracesInDiagram).forEach(t=> {
      const svgTraceElements = document.querySelectorAll(`[traceID="${t.traceID}"]:not([class^="bridge"])`);
      const initialBounding = (<any>svgTraceElements[0]).parentElement.getBBox();
      t.initialX = Math.round(initialBounding.x);
      t.initialY = Math.round(initialBounding.y);

      const endBounding = (<any>svgTraceElements[svgTraceElements.length -1]).parentElement.getBBox();
      t.endX = Math.round(endBounding.x);
      t.endY = Math.round(endBounding.y);

      t.dX = Number(svgTraceElements[0].getAttribute("x_value"));
      t.dY = Number(svgTraceElements[0].getAttribute("y_value"));
      
      svgTraceElements[0].setAttribute("x_value_abs", Math.round(initialBounding.x).toString());
      svgTraceElements[0].setAttribute("y_value_abs", Math.round(initialBounding.y).toString());

      svgTraceElements[svgTraceElements.length -1].setAttribute("x_value_abs", Math.round(endBounding.x).toString());
      svgTraceElements[svgTraceElements.length -1].setAttribute("y_value_abs", Math.round(endBounding.y).toString());
    });

    segName = segName
    .data(Object.values(tracesInDiagram))
    .enter()
    .append("path")
    .attr("id", (t)=> { return `tname_${t.traceID}`; })
    //.attr("d", (t)=> { return `M${t.initialX} ${t.initialY} A ${radius} ${radius} 0 0 ${(t.initialX < 180 ? "1" : "0")} ${t.endX} ${t.endY}`; })
    .attr("d", (t)=> { return `A ${radius} ${radius} 0 0 ${(t.initialX < 180 ? "1" : "0")}`; })
    .attr("transform", (t)=> { return `translate(${t.initialX},${t.initialY})`});


    segName = segName
    .data(Object.values(tracesInDiagram))
    .enter()
    .append("text")
    .attr("fill", "black")
    .append("textPath").text((t)=> { return t.name; })
    .attr("xlink:href", (t)=> {
      return "#" + `tname_${t.traceID}`;
    });
*/
    let segPositionIndex = [];


    const self = this;
    const directive = new DraggableDirective(new ElementRef(self), self._document);

    segName = segName
      .data(root.leaves().filter(l => l.data.name.includes('AZ.AZ')))
      .enter()
      .append('text')
      .text((d: HierarchyNode<D3CircularHierarchyTreeData>) => {
        let retval = d.data.mainAttr.name;
        if (d.data.side && d.data.side != "") {
          retval += " (" + d.data.side + ")";
        }
        return retval;
      })
      .attr('class', 'segment-name')
      .attr('transform', (d: HierarchyNode<D3CircularHierarchyTreeData>) => {
        let rotation = (<any>d).x - 90;
        rotation = rotation * (-1);

        const position = calculateSegmentNamePosition(d);
        segPositionIndex.push({ x: position.x, y: position.y, r: rotation });

        let retval = 'rotate(' + ((<any>d).x - 90) + ')translate(' + position.x + ',' + position.y + ')rotate(' + rotation + ')';
        if (position.verticalRotation) {
          retval += "rotate(" + position.verticalRotation + ")";
        }
        return retval;
      })
      .attr('dx-value', function (d) {
        const position = calculateSegmentNamePosition(d);
        return (<any>d).x;
      })
      .attr('dy-value', function (d) {
        const position = calculateSegmentNamePosition(d);
        return (<any>d).y;
      })
      .attr('rotation', function (d) { return [(<any>d).x - 90, ((<any>d).x - 90) * (-1)]; })
      .attr('id', function (d) { return `name_${(<any>d).data.traceID}`; })
      .attr('text-anchor', (d: HierarchyNode<D3CircularHierarchyTreeData>) => {
        return (<any>d).x > 180 ? 'end' : 'start';
      });

  /*.on('mousedown', function(event, d) {
    directive.onMouseDown(event , d, `name_${(<any>d).data.traceID}`);
  })
  .on('mouseup', function(d) {
    directive.onMouseUp(`name_${(<any>d).data.traceID}`);
  })
  .on('mousemove', function(event, d) {
    directive.onMouseMove(event, d, `name_${(<any>d).data.traceID}`);
  })*/;


    function mouseovered(d) {
      node.each((n) => (<any>n).target = (<any>n).source = false);

      link
        .classed('link--target', function (l) {
          if ((<any>l).target === d) {
            return (<any>l).source.source = true;
          } else {
            return null;
          }
        })
        .classed('link--source', function (l) {
          if ((<any>l).source === d) {
            return (<any>l).target.target = true;
          } else {
            return null;
          }
        })
        .filter(function (l) { return (<any>l).target === d || (<any>l).source === d; })
        .raise();

      node
        .classed('node--target', function (n) { return (<any>n).target; })
        .classed('node--source', function (n) { return (<any>n).source; });
    }

    function mouseouted(d) {
      link
        .classed('link--target', false)
        .classed('link--source', false);

      node
        .classed('node--target', false)
        .classed('node--source', false);
    }

    function calculateSegmentNamePosition(d: HierarchyNode<D3CircularHierarchyTreeData>) {
      let text = d.data.mainAttr.name;
      let horizontalPos = ((<any>d).y + 75);
      let verticalPos = 0;
      let verticalRotation;

      if ((<any>d).x >= 0 && (<any>d).x < 70) {
        //Posición por defecto
      } else if ((<any>d).x >= 70 && (<any>d).x < 140) {
        verticalPos = 10;
      } else if ((<any>d).x >= 140 && (<any>d).x < 210) {
        horizontalPos += 0;
        verticalPos = 80;
      } else if ((<any>d).x >= 210 && (<any>d).x < 240) {
        horizontalPos += 80;
        verticalPos = 70;
        // verticalRotation = "-90";
      } else if ((<any>d).x >= 240 && (<any>d).x < 260) {
        horizontalPos += 20;
        verticalPos = 50;
        // verticalRotation = "-105";
      } else if ((<any>d).x >= 260 && (<any>d).x < 280) {
        horizontalPos += 20;
        verticalPos = 50;
        // verticalRotation = "-75";
      } else if ((<any>d).x >= 280 && (<any>d).x < 360) {
        horizontalPos += 20;
        // verticalRotation = "-60";
      }

      //Will be overlapped. Move
      while (segPositionIndex.some(s => s.x == horizontalPos && s.y == verticalPos)) {
        horizontalPos -= 10;
        verticalPos += 5;
      }

      return { x: horizontalPos, y: verticalPos, verticalRotation };
    }

    // Return a list of imports for the given array of nodes.
    function packageImports(nodes) {
      const map = {};
      const imports = [];

      // Compute a map from name to node.
      nodes.forEach((d: HierarchyNode<D3CircularHierarchyTreeData>) => map[d.data.name] = d);

      // For each import, construct a link from the source to target node.
      nodes.forEach((d: HierarchyNode<D3CircularHierarchyTreeData>) => {
        try {
          imports.push(map[d.data.name].path(map[d?.data?.imports]));
        } catch (ex) {
          // console.error(ex);
        }
      });

      return imports;
    }
  };


  getMaxOrderFromBuffer(mappedArray, data): number {
    let a = mappedArray.find(item => item[0] === data.traceID.toString());
    let maxOrder = 12;
    if (a) {
      maxOrder = <number>a[1] * data.bufferIndex;
    }

    return maxOrder;
  }

  // Lazily construct the package hierarchy from class names.
  packageHierarchy(classes) {
    const map = {};

    function find(name, data?) {
      let node = map[name], i;

      if (!node) {
        node = map[name] = data || { name: name, children: [] };
        if (name.length) {
          node.parent = find(name.substring(0, i = name.lastIndexOf('.')));
          node.parent.children.push(node);
          node.key = name.substring(i + 1);
        }
      }
      return node;
    }

    classes.forEach(function (d) {
      find(d.name, d);
    });

    return d3.hierarchy(map['']);
  }

  // This function is executed by accessing to this component via ViewChild/ViewChildren. Don't delete.
  // Se ejecuta esta funcion mediante ViewChild/ViewChildren. No borrar.
  downloadD3Graph(): Promise<void> {
    if (!this.svgContainerRef?.nativeElement) {
      return Promise.reject(new Error('svgContainerRef not found'));
    }

    this.changeCircleColor('.special-circle', '#cedae2');

    return this.d3GraphHelperService.downloadCircularHierarchyTree({
      svg: this.svgContainerRef.nativeElement,
      fileName: this.id,
      extension: 'jpg',
      diameter: this.__ACTIVE_DIAMETER.value,
      width: this.svgWidth
    }).then(() => {
      this.resetCircleColor('.special-circle', 'white');
    }).catch((error) => {
      console.error('Error downloading graph:', error);

      this.resetCircleColor('.special-circle', 'white');
    });
  }

  changeCircleColor(selector: string, color: string): void {
    d3.selectAll(selector)
      .attr('fill', color);
  }

  resetCircleColor(selector: string, defaultColor: string): void {
    d3.selectAll(selector)
      .attr('fill', function (d: any) {
        return defaultColor;
      });
  }
}
