import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class SearchAreaService {
  searchArea : number;

  constructor() {
    this.searchArea = 0;
  }

  setMapSource(newValue : number): void {
    this.searchArea = newValue;
  }
}
