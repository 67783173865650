import { Component, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { Connection } from '@app/@shared/model/connection.model';
import { Container } from '@app/@shared/model/container.model';
import { Device } from '@app/@shared/model/device.model';
import { DeviceService } from '@app/@shared/services/map-elements-services/device.service';
import { TranslateService } from '@ngx-translate/core';
import { exportDataGrid } from 'devextreme/excel_exporter';
import { Workbook, Worksheet } from 'exceljs';
import * as saveAs from 'file-saver';
import { ExcelJSHelperService } from '@app/@shared/services/aux-services/excelJS-helper.service';
import { DeviceDetailComponent } from '../device-detail/device-detail.component';
import { ContainerService } from '@app/@shared/services/map-elements-services/container.service';
import { TraceService } from '@app/@shared/services/map-elements-services/trace.service';
import { firstValueFrom } from 'rxjs';
import { ConnectionService } from '@app/@shared/services/map-elements-services/connection.service';
import { DataTransferService } from '@app/@shared/services/d3/data-transfer.service';
import { AppRoutingService } from '@app/app-routing.service';

@Component({
  selector: 'container-detail',
  templateUrl: './container-detail.component.html',
  styleUrls: ['./container-detail.component.scss'],
})
export class ContainerDetailComponent implements OnInit {

  @Output() connectionSelected = new EventEmitter<Connection>();
  @ViewChild(DeviceDetailComponent, { static: false }) deviceDetail: DeviceDetailComponent;

  ds_accordion_element = [];
  accordion_element_selected = [];
  ds_accordion_device = [];
  ds_accordion_device_selected = [];

  showLoading: boolean = false;

  _entity: Container;
  projectDataDetail: any;
  projectDataDetailUserfId2: any;
  containerID: number;
  platformID: number;
  traceData: any[];
  ductData: any;
  isLoading: boolean;
  excelData: any;
  devicesWithConnections: any[] = [];
  deviceID: number[];
  workbook: Workbook;
  name: string;



  @Input('entity')
  get entity() {
    return this._entity;
  }
  set entity(value) {
    if (value == this._entity) {
      return;
    }
    this._entity = value;
    this.name = this._entity.name;
    this.platformID = this._entity.platformID;
    this.containerID = this._entity.containerID;
    this.projectDataDetail = this._entity.containerAttributes;
    const containerAttributes = this._entity.containerAttributes;

    const userFldFields = Object.keys(containerAttributes)
      .filter(key => key.startsWith('userfld') || key.startsWith('userFld'))
      .reduce((obj, key) => {
        obj[key] = containerAttributes[key];
        return obj;
      }, {});

    this.projectDataDetailUserfId2 = userFldFields;
    this.getTraceAsociatedWithContainerID();
    this.loadEntity();
  }

  detail: Device;
  traceDetail: any;

  constructor(
    private trans: TranslateService,
    private deviceService: DeviceService,
    private excelJSHelperService: ExcelJSHelperService,
    private containerService: ContainerService,
    private traceService: TraceService,
    private connectionService: ConnectionService,
    private dataTransferService: DataTransferService,
    private appRoutingService: AppRoutingService
  ) { }

  ngOnInit() { }

  loadEntity() {
    //Close search panel
    this.ds_accordion_element = [];

    this.ds_accordion_element.push({
      title:
        String.prototype.upperFirstLetter(this.entity.entityName) +
        ' ' +
        this.trans.instant('general.review'),
      data: this.entity,
    });

    this.accordion_element_selected = this.ds_accordion_element;

    this.ds_accordion_device = [];
    this.ds_accordion_device.push({
      title: this.trans.instant('comp.search-panel.devices'),
      data: this.entity.device,
    });

    this.ds_accordion_device_selected = this.ds_accordion_device;
  }

  selectItem($event, data = null) {

    if ($event?.data?.traceID) {

      this.showLoading = true;
      this.traceService.getByID($event.data.traceID).subscribe((res) => {
        if (res) {
          this.accordion_element_selected = [];
          this.traceDetail = res;
        }
        this.showLoading = false;
      });
    } else {
      if (!$event || ($event.length === 0 && !data)) {
        const defaultItem = data && data.length > 0 ? data[0] : null;
        if (defaultItem) {
          this.deviceService.getByID(defaultItem.deviceID).subscribe((res) => {
            if (res) {
              this.accordion_element_selected = [];
              this.detail = res;
            }
          });
        }
        $event = data;
      }

      if (Array.isArray($event)) {
        // $event.forEach((device, index) => {
        //   this.deviceService.getByID(device.deviceID).subscribe((res) => {
        //     time += 500;
        //     if (res) {
        //       setTimeout(() => {
        //         this.detail = res;
        //         this.deviceDetail.prepareToDownloadDevice(res);
        //       }, time);
        //     }
        //   });
        // });
        const promises = $event.map(device =>
          this.deviceService.getByID(device.deviceID).toPromise()
        );

        Promise.all(promises).then(results => {
          // this.deviceDetail.deviceBoxComponent.loading = true;

          this.showLoading = true;
          results.forEach((res, index) => {
            if (res) {
              const delay = (index == 0) ? 8000 : (index + 1) * 8000;
              setTimeout(() => {
                this.detail = res;
                this.deviceDetail.prepareToDownloadDevice(res);
                if (index == results.length - 1) {
                  this.showLoading = false;
                }
              }, delay);
            }
          });
        }).catch(error => {
          console.error('Error al obtener dispositivos:', error);
        });
      } else {
        this.deviceService.getByID($event.data.deviceID).subscribe((res) => {
          if (res) {
            this.accordion_element_selected = [];
            this.detail = res;
          }
        });
      }
    }
  }

  exportDuctDataWithAsociatedTraceData = async ($event, data) => {
    this.isLoading = true;
    try {
      const workbook = new Workbook();

      await this.onExportingDuctData($event, workbook);

      if (Array.isArray(data)) {
        for (const trace of data) {
          const traceData2 = await firstValueFrom(this.traceService.getByID(trace.traceID));
          await this.onExportingTraceData($event, workbook, traceData2);
        }
      } else {
        const traceData2 = await firstValueFrom(this.traceService.getByID(data.traceID));
        await this.onExportingTraceData($event, workbook, traceData2);
      }

      const buffer = await workbook.xlsx.writeBuffer();
      saveAs(
        new Blob([buffer], { type: 'application/octet-stream' }),
        this.entity.name + '_DUCT.xlsx'
      );
    } catch (error) {
      console.error('Error exporting data:', error);
    } finally {
      this.isLoading = false;
    }
  };

  //-------------------------------------------------------------------Export duct data-------------------------------------------------------------------------//

  onExportingDuctData = async (e, workbook) => {
    const worksheet = workbook.addWorksheet('Duct');
    await exportDataGrid({
      component: e.component,
      worksheet,
      autoFilterEnabled: true,
    });
    this.initializeExcelDuctHeaders(worksheet);
    this.writeExcelDuctData(worksheet);
    this.excelJSHelperService.adjustColumnsWidth(worksheet);
  };

  initializeExcelDuctHeaders = (worksheet: Worksheet) => {
    const entityHeader = this._entity.name?.toUpperCase();

    const mslinkLabel = "MSLINK";
    const nameLabel = this.trans.instant('comp.search-panel.excel-header.name')?.toUpperCase();
    const descriptionLabel = this.trans.instant('comp.search-panel.excel-header.description')?.toUpperCase();

    const headers = [
      mslinkLabel,
      nameLabel,
      descriptionLabel
    ];

    worksheet.getRow(1).getCell(1).value = entityHeader;
    worksheet.mergeCells(1, 1, 1, headers.length);
    worksheet.getRow(1).alignment = { horizontal: 'center', vertical: 'middle' };

    headers.forEach((header, index) => {
      const cell = worksheet.getRow(2).getCell(index + 1);
      cell.value = header;
      cell.alignment = { horizontal: 'center' };
      cell.fill = {
        type: 'pattern',
        pattern: 'solid',
        fgColor: { argb: 'FF96C8FB' }
      };
    });

    this.centerTextExcel(worksheet, 2, [1, 2, 3]);
  };

  writeExcelDuctData = (worksheet: Worksheet) => {

    const ductData: any[] = Array.isArray(this.traceData) ? this.traceData : [this.traceData];
    const excelData: string[][] = ductData.map((x) => {
      const mslink = x.msLink;
      const name = x.name;
      const description = x.description;

      return [mslink, name, description];
    });
    this.excelJSHelperService.writeData(worksheet, 3, excelData);
    this.centerTextExcel(worksheet, 3, [1, 2, 3], excelData.length);
  }

  //-------------------------------------------------------------------END: Export duct data-------------------------------------------------------------------------//
  //------------------------------------------------------------------------Export trace data-----------------------------------------------------------------------//

  onExportingTraceData = async (e, workbook, data) => {
    let sheetName = data.name;
    let counter = 1;

    while (workbook.getWorksheet(sheetName)) {
      sheetName = `${data.name} (${counter})`;
      counter++;
    }

    const worksheet = workbook.addWorksheet(sheetName);
    this.initializeExcelTraceHeaders(worksheet);
    this.writeExcelTraceData(worksheet, data);
    this.excelJSHelperService.adjustColumnsWidth(worksheet);
  }


  initializeExcelTraceHeaders = (worksheet: Worksheet) => {
    const bufferLabel = this.trans.instant('comp.search-panel.excel-header.buffer')?.toUpperCase();
    const colorLabel = this.trans.instant('comp.search-panel.excel-header.color')?.toUpperCase();
    const serviceLabel = this.trans.instant('comp.search-panel.excel-header.service')?.toUpperCase();
    const fiberOrderLabel = this.trans.instant('comp.search-panel.excel-header.fiber-order')?.toUpperCase();

    const headers = [
      bufferLabel,
      colorLabel,
      fiberOrderLabel,
      serviceLabel
    ];

    this.excelJSHelperService.writeHeaders(worksheet, headers, 1);
    this.centerTextExcel(worksheet, 1, [1, 2, 3, 4]);
  }

  writeExcelTraceData = (worksheet: Worksheet, data) => {

    const traceData = data;
    const connections: any[] = Array.isArray(traceData) ? traceData : [traceData];
    const excelData: string[][] = connections.reduce((acc, connection) => {

      const fiberData = connection.fiber.map((fiber) => {

        const buffer = fiber.fiberAttributes.buffer;
        const color = fiber.fiberAttributes.color;
        const fiberOrder = fiber.fiberOrder;
        const service = fiber.serviceTrace[0]?.service.name || "";


        return [buffer, color, fiberOrder, service];
      });

      return acc.concat(fiberData);
    }, []);

    this.excelJSHelperService.writeData(worksheet, 2, excelData);
    this.centerTextExcel(worksheet, 2, [1, 2, 3, 4], excelData.length);
  }

  //------------------------------------------------------------------------END: Export trace data-------------------------------------------------------------//
  //------------------------------------------------------------------------Export devices in datagrid-------------------------------------------------------------//

  async onExporting(e) {

    this.isLoading = true;

    this.excelData = e;
    this.workbook = new Workbook();

    const worksheet = this.workbook.addWorksheet('Devices');

    await exportDataGrid({
      component: e.component,
      worksheet,
      autoFilterEnabled: true,
    });

    this.initializeExcelHeaders(worksheet);
    this.writeExcelData(worksheet);
    this.excelJSHelperService.adjustColumnsWidth(worksheet);

    this.deviceID = this.getDeviceID();
    await this.getConnectionFromDeviceID(this.deviceID);

    this.onExportingConnection();

    const finalBuffer = await this.workbook.xlsx.writeBuffer();
    saveAs(
      new Blob([finalBuffer], { type: 'application/octet-stream' }),
      this.entity.name + '_CONTAINER.xlsx'
    );

    this.isLoading = false;
    e.cancel = true;
  }

  initializeExcelHeaders = (worksheet: Worksheet) => {
    const entityLabel = this._entity.name.toUpperCase();
    const idLabel = this.trans.instant('ID')?.toUpperCase();
    const nameLabel = this.trans.instant('comp.search-panel.excel-header.name')?.toUpperCase();
    const descriptionLabel = this.trans.instant('comp.search-panel.excel-header.description')?.toUpperCase();

    worksheet.getCell('A1').value = entityLabel;
    worksheet.getCell('A1').font = { size: 14, bold: true };
    worksheet.mergeCells('A1:C1');

    const headers = [
      idLabel,
      nameLabel,
      descriptionLabel
    ];

    this.excelJSHelperService.writeHeaders(worksheet, headers, 2);
    this.centerTextExcel(worksheet, 2, [1, 2, 3]);
  };

  writeExcelData = (worksheet: Worksheet) => {
    const devices: Device[] = this.ds_accordion_device[0].data;

    const excelData: string[][] = devices.map((x) => {
      const id = String(x.deviceID);
      const name = x.name;
      const description = x.description;

      return [id, name, description];
    });

    this.excelJSHelperService.writeData(worksheet, 3, excelData);
    this.centerTextExcel(worksheet, 3, [1, 2, 3], devices.length);
  }

  //------------------------------------------------------------------------END: Export devices in datagrid-------------------------------------------------------------//
  //------------------------------------------------------------------------ Export connection of devices -------------------------------------------------------------//

  onExportingConnection() {

    this.devicesWithConnections.forEach((device) => {
      const worksheet = this.workbook.addWorksheet(`${device.name}`);

      this.initializeExcelConnectionHeaders(worksheet);
      this.writeExcelConnectionData(worksheet, device.connection);

      this.excelJSHelperService.adjustColumnsWidth(worksheet);
    });
  }

  initializeExcelConnectionHeaders = (worksheet: Worksheet) => {
    const traceLabel = this.trans.instant('comp.search-panel.excel-header.trace')?.toUpperCase();
    const bufferLabel = this.trans.instant('comp.search-panel.excel-header.buffer')?.toUpperCase();
    const colorLabel = this.trans.instant('comp.search-panel.excel-header.color')?.toUpperCase();
    const serviceLabel = this.trans.instant('comp.search-panel.excel-header.service')?.toUpperCase();
    const term1Label = this.trans.instant('comp.search-panel.excel-header.term1')?.toUpperCase();
    const term2Label = this.trans.instant('comp.search-panel.excel-header.term2')?.toUpperCase();
    const oldNameLabel = this.trans.instant('comp.search-panel.excel-header.old-name')?.toUpperCase();
    const bufferIndexLabel = this.trans.instant('comp.search-panel.excel-header.buffer-index')?.toUpperCase();
    const fiberIndexLabel = this.trans.instant('comp.search-panel.excel-header.fiber-index')?.toUpperCase();
    const fiberOrderLabel = this.trans.instant('comp.search-panel.excel-header.fiber-order')?.toUpperCase();

    const headers = [
      term1Label,
      oldNameLabel,
      traceLabel,
      fiberOrderLabel,
      bufferLabel,
      bufferIndexLabel,
      colorLabel,
      fiberIndexLabel,
      serviceLabel,
      bufferLabel,
      bufferIndexLabel,
      colorLabel,
      fiberIndexLabel,
      oldNameLabel,
      traceLabel,
      fiberOrderLabel,
      term2Label
    ];

    this.excelJSHelperService.writeHeaders(worksheet, headers, 1);
    this.centerTextExcel(worksheet, 1, [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17]);

  };

  writeExcelConnectionData = (worksheet: Worksheet, connections: Connection[]) => {
    const excelData: string[][] = connections.map((x) => {
      const formatName = (name: string) =>
        name.includes(' [') ? name.split(' [')[0] : name;

      const getServiceName = () => {
        const serviceName1 = x.elementID1_Rel?.serviceName;
        const serviceName2 = x.elementID2_Rel?.serviceName;

        return [...new Set([serviceName1, serviceName2])]
          .filter((x) => !!x)
          .join(' ,');
      };

      const term1 = x.elementID1_Rel.term1;
      const description1 = x.elementID1_Rel.T1[0].description;
      const trace1 = formatName(x.elementID1_Rel.name);
      const fiberOrder1 = x.elementID1_Rel.fiberOrder;
      const buffer1 = x.elementID1_Rel.fiberAttributes.buffer;
      const bufferIndex1 = x.elementID1_Rel.T1[0].BufferIndex;
      const color1 = x.elementID1_Rel.fiberAttributes.color;
      const fiberIndex1 = this.getFiberIndex(x.elementID1_Rel.T1[0].children, x.elementID1_Rel.fiberID);
      const service = getServiceName();
      const color2 = x.elementID2_Rel.fiberAttributes.color;
      const fiberIndex2 = this.getFiberIndex(x.elementID2_Rel.T2[0].children, x.elementID2_Rel.fiberID);
      const buffer2 = x.elementID2_Rel.fiberAttributes.buffer;
      const bufferIndex2 = x.elementID2_Rel.T2[0].BufferIndex;
      const trace2 = formatName(x.elementID2_Rel.name);
      const description2 = x.elementID2_Rel.T2[0].description;
      const fiberOrder2 = x.elementID2_Rel.fiberOrder;
      const term2 = x.elementID2_Rel.term2;

      return [term1, description1, trace1, fiberOrder1, buffer1, bufferIndex1, color1, fiberIndex1, service, buffer2, bufferIndex2, color2, fiberIndex2, description2, trace2, fiberOrder2, term2];
    });

    this.excelJSHelperService.writeData(worksheet, 2, excelData);
    this.centerTextExcel(worksheet, 1, [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17], connections.length);
  }

  //------------------------------------------------------------------------ END: Export connection of devices-------------------------------------------------------------//

  centerTextExcel(worksheet: Worksheet, startRow: number, cells: number[], rowCount: number = 1) {
    for (let i = 0; i < rowCount; i++) {
      const row = worksheet.getRow(startRow + i);
      cells.forEach((cell) => {
        row.getCell(cell).alignment = { horizontal: 'center' };
      });
    }
  }

  getFiberIndex = (childrenString, fiberID) => {
    const children = JSON.parse('[' + childrenString + ']');
    return children.findIndex(child => child.fiberID === fiberID) + 1;
  }

  getDeviceID = (): number[] => {
    return this.ds_accordion_device[0].data.map((device: Device) => Number(device.deviceID));
  }

  async getConnectionFromDeviceID(deviceIDs: number[]) {
    this.devicesWithConnections = [];

    for (const id of deviceIDs) {
      try {
        const deviceResponse = await this.deviceService.getByID(id).toPromise();

        const connectionResponse = await this.connectionService.getByDeviceID(id, 'FIBER_FIBER').toPromise();
        if (connectionResponse) {
          deviceResponse.connection = connectionResponse;
          deviceResponse.connection.forEach(c => {
            if (!c.elementID1_Rel.fiberAttributes) {
              c.elementID1_Rel.fiberAttributes = JSON.parse(c.elementID1_Rel.fiberData);
            }
            if (!c.elementID2_Rel.fiberAttributes) {
              c.elementID2_Rel.fiberAttributes = JSON.parse(c.elementID2_Rel.fiberData);
            }
          });
          this.devicesWithConnections.push(deviceResponse);
        }
      } catch (error) {
        console.error(`Error al obtener datos para el dispositivo con ID ${id}:`, error);
      }
    }
  }

  getValueOrDefault(value: any): string {
    const stringValue = value !== null && value !== undefined ? String(value) : '';
    return stringValue.trim() !== '' ? stringValue : this.trans.instant('general.no-data');
  }

  getProjectDataDetailUserfld2Fields(): { key: string; value: string }[] {
    return Object.entries(this.projectDataDetailUserfId2 || {}).map(([key, value]) => ({
      key: this.capitalizeFirstLetter(key),
      value: value?.toString().trim() === '' ? this.trans.instant('general.no-data') : String(value)
    }));
  }

  capitalizeFirstLetter(text: string): string {
    if (!text) return '';
    return text.charAt(0).toUpperCase() + text.slice(1).toLowerCase();
  }

  getGroupedFields() {
    const fields = this.getProjectDataDetailUserfld2Fields();
    const groupedFields = [];

    for (let i = 0; i < fields.length; i += 2) {
      groupedFields.push(fields.slice(i, i + 2));
    }
    return groupedFields;
  }

  //Backend requires string container ID for SectionData search
  getTraceAsociatedWithContainerID() {
    this.showLoading = true;
    const containerIDString = this.containerID.toString();

    this.containerService.getTraceAsociatedWithContainerID(containerIDString).subscribe(
      (data) => {
        if (!data || data.length === 0) {
          this.showLoading = false;
        }

        this.traceData = this.processTraceData(data);
        this.showLoading = false;
      },
      (error) => {
        console.error('Error fetching trace data:', error);
        this.showLoading = false;
      }
    );
  }

  processTraceData(data: any[]): any[] {
    return data.map(trace => {
      let sectionDataParsed: any = {};
      try {
        sectionDataParsed = JSON.parse(trace.sectionData);
      } catch (e) {
        console.error('Error parsing sectionData:', e);
      }

      this.showLoading = false;

      return {
        ...trace,
        msLink: sectionDataParsed['mslink'] || 'N/A',
        name: trace.name || 'N/A',
        description: trace.description || 'N/A'
      };
    });


  }
  async openComponentInNewTab() {
    this.showLoading = true;
    const path = '/trace-diagram';
    await this.getFiberFromTraceID();
    
    const url = this.appRoutingService.generateUrl(path);
    this.appRoutingService.navigateTo(url, true); 
  }

  async getFiberFromTraceID() {
    try {
      let traceData2List = [];

      if (Array.isArray(this.traceData)) {
        for (const trace of this.traceData) {
          const traceData2 = await firstValueFrom(this.traceService.getByID(trace.traceID));
          
          traceData2List.push(traceData2);
        }
  
        this.dataTransferService.setTraceData(traceData2List);
        this.dataTransferService.setName(this.name);
        this.dataTransferService.setPlatformID(this.platformID);

        this.showLoading = false;

  
      } else {
        console.error('traceData is not an array');
      }
    } catch (error) {
      console.error('Error retrieving trace data:', error);
    }
  }
}

