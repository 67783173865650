import { Injectable } from '@angular/core';
import { Worksheet } from 'exceljs';

@Injectable({ providedIn: 'root' })
export class ExcelJSHelperService {
  constructor() {}

  writeHeaders = (worksheet: Worksheet, headers: string[], initialRow: number): Worksheet => {
    const headerRow = worksheet.getRow(initialRow);
    headers.forEach((x, i) => {
      headerRow.getCell(i + 1).value = x;
      headerRow.getCell(i + 1).font = { bold: true }; 
      headerRow.getCell(i + 1).fill = { type: 'pattern', pattern: 'solid', fgColor: { argb: 'FF96C8FB' } };
    });
    return worksheet;
  };

  writeData = (
    worksheet: Worksheet,
    row: number,
    data: string[][]
  ): Worksheet => {
    data.forEach((x, i) => {
      const currentRow = worksheet.getRow(row + i);
      currentRow.values = x;

      // Set background color for odd rows
      if ((row + i) % 2 === 1) {
        currentRow.fill = { type: 'pattern', pattern: 'solid', fgColor: { argb: 'FFDCDCDC' }  };
      } 
      
    });
    return worksheet;
  };

  adjustColumnsWidth = (worksheet: Worksheet): Worksheet => {
    const adjustWidth = (worksheet: Worksheet) => {
      worksheet.columns.forEach((column) => {
        const lengths = column.values.map((v) => v.toString().length);
        const maxLength = Math.max(
          ...lengths.filter((v) => typeof v === 'number')
        );
        column.width = maxLength * 2;
      });
    };

    adjustWidth(worksheet);
    return worksheet;
  };
}
