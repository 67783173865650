<div class="mb-4" style="position: relative" *ngIf="!embeddedAddress">
  <dx-text-box labelMode="floating"  height="40"
        [disabled]="!allowEditing"
        labelMode="hidden"
        [style.opacity]="(!allowEditing ? .9 : 1)"
        [showClearButton]="(allowEditing ? true : false)"
        [placeholder]="'feasibility.grid.address' | translate"  (onKeyUp)="onkeyup($event, 6)"
        [(value)]="address" >
  </dx-text-box>
  <div class="address-list address-list-outside">
    <dx-list *ngIf="(!selectedKeys || selectedKeys.length == 0) && address_datasource.length != 0  && address != ''"
      #list
      [dataSource]="address_datasource"
      [height]="height + 'px'"
      selectionMode="single"
      [showSelectionControls]="false"
      [(selectedItemKeys)]="selectedKeys"
      (onItemClick)="onAddressSelected($event)"
      selectByClick="true"
    >
    <div *dxTemplate="let item of 'item'">
     {{ item.place_name }}
    </div>
  </dx-list>
  </div>
</div>

<div [style.height]="height + 'px'" style="position:relative">
  <div class="mb-4" class="embeddedAddress"  *ngIf="embeddedAddress">
    <dx-text-box  height="40"
          labelMode="hidden" 
          [disabled]="!allowEditing"
          [style.opacity]="(!allowEditing ? .9 : 1)"
          [showClearButton]="(allowEditing ? true : false)"
          [placeholder]="'feasibility.grid.address' | translate"  
          (onKeyUp)="onkeyup($event, 5)"
          (onValueChanged)="onValueChanged()"
          [(value)]="address" >
    </dx-text-box>
    <div class="address-list">
      <div class="address-content">
          <dx-list *ngIf="displayOptions"
            #list
            [dataSource]="address_datasource"
            [height]="(height - 100) + 'px'"
            selectionMode="single"
            [showSelectionControls]="false"
            [(selectedItemKeys)]="selectedKeys"
            (onItemClick)="onAddressSelected($event)"
            selectByClick="true"
          >
          <div *dxTemplate="let item of 'item'">
          {{ item.place_name }}
          </div>
        </dx-list>
      </div>
    </div>
  </div>
  <ol-map
    #olMap
    [points]="points"
    [latitude]="latitude"
    [longitude]="longitude"
    [zoom]="zoom"
    [linestring]="_linestring"
    [height]="'100%'"
    (onMapReady)="mapReady()"
  ></ol-map>
</div>