import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root',
})
export class DataTransferService {

  setTraceData(data: any) {
    localStorage.setItem('traceData', JSON.stringify(data));
  }

  getTraceData() {
    const data = localStorage.getItem('traceData');
    return data ? JSON.parse(data) : null;
  }

  setName(name: string) {
    localStorage.setItem('name', name);
  }

  getName() {
    return localStorage.getItem('name');
  }

  setPlatformID(platformID: number) {
    localStorage.setItem('platformID', platformID.toString());
  }

  getPlatformID() {
    const platformID = localStorage.getItem('platformID');
    return platformID ? Number(platformID) : null;
  }
}