import { LocationData } from "../aux-models/locationData";
import { Cons } from '@app/@shared/cons/cons';
import { wktToGeoJSON } from "@terraformer/wkt";

export abstract class IOlMapFeature {
    name: string;
    id: any;
    type: string;
    entity: any;
    visible: boolean = true;
    selected: boolean = false;
    locationData: LocationData;

    abstract toMapFeature(m : any, type: string, color : string, background?: string) : IOlMapFeature;

    get flatCoordinates(): number[][] {
        return this.getFlatCoordinates();
    }

    get coordinateArray() : number[]{
        return this.getCoordinatesArray();
    }

    private getFlatCoordinates(): number[][] {
        if (!this.locationData || !this.locationData.coordinates) {
            return [];
        }

        if (this.locationData.format === Cons._WKT) {
            const coords = this.locationData.coordinates.values;
            const { coordinates } = wktToGeoJSON(coords);

            if (typeof coordinates[0] === 'number') {
                return [ coordinates ];
            }

            return coordinates;
        }
      
        if(this.locationData.format == Cons._GEOJSON){
            let _flatCoord: number[][] = [];
            if (
                this.locationData.coordinates &&
                this.locationData.coordinates.values
            ) {
                this.locationData.coordinates.values.forEach((c) => {
                    _flatCoord.push([c.x, c.y]);
                });
            }
            
            return _flatCoord;
        }

        return [this.locationData.coordinates.values];
    };

    private getCoordinatesArray() : number[]{
        if (!this.locationData || !this.locationData.coordinates) {
            return [];
        }

        if (this.locationData.format === Cons._WKT) {
            const coords = this.locationData.coordinates.values;
            const { coordinates } = wktToGeoJSON(coords);

            if (typeof coordinates[0] === 'number') {
                return coordinates;
            }

            return coordinates;
        }
      
        if(this.locationData.format == Cons._GEOJSON){
            let _flatCoord: number[] = [];
            if (this.locationData.coordinates && this.locationData.coordinates.values) {
                this.locationData.coordinates.values.forEach((c) => {
                    _flatCoord.push(c.x);
                    _flatCoord.push(c.y);
                });
            }
            
            return _flatCoord;
        }

        return this.locationData.coordinates.values;
    }
}