import { Component, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { OlMapComponent } from '@app/@shared/components/ol-map/ol-map.component';
import { Cons } from '@app/@shared/cons/cons';
import { LocationData } from '@app/@shared/model/aux-models/locationData';
import { Mapbox } from '@app/@shared/model/aux-models/mapbox';
import { olMapLineString } from '@app/@shared/model/aux-models/ol-map-models/olMapLineString';
import { olMapPoint } from '@app/@shared/model/aux-models/ol-map-models/olMapPoint';
import { ICommonEntity } from '@app/@shared/model/interface/iCommonEntity';
import { DataSourceService } from '@app/@shared/services/aux-services/datasources.service';
import { MapboxService } from '@app/@shared/services/aux-services/mapbox.service';
import { TranslateService } from '@ngx-translate/core';
import DataSource from 'devextreme/data/data_source';



@Component({
  selector: 'address-form',
  templateUrl: './address-form.component.html',
  styleUrls: ['./address-form.component.scss'],
})
export class AddressFormComponent implements OnInit {

  @Input() allowEditing : boolean = true;
  @Input() required: boolean = false;
  @Input() height: number = 300;
  @Input() embeddedAddress: boolean = false;
  @Input() location: LocationData;
  @Input("locationAddress") 
  get locationAddress(){
    return this.address;
  }
  set locationAddress(value){
    this.address = value;
  }

  /// --------------- LINESTRING ---------------- ///
  _linestring: olMapLineString[] = [];
  @Input('linestring')
  set linestring(value) {
    this._linestring = [];
    this._linestring.push(...value);
  }
  get linestring() {
    return this._linestring;
  }

  @Output() onAddressChanged = new EventEmitter<{ location, placeName }>();
  @Output() fullAddressChange = new EventEmitter<string>();
  @Output() onViewUpdated = new EventEmitter<any>();

  // Map
  @ViewChild('olMap') olMap: OlMapComponent;
  points: olMapPoint[] = [];
  latitude: number = -34.604369;
  longitude: number = -58.3850703;
  zoom: number = 10;

  address_datasource: Mapbox[] = [];
  customStore: any;
  selectedKeys = [];
  displayOptions = false;
  address: string;
  

  constructor(
    private mapboxService: MapboxService,
    private translateService: TranslateService,
    public allDatasources: DataSourceService
  ) { }

  ngOnInit() {
    this.loadCustomStore();
  }

  ngAfterViewInit() {
    this.mapReady();
  }

  loadCustomStore() {
    this.customStore = new DataSource({
      useDefaultSearch: false,
      load: (loadOptions) => {
        const searchValue = loadOptions.searchValue;
        if (searchValue === null) {
          return this.address_datasource; // Assuming simpleProducts is defined in your component
        } else {
          return this.fetchStoreValues(searchValue);
        }
      }
    });
  }

  mapReady() {
    setTimeout(() => {
      if (this.olMap && this.olMap.mapReady()) {
        this.displayPointOnMap(this.location, this.locationAddress);
        return;
      }
      else {
        this.mapReady();
      }
    },
      800);
  }

  onValueChanged() {
    if (!this.address || this.address == "") {
      this.selectedKeys = [];
    }
  }

  onkeyup($event, limit?) {
    if (!$event || !$event.event || !$event.event.target) {
      return;
    }
    if (!limit) {
      limit = 6;
    }

    let filter = $event.event.target.value;
    let words = filter.split(' ');
    if (words.length < 3) {
      this.selectedKeys = [];
      this.displayOptions = false;
    }
    else {
      this.displayOptions = true;
      //Search on the datasource
      let filtered = this.fetchStoreValues(filter);

      if (!filtered || filtered.length == 0) {
        //Fetch more values
        this.fetchAPIValues(filter, limit);
      }
      else {
        this.address_datasource = filtered;
      }
    }
  }

  fetchStoreValues(searchValue: string): Mapbox[] {
    return this.address_datasource.filter((item) => {
      const searchWords = searchValue.trim().toLowerCase().normalize("NFD").replace(/[\u0300-\u036f]/g, "").split(' ');
      const itemNameWords = item.place_name.toLowerCase().normalize("NFD").replace(/[\u0300-\u036f]/g, "").split(' ');
      let flag = true;
      for (let i = 0; i < searchWords.length; i++) {
        if (!itemNameWords.includes(searchWords[i])) {
          flag = false;
        } else {
          itemNameWords.splice(itemNameWords.indexOf(searchWords[i]), 1);
        }
      }
      return flag;
    });
  }

  fetchAPIValues(filter: string, limit: number) {
    this.mapboxService.getLocationListByAddress(filter, "", limit).subscribe((res) => {
      if (res) {
        this.address_datasource = res;
      }
    });
  }

  onAddressSelected($event) {
    let mapbox: Mapbox = $event.itemData;
    this.address = mapbox.place_name;
    this.displayOptions = false;

    let locationData = this.createLocationData(mapbox.geometry, mapbox.center);
    this.onAddressChanged.emit({ location: locationData, placeName: this.address });
    this.displayPointOnMap(locationData, mapbox.place_name, mapbox.center);
  }


  createLocationData(geometry: any, center: any): LocationData {
    let ld = new LocationData(Cons._WKT, geometry, parseInt(Cons.SRIDS.S4326));
    return ld;
  }

  displayPointOnMap(locationData: LocationData, name: string, coordinates?: any) {
    if (!locationData) {
      return;
    }
    const pointHelper: olMapPoint = new olMapPoint();
    const commonEntity: ICommonEntity = { name: name, locationData, commonID: null, entityName: null, elementType: null, elementTypeID: null, modelType: null };
    const point = pointHelper.toMapFeature(commonEntity, null, null, null, 'markers/pin.png', false, 0.8);
    this.points = [point];

    if (point.coordinateArray && point.coordinateArray != null && point.coordinateArray.length > 1) {
      coordinates = point.coordinateArray;
    }

    this.olMap.moveTo(coordinates[1], coordinates[0], 18, 2500, (complete) => {
      if (complete) {
        let extentView = this.olMap.getExtent();
        let mapBoundaries = [
          { x: extentView[0], y: extentView[1] }, //coord 1
          { x: extentView[2], y: extentView[1] }, //coord 2
          { x: extentView[2], y: extentView[3] }, //coord 3
          { x: extentView[0], y: extentView[3] }, //coord 4
        ];
    
        //Repeat first coord to close the polygon
        mapBoundaries.push(mapBoundaries[0]);
    
        this.onViewUpdated.emit(mapBoundaries);
      }
    });
  }

  getUserPosition(): Promise<any> {
    return new Promise((resolve, reject) => {
      navigator.geolocation.getCurrentPosition(resp => resolve({ lng: resp.coords.longitude, lat: resp.coords.latitude }),
        err => reject(err)
      );
    });
  }

}
