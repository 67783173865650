  <div *ngIf="showDistance" fxLayout="row wrap" style="padding-bottom: 1rem; align-items: center;">
    <div fxFlex="35" class="form-field custom-disabled-text-box">
      <dx-switch (onValueChanged)="onValueChanged($event)"></dx-switch>
    </div>
    <div  fxFlex="65" class="form-field custom-disabled-text-box">
      <dx-text-box [disabled]="true" [value]="textBoxValue" labelMode="floating"
        [label]="'comp.search-panel.distance' | translate">
      </dx-text-box>
    </div>
  </div>
  <dx-scroll-view>
    <div class="stepper-container" *ngFor="let steps of multiplesSteps">
      <app-stepper [steps]="steps" [vertical]="true" [currentStep]="1" [hideCurrentStepIndicator]="true"></app-stepper>
    </div>
  </dx-scroll-view>
