<div class="infrastructure-odf-container py-4 px-5">
  <div class="header-container py-3 mt-3 mb-3">
    <div class="title-and-svg">
      <div class="svg-container" *ngIf="selectedTab === 1">
        <div class="row">
            <div class="col-12">
                <p class="svg-title">{{ 'infrastructure-odf.title.port.icon' | translate }}</p>
            </div>
        </div>
    
        <div class="row">
            <div class="svg-group">
                <p class="svg-title">Puertos ODF:</p>
                <div *ngFor="let svg of portSVGs; let i = index" class="svg-item">
                    <img [src]="svg" [ngClass]="'svg-size-' + i" alt="port icon">
                    <p class="svg-label">{{ svgLabels[i] }}</p>
                </div>
            </div>
            <div class="example-svg">
                <p class="svg-title-example" *ngIf="selectedTab === 1">{{ 'infrastructure-odf.title.port.icon.example' | translate }}</p>
                <div *ngFor="let svg of examplePortSVG; let i = index" class="svg-item-example">
                    <img [src]="svg" [ngClass]="'svg-size-example-' + i" alt="example icon">
                    <p class="svg-label-example">{{ exampleLabel[i] }}</p>
                </div>
            </div>
        </div>
    </div>


      <div class="title-container">
        <strong class="title-header">
          {{ infrastructure?.name }} {{ (infrastructure && infrastructure?.description != '' ? "(" +
          infrastructure.description + ")" : '')}}
        </strong>
        <div class="button-container my-3">
          <dx-button icon="fas fa-file-download" class="button main-background-theme" [disabled]="selectedTab !== 0"
            [text]="'general.download' | translate" (click)="downloadGraph()">
          </dx-button>
        </div>
      </div>
    </div>
  </div>



  <dx-tab-panel [(selectedIndex)]="selectedTab">
    <dxi-item [title]="'infrastructure-odf.connection-view' | translate">
      <dx-scroll-view>
        <div *ngIf="!infrastructureODFs || infrastructureODFs.length == 0">{{
          'infrastructure-odf.port-details.no-equipments' | translate }}</div>
        <app-d3-horizontal-tree *ngFor="let infrastructureData of infrastructureODFs" [data]="infrastructureData.graph"
          [fileName]="infrastructureData.fileName" [svgIcons]="svgIcons" [selectableLeafs]="true"
          (componentReady)="graphReady(infrastructureData)" (leafSelected)="onLeafSelected($event)">
        </app-d3-horizontal-tree>
      </dx-scroll-view>
    </dxi-item>

    <dxi-item [title]="'infrastructure-odf.front-view' | translate" *ngIf="infrastructure?.equipment?.length">
      <dx-scroll-view>
        <div fxLayout="column">
          <div *ngFor="let equipment of infrastructure?.equipment" style="padding: 10px 0px;">
            <app-odf-front-view [infrastructureFullName]="infrastructureFullName" [equipment]="equipment"></app-odf-front-view>

            <br />
          </div>
        </div>
      </dx-scroll-view>
    </dxi-item>

    <dxi-item [title]="'infrastructure-odf.comment-section' | translate">
      <dx-scroll-view>
        <div fxLayout="column">
          <app-odf-comment-section></app-odf-comment-section>
        </div>
      </dx-scroll-view>
    </dxi-item>

  </dx-tab-panel>
</div>

<dx-load-panel shadingColor="rgba(0,0,0,0.4)" [(visible)]="loading" [showIndicator]="true" [showPane]="true"
  [shading]="true" [closeOnOutsideClick]="false">
</dx-load-panel>

<dx-popup *ngIf="displayTraceabilityDiagramModal" [(visible)]="displayTraceabilityDiagramModal"
  [closeOnOutsideClick]="true" [title]="traceabilityDiagramData?.serviceName" [showTitle]="true" [height]="750"
  [width]="500">
  <div *dxTemplate="let data of 'content'">
    <app-traceability-diagram [traceabilityDiagram]="traceabilityDiagramData"></app-traceability-diagram>
  </div>
</dx-popup>