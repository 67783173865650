<div fxLayout="column" fxLayoutAlign="space-between stretch" class="h-100">
  <div fxLayout="row nowrap" fxLayoutAlign="space-between stretch">
    <div class="me-2" fxLayout="column nowrap" fxFlex="50">

      <dx-text-box class="custom-input" height="45px" labelMode="floating" [label]="'devices.add-edit.name' | translate"
        [(value)]="selectedInfrastructure.name">
      </dx-text-box>

      <dx-select-box *ngIf="elementTypeDataSource" class="custom-input" height="45px" labelMode="floating"
        [label]="'devices.add-edit.model' | translate" displayExpr="name" valueExpr="infrastructureID"
        [dataSource]="elementTypeDataSource" [deferRendering]="false"
        [placeholder]="selectedInfrastructure.elementTypeID.toString()" [searchEnabled]="true" [searchExpr]="'name'"
        [searchMode]="'contains'" [searchTimeout]="200"
        (onSelectionChanged)="infrastructureChange($event)"></dx-select-box>

      <dx-text-box class="custom-input" height="45px" labelMode="floating"
        [label]="'devices.add-edit.description' | translate" [(value)]="selectedInfrastructure.description"
        (onChange)="infrastructureChange($event)">
      </dx-text-box>
    </div>

    <div fxLayout="column nowrap" fxFlex="50">
      <address-form [location]="selectedInfrastructure.locationData" [required]="true"
        (onAddressChanged)="fullAddressChange($event)">
      </address-form>
    </div>
  </div>

  <div class="me-2 mt-4">
    <dx-tab-panel>
      <dxi-item [title]="'devices.add-edit.equipment' | translate" *ngIf="selectedInfrastructure.equipment?.length;">
        <dx-scroll-view>
          <div fxLayout="column" class="data-container">
            <dx-data-grid [dataSource]="selectedInfrastructure.equipment" [showBorders]="true" [showRowLines]="true"
              (onRowExpanding)="handleSeletedODF($event)" (onRowUpdating)="odfNameChange($event)">

              <dxo-editing mode="cell" [allowUpdating]="true">
              </dxo-editing>

              <dxi-column [caption]="'devices.equipment.equipmentType.name' | translate"
                dataField="equipmentType.name" [allowEditing]="false"></dxi-column>
              <dxi-column [caption]="'devices.equipment.name' | translate" dataField="name"
                [allowEditing]="true"></dxi-column>
              <dxi-column [caption]="'devices.equipment.description' | translate" dataField="description" [allowEditing]="false"></dxi-column>

              <dxo-master-detail [enabled]="true" template="detail"></dxo-master-detail>
              <div *dxTemplate="let equipment of 'detail'; let index=index">
                <!-- <div class="master-detail-caption">{{ equipment2.equipmentPort }}</div> -->

                <dx-data-grid [dataSource]="getEquipmentPorts(equipment)" [showBorders]="true" [showRowLines]="true">
                  <!-- {{ equipment2.ports | json }}
                  <div *ngIf="equipment2">
                    <span>stringValue is empty.</span>
                  </div> -->
                  <dxi-column dataField="name" [caption]="'Name' | translate"></dxi-column>
                  <dxi-column dataField="fiber" [caption]="'Fiber' | translate"></dxi-column>
                  <dxi-column dataField="service" [caption]="'Port Service' | translate"></dxi-column>
                </dx-data-grid>
              </div>
            </dx-data-grid>
          </div>
        </dx-scroll-view>
      </dxi-item>
    </dx-tab-panel>
  </div>



  <div fxLayout="row" fxLayoutAlign="end center" class="me-2 mt-4">
    <dx-button [text]="'general.cancel' | translate" (click)="cancel.emit()">
    </dx-button>
    <dx-button [disabled]="disableSave" class="ms-3 main-background-theme" [text]="'general.save' | translate"
      (click)="saveInfrastructure()">
    </dx-button>
  </div>
</div>