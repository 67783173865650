import { Component, Input, OnChanges, SimpleChanges, ViewChildren, QueryList, HostListener } from '@angular/core';
import { Equipment } from '@app/@shared/model/equipment.model';
import { EquipmentPort } from '@app/@shared/model/equipmentPort.model';
import { TranslateService } from '@ngx-translate/core';
import { QRCodeComponent } from '@app/general/qr-code/qr-code/qr-code.component';
import { first } from 'rxjs';




@Component({
  selector: 'app-odf-front-view',
  templateUrl: './odf-front-view.component.html',
  styleUrls: ['./odf-front-view.component.scss'],
})

export class OdfFrontViewComponent implements OnChanges {
  _equipment: Equipment;
  equipmentID: number;
  @Input("equipment")
  get equipment() {
    return this._equipment;
  }
  set equipment(value) {
    this._equipment = value;
  }
  @Input() showTitle: boolean = true;
  @Input() infrastructureFullName: string;

  @ViewChildren('qrcodeComponent') qrCodeComponents!: QueryList<QRCodeComponent>; // Referencia a los componentes hijos



  rows: number;
  maxBackColumns: number;
  maxFrontColumns: number;
  rowsArray: number[] = [];
  backColumnsArray: number[] = [];
  frontColumnsArray: number[] = [];

  backPorts: EquipmentPort[] = [];
  frontPorts: EquipmentPort[] = [];
  backPortsArray: EquipmentPort[][] = [];
  frontPortsArray: EquipmentPort[][] = [];
  valuesBetweenBrackets: string[] = [];

  firstPorts1: string[] = [];
  lastPorts1: string[] = [];

  width: number = 50;

  modalTitle: string = '';
  selectedPort: EquipmentPort;
  selectedTab: number = 0;
  selectedEquipment: Equipment;


  qrIcon: string = '../../../../../../../assets/icons/qr.svg';
  dynamicQrIcon: string = '../../../../../../../assets/icons/dynamic-qr.svg';
  cameraIcon: string = '../../../../../../../assets/icons/camera.svg';

  target = '__';
  popupVisible = false;
  showPopup = false;
  cameraPopupVisible = false;
  dynamicQRVisible = false;

  isDynamicQR = false;


  qrDataArray = [];
  fiberNamePort: string[] = [];


  nombreEquipoConcatenado: string = '';
  rackNumber: number = 0;

  windowWidth = window.innerWidth;
  windowHeight = window.innerHeight;



  portSVGs = [
    "../../../../../../../assets/icons/blue-square.svg",
    "../../../../../../../assets/icons/blue-square-grey.svg",
    "../../../../../../../assets/icons/blue-square-yellow.svg",

    "../../../../../../../assets/icons/green-square.svg",
    "../../../../../../../assets/icons/green-square-grey.svg",
    "../../../../../../../assets/icons/green-square-yellow.svg",

    "../../../../../../../assets/icons/blue-square-with-circle.svg",
    "../../../../../../../assets/icons/blue-square-with-circle-grey.svg",
    "../../../../../../../assets/icons/blue-square-with-circle-yellow.svg",

    "../../../../../../../assets/icons/green-square-with-triangle.svg",
    "../../../../../../../assets/icons/green-square-with-triangle-grey.svg",
    "../../../../../../../assets/icons/green-square-with-triangle-yellow.svg",


    "../../../../../../../assets/icons/buffer.svg",
    "../../../../../../../assets/icons/buffer_conn.svg",
    "../../../../../../../assets/icons/buffer-yellow.svg",

    "../../../../../../../assets/icons/circle-with-cross.svg",
    "../../../../../../../assets/icons/circle-with-cross-grey.svg",
    "../../../../../../../assets/icons/circle-with-cross-yellow.svg",

    "../../../../../../../assets/icons/empty-circle.svg",
    "../../../../../../../assets/icons/empty-circle-grey.svg",
    "../../../../../../../assets/icons/empty-circle-yellow.svg",

    "../../../../../../../assets/icons/green-square-half.svg",
    "../../../../../../../assets/icons/green-square-half-grey.svg",
    "../../../../../../../assets/icons/green-square-half-yellow.svg",

    "../../../../../../../assets/icons/red-square-half.svg",
    "../../../../../../../assets/icons/red-square-half-grey.svg",
    "../../../../../../../assets/icons/red-square-half-yellow.svg",

    "../../../../../../../assets/icons/ethernet.svg",
    "../../../../../../../assets/icons/ethernet-grey.svg",
    "../../../../../../../assets/icons/ethernet-yellow.svg",


  ];

  constructor(
    private translateService: TranslateService,

  ) { }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes['equipment']) {
      this.rows = this.equipment.equipmentType.specAttributes?.slotCount;
      if (!this.rows) {
        this.rows = 1; //Default
      }
      this.maxBackColumns = Math.ceil(this.equipment.equipmentPort?.filter(ep => ep.name.startsWith("B")).length / this.rows);
      this.maxFrontColumns = Math.ceil(this.equipment.equipmentPort?.filter(ep => ep.name.startsWith("F")).length / this.rows);
      this.maxBackColumns = Math.ceil(this.equipment.equipmentPort?.filter(ep => ep.name.startsWith("B")).length / this.rows);
      this.maxFrontColumns = Math.ceil(this.equipment.equipmentPort?.filter(ep => ep.name.startsWith("F")).length / this.rows);
      this.backPortsArray = [];
      this.frontPortsArray = [];

      this.resizeImage();

      this.rowsArray = Array(this.rows).fill(1).map((_, i) => i + 1);
      this.backPorts = this.equipment.equipmentPort?.filter(ep => ep.name.startsWith("B"));
      this.frontPorts = this.equipment.equipmentPort?.filter(ep => ep.name.startsWith("F"));

      const missingPortNumbersExist = this.equipment.equipmentPort.some(x => !x.equipmentPortAttributes.portNumber);

      if (missingPortNumbersExist) {
        this.backPorts = this.backPorts.sort((a, b) => { if (a.name < b.name) { return -1; } else return 1; });
        this.frontPorts = this.frontPorts.sort((a, b) => { if (a.name < b.name) { return -1; } else return 1; });
        this.backPorts = this.backPorts.sort((a, b) => { if (a.name < b.name) { return -1; } else return 1; });
        this.frontPorts = this.frontPorts.sort((a, b) => { if (a.name < b.name) { return -1; } else return 1; });
      } else {
        this.backPorts = this.backPorts.sort((a, b) => a.equipmentPortAttributes.portNumber > b.equipmentPortAttributes.portNumber ? 1 : -1);
        this.frontPorts = this.frontPorts.sort((a, b) => a.equipmentPortAttributes.portNumber > b.equipmentPortAttributes.portNumber ? 1 : -1);

      }



      this.assignPortNumberDinamically(missingPortNumbersExist);
      this.getEquimentTypeNameSplited();

    }




  }

  ngOnInit() {

    this.extractEquipmentDetails();
    this.collectBackPortsByEquipmentID();
    this.concatenateFiberNamesWithPorts();
  }



  assignPortNumberDinamically(assignPortNumber: boolean) {
    let index = 0;

    for (let r = 0; r < this.rowsArray.length; r++) {
      let maxValueColumn = this.maxBackColumns;
      for (let bp = 0; bp < this.backPorts.length; bp++) {

        if (assignPortNumber) {
          this.backPorts[bp].equipmentPortAttributes.portNumber = index;
        }

        if (!this.backPortsArray[this.rowsArray[r]]) {
          this.backPortsArray[this.rowsArray[r]] = [];
        }
        this.backPortsArray[this.rowsArray[r]][bp] = this.backPorts[index];

        index++;
        maxValueColumn--;

        if (maxValueColumn == 0) { bp = this.backPorts.length; }
      }
    }

    index = 0;

    for (let r = 0; r < this.rowsArray.length; r++) {
      let maxValueColumn = this.maxFrontColumns;
      for (let fp = 0; fp < this.frontPorts.length; fp++) {

        if (assignPortNumber) {
          this.frontPorts[fp].equipmentPortAttributes.portNumber = index;
        }

        if (!this.frontPortsArray[this.rowsArray[r]]) {
          this.frontPortsArray[this.rowsArray[r]] = [];
        }
        this.frontPortsArray[this.rowsArray[r]][fp] = this.frontPorts[index];

        index++;
        maxValueColumn--;

        if (maxValueColumn == 0) { fp = this.frontPorts.length; }
      }
    }
  }
  /*
    selectPort(row: number, column: number, type : string, popup : boolean) {
      const portsPerRow = this.equipment.equipmentPort?.filter(ep=> ep.name.startsWith(type)).length / this.rows;
      const portIndex: number = (row - 1) * portsPerRow + column - 1;
      this.selectedPort = this.equipment?.equipmentPort?.filter(ep=> ep.name.startsWith(type))[portIndex];
      
      if (!this.selectedPort) {
        return;
      }
  
      this.popupVisible = popup;
      
      this.target = "#e" + this.equipment.equipmentID + '_' + row + '_' + column
      this.modalTitle = `${this.translateService.instant('infrastructure-odf.port-details.title')}: ${this.selectedPort.name}`;
    }
    */
  selectPort(port: EquipmentPort, type: string, popup: boolean) {

    this.selectedPort = port;
    if (!this.selectedPort) {
      return;
    }

    this.popupVisible = popup;

    this.target = "#e" + this.equipment.equipmentID + '_' + port.equipmentPortID
    this.modalTitle = `${this.translateService.instant('infrastructure-odf.port-details.title')}: ${this.selectedPort.name}`;


  }

  resizeImage() {
    if (this.maxBackColumns < 13) {
      this.width = 50;
    } else if (this.maxBackColumns > 12 && this.maxBackColumns < 25) {
      this.width = 75;
    } else {
      this.width = 100;
    }
  }

  onVisibleChange(event: boolean) {
    if (!event) {
      this.popupVisible = false;
    }
  }

  log(p) {
    console.log(p);
  }

  getPortSVG(port: EquipmentPort): string {
    const typeName = this.equipment.equipmentType?.name;
    const isShelf = this.equipment.name;
    const basePath = "../../../../../../../assets/icons/";

    if (typeName && (typeName.includes('SC SINGLE') || typeName.includes('SC-PC'))) {
      if (port.hasFiber) {

        if (!this.hasService(port)) {

          return `${basePath}blue-square-grey.svg`;
        } else {

          return `${basePath}blue-square-yellow.svg`;
        }

      } else if (port.hasCrossConnection) {

        return `${basePath}blue-square-yellow.svg`;


      } else {

        return `${basePath}blue-square.svg`;

      }

    } else if (typeName && (typeName.includes('SC-APC') || typeName.includes('SC APC'))) {
      if (port.hasFiber) {
        if (!this.hasService(port)) {

          return `${basePath}green-square-grey.svg`;
        } else {

          return `${basePath}green-square-yellow.svg`;
        }
      } else if (port.hasCrossConnection) {
        return `${basePath}green-square-yellow.svg`;
      } else {
        return `${basePath}green-square.svg`;
      }
    }



    else if (typeName && typeName.includes('LC-PC')) {
      if (port.hasFiber) {
        if (!this.hasService(port)) {
          return `${basePath}blue-square-with-circle-grey.svg`;
        } else {
          return `${basePath}blue-square-with-circle-yellow.svg`;
        }
      } else if (port.hasCrossConnection) {
        return `${basePath}blue-square-with-circle-yellow.svg`;
      } else {
        return `${basePath}blue-square-with-circle.svg`;
      }
    }


    else if (typeName && typeName.includes('LC-APC')) {
      if (port.hasFiber) {
        if (!this.hasService(port)) {
          return `${basePath}green-square-with-triangle-grey.svg`;
        } else {
          return `${basePath}green-square-with-triangle-yellow.svg`;
        }
      } else if (port.hasCrossConnection) {
        return `${basePath}green-square-with-triangle-yellow.svg`;
      } else {
        return `${basePath}green-square-with-triangle.svg`;
      }
    }


    else if (typeName && (typeName.includes('FC-PC') || typeName.includes('FC SINGLE'))) {
      if (port.hasFiber) {
        if (!this.hasService(port)) {
          return `${basePath}buffer_conn.svg`;
        } else {
          return `${basePath}buffer-yellow.svg`;
        }
      } else if (port.hasCrossConnection) {
        return `${basePath}buffer-yellow.svg`;
      } else {
        return `${basePath}buffer.svg`;
      }
    }

    else if (typeName && typeName.includes('FC-APC')) {
      if (port.hasFiber) {
        if (!this.hasService(port)) {
          return `${basePath}circle-with-cross-grey.svg`;
        } else {
          return `${basePath}circle-with-cross-yellow.svg`;
        }
      } else if (port.hasCrossConnection) {
        return `${basePath}circle-with-cross-yellow.svg`;
      } else {
        return `${basePath}circle-with-cross.svg`;
      }

    }

    else if (typeName && typeName.includes('ST')) {
      if (port.hasFiber) {
        if (!this.hasService(port)) {
          return `${basePath}empty-circle-grey.svg`;
        } else {
          return `${basePath}empty-circle-yellow.svg`;
        }
      } else if (port.hasCrossConnection) {
        return `${basePath}empty-circle-yellow.svg`;
      } else {
        return `${basePath}empty-circle.svg`;
      }
    }


    else if (typeName && (typeName.includes('Euro 2000') || typeName.includes('E2000'))) {
      if (port.hasFiber) {
        if (!this.hasService(port)) {
          return `${basePath}green-square-half-grey.svg`;
        } else {
          return `${basePath}green-square-half-yellow.svg`;
        }
      } else if (port.hasCrossConnection) {
        return `${basePath}green-square-half-yellow.svg`;
      } else {
        return `${basePath}green-square-half.svg`;
      }
    }

    else if (typeName && typeName.includes('EURO LX.5')) {
      if (port.hasFiber) {
        if (!this.hasService(port)) {
          return `${basePath}red-square-half-grey.svg`;
        } else {
          return `${basePath}red-square-half-yellow.svg`;
        }
      } else if (port.hasCrossConnection) {
        return `${basePath}red-square-half-yellow.svg`;
      } else {
        return `${basePath}red-square-half.svg`;
      }
    }

    else if (isShelf && isShelf.includes('SHELF')) {
      if (port.hasFiber) {
        if (!this.hasService(port)) {
          return `${basePath}ethernet-grey.svg`;
        } else {
          return `${basePath}ethernet-yellow.svg`;
        }
      } else if (port.hasCrossConnection) {
        return `${basePath}ethernet-yellow.svg`;
      } else {
        return `${basePath}ethernet.svg`;
      }
    }

    if (port.hasFiber) {
      return !this.hasService(port) ? `${basePath}ethernet-grey.svg` : `${basePath}ethernet-yellow.svg`;
    } else if (port.hasCrossConnection) {
      return `${basePath}ethernet-yellow.svg`;
    } else {
      return `${basePath}ethernet.svg`;
    }

  }

  hasService(port: EquipmentPort): boolean {
    return port.fiber?.serviceTrace?.some(trace => {
      const serviceName = trace.service?.name || '';
      return /\-/.test(serviceName);
    }) ?? false;
  }


  getEquimentTypeNameSplited() {

    let name = this.equipment.equipmentType?.name;
  }




  openPopup(type: string) {
    if (type === 'qr') {
      this.isDynamicQR = false;
      this.showPopup = true;
    } else if (type === 'camera') {
      this.isDynamicQR = false;
      this.cameraPopupVisible = true;
    } else if (type === 'dynamicQR') {
      this.isDynamicQR = true;
      this.dynamicQRVisible = true;
    }
  }

  onPopupVisibleChange(event: any) {
    this.showPopup = event;
  }

  onDynamicQRvisibleChange(event: any) {
    this.dynamicQRVisible = event;
  }

  onCameraPopupVisibleChange(visible: boolean) {
    this.cameraPopupVisible = visible;
  }




  extractEquipmentDetails() {


    let nombreEquipo = this.equipment?.name;
    let nombreTipoDeEquipo = this._equipment?.equipmentType?.name;
    this.nombreEquipoConcatenado = `${nombreEquipo} ${nombreTipoDeEquipo}`;


    this.valuesBetweenBrackets = [];


    if (this.equipment?.equipmentPort && this.equipment.equipmentPort.length > 0) {

      let uniqueFiberNames = Array.from(new Set(this.equipment.equipmentPort.map(port => {
        let fiberName = port.fiber.name;

        let match = fiberName.match(/\[(.*?)\]/);
        if (match) {
          let contentInBrackets = match[1].trim();
          this.valuesBetweenBrackets.push(contentInBrackets);
        } else {
          this.valuesBetweenBrackets.push('');
        }

        return fiberName.replace(/\s*\[.*?\]\s*/, '');
      })));


      this.fiberNamePort = uniqueFiberNames.length > 0 ? [uniqueFiberNames[0]] : [];
    } else {
      console.error('No se encontraron datos válidos en equipmentPort.');
      this.fiberNamePort = [];
    }



    this._equipment.equipmentPort?.filter(port => port.name.startsWith('B')).forEach(port => {


      if (port.fiber.serviceTrace?.length > 0) {
        const services = port.fiber.serviceTrace.map((trace, index) => trace.service.name);

      }
    });
  }




  collectBackPortsByEquipmentID() {
    if (!this.equipment || !this.equipment.equipmentPort) {
      console.error('No hay datos válidos en equipmentPort para mostrar.');
      return;
    }

    this.equipmentID = this.equipment.equipmentID;

    const backPortsByEquipmentID: { [equipmentID: string]: string[] } = {};
    let firstPort: string | undefined;
    let lastPort: string | undefined;

    this.equipment.equipmentPort.forEach(port => {
      if (port.name?.startsWith('B')) {
        const equipmentID = port.equipmentID.toString();

        if (!backPortsByEquipmentID[equipmentID]) {
          backPortsByEquipmentID[equipmentID] = [];
        }

        backPortsByEquipmentID[equipmentID].push(port.name);


      }
    });


  }


  concatenateFiberNamesWithPorts() {
    const firstFiberName = this.fiberNamePort[0];
    let globalIndex = 0;


    const { firstPorts, lastPorts } = this.getFirstAndLastPorts();

    this.qrDataArray = this.backPortsArray.map((ports, index) => {
      const fiberAndEquipmentInfo = {
        fiberName: firstFiberName,
        equipmentName: this.nombreEquipoConcatenado
      };

      const portsTextArray = ports.map((port, portIndex) => {
        const portName = port.name;
        const matches = [...portName.matchAll(/\[(.*?)\]/g)];
        const contentsInBrackets = matches.map(match => match[1]);

        const valueInBrackets = this.valuesBetweenBrackets[globalIndex] || "";
        globalIndex++;

        let serviceText = "";
        if (port.fiber.serviceTrace?.length > 0) {
          const services = port.fiber.serviceTrace.map((trace) => trace.service.name);
          serviceText = services.length > 0 ? ` [${services.join(" ")}]` : "";
        } else {
          serviceText = " [VACIO]";
        }

        return `${portName} [${valueInBrackets}]${serviceText}`;
      });

      const combinedPortsText = portsTextArray;

      // Creates an object with the necessary data to generate the QR code
      return {
        name: this.nombreEquipoConcatenado,
        fiberName: firstFiberName,
        ports: combinedPortsText,
      };
    });

    this.firstPorts1 = firstPorts;
    this.lastPorts1 = lastPorts;


  }


  getFirstAndLastPorts() {
    let firstPorts: string[] = [];
    let lastPorts: string[] = [];

    this.backPortsArray.forEach((ports, index) => {
      const firstPort = ports[0]?.name;
      const lastPort = ports[ports.length - 1]?.name;

      if (firstPort) {
        firstPorts.push(firstPort);
      }
      if (lastPort) {
        lastPorts.push(lastPort);
      }
    });

    return { firstPorts, lastPorts };
  }


  //Download all the QRs of the child component at once

  downloadAllQRCodes(): void {
    this.qrCodeComponents.forEach(qrCodeComponent => {
      qrCodeComponent.downloadQRCode();
    });
  }

  @HostListener('window:resize', ['$event'])
  onResize(event: Event) {
    this.windowWidth = (event.target as Window).innerWidth;
    this.windowHeight = (event.target as Window).innerHeight;
  }

}