<div fxLayout="row" fxLayoutWrap fxLayoutAlign="start start" class="card-container">
  <!-- <overlapping-rings fxFlex="30" class="card-separation" [cardItems]="_cardItemsService.getCards()"></overlapping-rings> -->
  <ng-container *ngFor="let cardItem of _cardItems">
    <overlapping-rings class="card-separation" [cardItem]="cardItem"></overlapping-rings>
  </ng-container>
  <device-occupation *ngIf="platformID != 7 && platformID != 1" fxFlex="35" class="card-separation"></device-occupation>
</div>

<dx-load-panel shadingColor="rgba(0,0,0,0.4)" [visible]="loading" [showIndicator]="true" [showPane]="true"
  [shading]="true" [closeOnOutsideClick]="false">
</dx-load-panel>