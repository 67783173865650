import {
  Component,
  Input,
  Output,
  OnChanges,
  SimpleChanges,
  EventEmitter,
} from '@angular/core';
import { AccordionDataSource } from '@app/@shared/model/aux-models/accordion-data-source';
import { Splitter } from '@app/@shared/model/splitter.model';
import { SplitterConnection } from '@app/@shared/model/splitterConnection.model';
import { ExcelJSHelperService } from '@app/@shared/services/aux-services/excelJS-helper.service';
import { TranslateService } from '@ngx-translate/core';
import { exportDataGrid } from 'devextreme/excel_exporter';
import { Workbook, Worksheet } from 'exceljs';
import * as saveAs from 'file-saver';

@Component({
  selector: 'splitter-detail',
  templateUrl: './splitter-detail.component.html',
  styleUrls: ['./splitter-detail.component.scss'],
})
export class SplitterDetailComponent implements OnChanges {
  @Input() splitter: Splitter;
  @Input() closable: boolean;

  @Output() close = new EventEmitter<any>();

  ds_splitter_review: AccordionDataSource<Splitter>[] = [];

  splitterConnectionsDataSource: AccordionDataSource<SplitterConnection[]>[] = [];

  constructor(
    private excelJSHelperService: ExcelJSHelperService,
    private trans: TranslateService
  ) {}

  ngOnChanges(changes: SimpleChanges): void {
    if (changes?.['splitter']) {
      const title =
        String.prototype.upperFirstLetter(this.splitter.entityName) +
        ' ' +
        this.trans.instant('general.review');

      this.ds_splitter_review = [{ title, data: this.splitter }];
      this.loadSplitterConnections();
    }
  }

  loadSplitterConnections = () => {
    const title = `${String.prototype.upperFirstLetter(
      this.splitter.entityName
    )} ${this.trans.instant('comp.search-panel.connections')}`;

    this.splitterConnectionsDataSource = [
      {
        title,
        data: this.splitter.splitterConnection,
      },
    ];
  };

  closeDetail() {
    this.close.emit();
  }

  onExporting(e) {
    const connectionsLabel = this.trans.instant(
      'comp.search-panel.connections'
    );
    const gridName = `${this.splitter.name}_${connectionsLabel}`;
    const workbook = new Workbook();
    const worksheet = workbook.addWorksheet(gridName);

    exportDataGrid({
      component: e.component,
      worksheet,
      topLeftCell: { row: 2, column: 1 },
    }).then(() => {
      this.initializeExcelHeaders(worksheet);
      this.writeExcelData(worksheet);
      this.excelJSHelperService.adjustColumnsWidth(worksheet);

      workbook.xlsx.writeBuffer().then((buffer) => {
        saveAs(
          new Blob([buffer], { type: 'application/octet-stream' }),
          `${gridName}.xlsx`
        );
      });
    });
    e.cancel = true;
  }

  initializeExcelHeaders = (worksheet: Worksheet) => {
    const nameLabel = this.trans.instant('general.name')?.toUpperCase();
    const bufferLabel = this.trans
      .instant('comp.search-panel.excel-header.buffer')
      ?.toUpperCase();
    const colorLabel = this.trans
      .instant('comp.search-panel.excel-header.color')
      ?.toUpperCase();
    const fiberOrderLabel = this.trans
      .instant('comp.search-panel.excel-header.fiber-order')
      ?.toUpperCase();
    const serviceLabel = this.trans
      .instant('comp.search-panel.excel-header.service')
      ?.toUpperCase();

    const headers = [
      nameLabel,
      bufferLabel,
      colorLabel,
      fiberOrderLabel,
      serviceLabel,
    ];

    this.excelJSHelperService.writeHeaders(worksheet, headers, 1);
  };

  writeExcelData = (worksheet: Worksheet) => {
    const connections: SplitterConnection[] = this.splitterConnectionsDataSource[0].data;

    const excelData: string[][] = connections.map((x) => {
      const formatName = (name: string) =>
        name.includes(' [') ? name.split(' [')[0] : name;

      const name = formatName(x.fiber.name);
      const buffer = x.fiber?.fiberAttributes.buffer;
      const color = x.fiber?.fiberAttributes.color;
      const fiberOrder = x.fiber?.fiberOrder;
      const service = x.fiber?.serviceName;

      return [name, buffer, color, fiberOrder, service];
    });

    this.excelJSHelperService.writeData(worksheet, 2, excelData);
  };

  log(d){
    console.log(d);
  }
}
