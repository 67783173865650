import { Injectable } from '@angular/core';
import { CardItem } from '@app/general/home/overlapping-rings/card-item';
import { UserFile } from '../model/userFile.model';

@Injectable({
  providedIn: 'root'
})
export class CardItemsService {
  cardItems : CardItem[]

  constructor() {
    this.cardItems = [];
  }

  addCard(card: CardItem) : void
  {
    this.cardItems.push(card);
  }

  getCards() {
    return this.cardItems;
  }
}
