<div style="height: 100vh;">
  <div class="login-box">
    <div fxLayout="row" fxLayoutAlign="center center" style="height: 100%" >
      <div fxflex="60" style="text-align:center">
        <img src="../../assets/main/Frameworld.png" style="width: 80%; max-width: 800px;"/>
        <div class="title">Smart Fiber Network</div>
      </div>  
      <div fxflex="40" class="right-panel"  > 
        <div class="floating-card"></div>
        <div class="floating-shadow"></div>
        <div>
          <h4 class="welcome-msg"> {{'general.welcome' | translate}}!</h4>
          <div fxLayout="row wrap"  class="row-clearance">
            <div fxFlex="100" fxLayoutAlign="center center" class="form-field">
              <dx-text-box
                height="45px"
                width="300px"
                [(value)]="model.user"
                mode="email"
                labelMode="floating"
                [label]="'general.username' | translate"
              >
              </dx-text-box>
          </div>
          </div>
          <br/>
          <div fxLayout="row wrap" class="row-clearance">
            <div fxFlex="100" fxLayoutAlign="center center" class="form-field">
              <dx-text-box
                height="45px"
                width="300px"
                [(value)]="model.password"
                mode="password"
                labelMode="floating"
                [label]="'general.password' | translate"
              >
              </dx-text-box>
            </div>
          </div>
  
          <br />
  
          <div class="alert alert-danger" [hidden]="!error || isLoading" style= "margin-bottom: -1rem ">
            {{ error }}
          </div>
  
          <div fxLayout="row" fxLayoutAlign="center center">
            <div class="spinner-border" style="color:white" role="status" *ngIf="isLoading"></div>
          </div>
          <br />
          <br />
          <div fxLayout="column" fxLayoutAlign="center center">
            <div fxLayout="row"  fxLayoutAlign="space-around center">
              <dx-button style="z-index: 10;"
                class="button login-button main-background-theme"
                [text]="'forms.login.login' | translate"
                width="150"
                height="35"
                (click)="login()"
                [disabled]="isLoading || (model.user  == '' && model.password == '')"
              >
              </dx-button>
              <dx-button
                *ngIf="isLocal"
                class="button quick-access-button"
                [text]="'general.quick-access' | translate"
                width="150"
                height="35"
                (click)="quickAccess()"
                [disabled]="isLoading"
              >
              </dx-button>
            </div>
            <div class="account">
              <span>{{'forms.login.no-account' | translate}}<a style="color: #FFFFFF;" href="">{{'forms.login.register-here'| translate}}</a></span>
              
            </div>
          </div>
        </div>

      </div>
    </div>
  </div>
</div>
<div class="footer">
  <div>2024</div>
</div>

