import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class PolygonDrawService {
  polygonDraw : number;

  constructor() {
    this.polygonDraw = 0;
  }

  setMapSource(newValue : number): void {
    this.polygonDraw = newValue;
  }
}
