<app-simple-crud
[gridTitle]="'infrastructure.title' | translate"
[modalTitle]="modalTitle"
[addButtonTitle]="'infrastructure.add-device' | translate"
[deleteButtonTooltip]="'infrastructure.delete-device' | translate"
[deleteWarningMessage]="'infrastructure.delete-device-warning' | translate"
[selectedItem]="selectedInfrastructure"
[loadingModal]="loadingModal"
[(showDeletePopup)]="popups.confirmDelete"
[addButtonNewItem]="false"
[showCreateOrEditPopup]="popups.createOrEdit"
(showCreateOrEditPopupChange)="closeModal()"
(addNewItem)="openModalAddInfrastructure()"
(deleteItem)="deleteInfrastructure($event)"
(onSearchPerformed)="search($event)"
[entityTypeModels]="entityTypeModels"
>
    <app-infrastructure-list grid
    [loading]="loading"
    [infrastructure]="infrastructureList"
    (editItem)="openModalEditInfrastructure($event)"
    (deleteItem)="openPromptDeleteInfrastructure($event)"
    [filter] = "filter"
>
    </app-infrastructure-list>
    <div create-edit-modal>
        <app-create-edit-infrastructure-modal *ngIf="selectedInfrastructure && infrastructureList"
          [infrastructureList]="infrastructureList"
          [selectedInfrastructure]="selectedInfrastructure"
          (save)="saveInfrastructure($event)"
          (cancel)="closeModal()"
        ></app-create-edit-infrastructure-modal>
    </div>
</app-simple-crud>