
import { Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges } from '@angular/core';
import { ElementType } from '@app/@shared/model/elementType.model';
import { ElementTypeService } from '../../services/element-type.service';
import { PlatformService } from '@app/auth/platform.service';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'common-filter',
  templateUrl: './common-filter.component.html',
  styleUrls: ['./common-filter.component.scss'],
})

export class CommonFilterComponent implements OnChanges{
  
  @Input() showEmail = false;
  @Input() showDescription = true;
  @Input() showElementType = true;
  @Input() entityTypeModels: any;
  @Input() entityType = '';
  @Output() onSearchPerformed = new EventEmitter<any>();
  @Output() onElementTypeSelected = new EventEmitter<any>();


constructor(private trans : TranslateService) {
  }
  
  ngOnChanges(changes: SimpleChanges): void {
    this.trans.get('general.all').subscribe((translatedTodos: string) => {
      this.entityTypeModels = [{ name: translatedTodos, elementTypeID: undefined }, ...this.entityTypeModels];
      if (!this.entity.elementTypeID && this.entityTypeModels.length > 0) {
        this.entity.elementTypeID = this.entityTypeModels[0].elementTypeID;
      }
    });
  }

 
  entity : any = { name: '', email: '', description: '', elementTypeID: 0 };

  search(){
    this.onSearchPerformed.emit(this.entity);

  }

  handleElementTypeSelection(event: any) {
    this.entity.elementTypeID = event.value;
  }
}