import { Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { OlMapComponent } from '@app/@shared/components/ol-map/ol-map.component';

import { PositionStack } from '@app/@shared/model/aux-models/positionStack';
import { IBreakpointMediaQuery } from '@app/@shared/model/interface/iBreakpoint-media-query';
import { Plot } from '@app/@shared/model/plot.model';
import { OlMapHelperService } from '@app/@shared/services/aux-services/ol-map-helper.service';
import { PositionStackService } from '@app/@shared/services/aux-services/position-stack.service';
import { PlotService } from '@app/@shared/services/map-elements-services/plot.service';
import { MediaQueryService } from '@app/@shared/services/media-query.service';
import { firstValueFrom, Subscription } from 'rxjs';
import { olMapPolygon } from '@app/@shared/model/aux-models/ol-map-models/olMapPolygon';
import { olMapPoint } from '@app/@shared/model/aux-models/ol-map-models/olMapPoint';
import { Cons } from '@app/@shared/cons/cons';
import { ICommonEntity } from '@app/@shared/model/interface/iCommonEntity';
import { LocationData } from '@app/@shared/model/aux-models/locationData';
import { PlatformPlot } from '@app/@shared/model/platform-plot.model';
import { PlatformService } from '@app/auth/platform.service';

interface DrawerLayout {
  position: 'left' | 'bottom';
  open: 'shrink' | 'overlap' | 'push';
}

@Component({
  selector: 'app-home-survey',
  templateUrl: './home-survey.component.html',
  styleUrls: ['./home-survey.component.scss'],
})
export class HomeSurveyComponent implements OnInit, OnDestroy {
  // Drawer
  drawerDefaultLayout: DrawerLayout = {
    position: 'left',
    open: 'shrink',
  };

  drawerMobileLayout: DrawerLayout = {
    position: 'bottom',
    open: 'shrink',
  };

  drawerLayout: DrawerLayout = this.drawerDefaultLayout;

  // Map
  latitude: number = -34.604369;
  longitude: number = -58.3850703;
  zoom: number = 16;
  polygons: olMapPolygon[] = [];
  mapBoundaries: { x: number; y: number }[] = [];
  points: olMapPoint[] = [];

  subscriptions: Subscription = new Subscription();
  currentLayout: IBreakpointMediaQuery;
  indexOfFirstPlotSelected: number;

  selectedPlot: olMapPolygon;

  constructor(
    private olMapHelperService: OlMapHelperService,
    private mediaQueryService: MediaQueryService,
    private positionStackService: PositionStackService,
    private plotService: PlotService,
    private platformService: PlatformService
  ) {}

  // Address
  address: string;
  addressInfo: PositionStack;

  isLoading: boolean = false;
  loading_panel: boolean = false;
  loadPlotVisible = false;

  @ViewChild('olMap') olMap: OlMapComponent;

  search = async () => {
    if (!this.address) {
      return;
    }

    this.isLoading = true;
    const response: PositionStack = await firstValueFrom(
      this.positionStackService.getLocationByAddress(this.address)
    );
    this.isLoading = false;

    this.addressInfo = response;

    if (!response) {
      return;
    }

    const { latitude, longitude } = this.addressInfo;

    this.olMap.moveTo(latitude, longitude, 16);
  };

  searchButton = {
    icon: 'search',
    stylingMode: 'text',
    onClick: this.search,
  };

  ngOnInit(): void {
    const subscription = this.mediaQueryService.mediaQueryChange$.subscribe(
      (x) => {
        this.currentLayout = x;
        this.adjustLayout(x.mobileLayout);
      }
    );

    this.subscriptions.add(subscription);
  }

  ngOnDestroy(): void {
    this.subscriptions.unsubscribe();
  }

  mapReady() {

    this.getUserPosition().then(({ lng, lat }) => {

      const pointHelper: olMapPoint = new olMapPoint();

      const locationData: LocationData = {
        inputSrID: 4326,
        outputSrID: 4326,
        format: 'GEOJSON',
        coordinates: { geographyType: 'Point', values: [{x: lng, y: lat}] },
        setCoordinates: () => null,
        constructWithArrayCoordinates: () => null,
        constructWithStringCoordinates: () => null
      };

      const commonEntity: ICommonEntity = { name: null, locationData, commonID: null, entityName: null, elementType: null, elementTypeID: null, modelType: null };
      const point = pointHelper.toMapFeature(commonEntity, null, null, null, 'markers/user.png', false);
      this.points = [point];
      
      //this.olMap.moveTo(lat, lng, this.zoom);
    }, 
    ((err) => null));
  }

  getUserPosition(): Promise<any> {
    return new Promise((resolve, reject) => {
      navigator.geolocation.getCurrentPosition(resp => 
        resolve({ lng: resp.coords.longitude, lat: resp.coords.latitude }),
        err => reject(err)
      );
    });
  }

  mapClicked(clickEvent: { coordinates: [number, number]; data: any }) {
    const selectedPolygon = clickEvent?.data?.getProperties?.();

    if (!!selectedPolygon) {
      const id = selectedPolygon?.entity?.plotID;

      let i = this.polygons.findIndex((x) => x.entity.plotID === id);
      if(i < 0){
        this.selectedPlot = undefined;
        return;
      }

      this.selectedPlot = this.polygons[i];
      this.indexOfFirstPlotSelected = i;
    } else {
      this.selectedPlot = undefined;
    }
  }

  mapZoomed(zoomEvent: { zoom: number; extentView: number[] }) {
    if (!zoomEvent.extentView) {
      return;
    }

    if(zoomEvent.zoom >= 17.5){
      this.loadPlotVisible = true;
    }
    else{
      this.loadPlotVisible = false;
    }

    //Coordinates should be grouped as x: long, y: lat
    this.mapBoundaries = [
      { x: zoomEvent.extentView[0], y: zoomEvent.extentView[1] }, //coord 1
      { x: zoomEvent.extentView[2], y: zoomEvent.extentView[1] }, //coord 2
      { x: zoomEvent.extentView[2], y: zoomEvent.extentView[3] }, //coord 3
      { x: zoomEvent.extentView[0], y: zoomEvent.extentView[3] }, //coord 4
    ];

    //Repeat first coord to close the polygon
    this.mapBoundaries.push(this.mapBoundaries[0]);
    this.zoom = zoomEvent.zoom;
  }

  elementHovered(hoverEvent) {
    // console.log('hoverEvent', hoverEvent);
  }

  adjustLayout(mobileLayout: boolean) {
    const drawerLayout = mobileLayout
      ? this.drawerMobileLayout
      : this.drawerDefaultLayout;
    const previousDrawerLayout = this.drawerLayout;

    if (
      previousDrawerLayout.open !== drawerLayout.open ||
      previousDrawerLayout.position !== drawerLayout.position
    ) {
      this.selectedPlot = undefined;
    }

    this.drawerLayout = drawerLayout;
  }

  loadPlotsByArea() {
    this.indexOfFirstPlotSelected = undefined;
    this.loading_panel = true;
    let plotSubs =this.plotService
      .queryView({ platformID: this.platformService.platformID }, this.mapBoundaries, 'CONTAINS')
      .subscribe({
        next: (res : Plot[]) => {
          let polyDS: olMapPolygon[] = [];
          polyDS.push(...this.polygons);

          res = res.sort((a,b)=> { if(a.locationCode < b.locationCode){ return -1; } if(a.locationCode > b.locationCode){ return 1;} return 0;});
          res.forEach((p: Plot) => {
            if (!polyDS.some((t1) => t1.id.includes(p.plotID))) {
              let color : string = Cons.OLSTYLES.$primaryColor;
              let background : string =  Cons.OLSTYLES.$primaryBackground;

              if(p.platformPlot && p.platformPlot.length != 0){
                color = Cons.OLSTYLES.$acceptedColor;
                background = Cons.OLSTYLES.$acceptedBackground;
              } 

              polyDS.push(this.olMapHelperService.OlMapPolygon.toMapFeature(p, Cons._PLOT, color, background));
            }
          });

          this.polygons = polyDS;
          this.loading_panel = false;
        },
        error: (err) => {
          this.loading_panel = false;
        },
        complete: () => {
          this.loading_panel = false;
        }
      });

      this.subscriptions.add(plotSubs);
  }

  closePlotForm = () => {
    this.selectedPlot = undefined;
  };

  selectNextPlot = () => {
    let i = this.polygons.findIndex((x) => x.entity.plotID === this.selectedPlot.entity.plotID);

    if (i === -1) {
      this.closePlotForm();
      return;
    }

    this.olMap.changeMapPolygonStyle(this.olMap, this.polygons[i].id, Cons.OLSTYLES.$acceptedColor, Cons.OLSTYLES.$acceptedBackground, 2)    
    i++;

    const nextIndexIsValid = i < this.polygons.length;

    if (!nextIndexIsValid) {
      i = 0;
    }

    if (this.indexOfFirstPlotSelected === i) {
      this.selectedPlot = undefined;
      return;
    }

    const nextPlot = this.polygons[i];
    let { centerExtent } = nextPlot;
    this.selectedPlot = nextPlot;

    this.olMap.setDefaultFeatureStyle(this.olMap);
    this.olMap.selectMapFeature(this.olMap, nextPlot.id);

    centerExtent = this.olMapHelperService.transformToLonLat(centerExtent);
    this.olMap.moveTo((centerExtent.coordinates[1]*1.00001), centerExtent.coordinates[0], this.zoom);
  };

  plotAddressUpdated(plot: Plot) {
    this.selectedPlot.entity.plotAttributes = plot.plotAttributes;
  }

  platformPlotChange(platformPlot: PlatformPlot) {
    this.selectedPlot.entity.platformPlot[0] = platformPlot;
  }
}
