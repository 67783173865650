import { Injectable } from '@angular/core';
import { BehaviorSubject, Subject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class MapInteractionService {
  private elementClickedSource = new BehaviorSubject<any>(null);
  elementClicked$ = this.elementClickedSource.asObservable();


  private searchClickedSource = new BehaviorSubject<any>(null);
  searchClicked$ = this.searchClickedSource.asObservable();



  updateElementClicked(data: any) {
    this.elementClickedSource.next(data);
  }

  searchClicked(data: any) {
    this.searchClickedSource.next(data);
  }


}