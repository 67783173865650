<div class="homepage-container pt-4 px-5">
    <div class="d-flex flex-column flex-sm-row justify-content-between align-items-stretch align-items-sm-center mb-3">
        <label class="main-secondary-text mb-3 mb-sm-0">{{ gridTitle }}</label>
        <dx-button
            *ngIf="addButtonNewItem"
            class="button main-background-theme"
            [text]="addButtonTitle"
            (click)="addNewItem.emit()"
        >
        </dx-button>
    </div>

    <common-filter 
    [entityTypeModels] = "entityTypeModels"
    [showDescription]="true"
    [showElementType]="true"
    [entityType]="entityType"
    (onSearchPerformed)="search($event)"
    ></common-filter>
    <ng-content select="[grid]"></ng-content>
</div>
  
<dx-popup
  [visible]="showDeletePopup"
  (visibleChange)="showDeletePopupChange.emit($event)"
  [closeOnOutsideClick]="true"
  [title]="deleteButtonTooltip"
  [showTitle]="true"
  [height]="175"
  [dragEnabled]="false"
>
  <div *dxTemplate="let data of 'content'">
    <app-confirmation-modal
      [message]="deleteWarningMessage"
      [confirmButtonMessage]="'general.delete' | translate"
      (userConfirm)="deleteItem.emit(selectedItem)"
      (cancel)="showDeletePopupChange.emit(false)"
    ></app-confirmation-modal>
  </div>
</dx-popup>

<ng-container *ngIf="showCreateOrEditPopup && selectedItem">
  <dx-popup
    [visible]="showCreateOrEditPopup"
    (visibleChange)="showCreateOrEditPopupChange.emit($event)"
    [closeOnOutsideClick]="false"
    [showTitle]="true"
    [title]="modalTitle"
    [dragEnabled]="false"
    [height]="'90%'"
    [maxWidth]="'50%'"
  >
    <div *dxTemplate="let data of 'content'">
      <dx-scroll-view>
        <ng-content select="[create-edit-modal]"></ng-content>
      </dx-scroll-view>
    </div>
  </dx-popup>
</ng-container>

<dx-load-panel
  shadingColor="rgba(0,0,0,0.4)"
  [visible]="loadingModal"
  [showIndicator]="true"
  [showPane]="true"
  [shading]="true"
  [closeOnOutsideClick]="false"
>
</dx-load-panel>
