import { Type } from 'class-transformer';
import { EquipmentType } from './equipmentType.model';
import { Infrastructure } from './infrastructure.model';
import { EquipmentPort } from './equipmentPort.model';



export class Equipment {
  equipmentID?: number;
  platformID: number;
  equipmentTypeID: number;
  infrastructureID : number;
  name: string;
  description: string;
  equipmentAttributes: any;
  ports: any;


  @Type(() => EquipmentPort)
  equipmentPort?: EquipmentPort[];

  @Type(()=> EquipmentType)
  equipmentType?: EquipmentType;

  @Type(()=> Infrastructure)
  infrastructure?: Infrastructure;
  id: any;
}
