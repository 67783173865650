import { Component, Input, OnInit, OnChanges, SimpleChanges } from '@angular/core';
import * as QRCode from 'qrcode';
import { saveAs } from 'file-saver';
import { TranslateService } from '@ngx-translate/core';


@Component({
  selector: 'app-qrcode',
  templateUrl: './qr-code.component.html',
  styleUrls: ['./qr-code.component.scss']
})
export class QRCodeComponent implements OnInit, OnChanges {
  @Input('qrData') qrData = {};
  qrCodeImages: string[] = [];
  @Input() index: number;
  @Input() firstPort1: string | null = null;
  @Input() lastPort1: string | null = null;
  @Input() isDynamicQR: boolean;
  @Input() equipmentID: number;
  @Input() nombreEquipoConcatenado: string;
  @Input() infrastructureFullName: string;



  constructor(private translate: TranslateService) { }

  ngOnInit(): void {


    if (this.isDynamicQR == true) {
      this.generateDynamicQRCode();
    } else {
      this.generateQRCodes();
    }

  }

  ngOnChanges(changes: SimpleChanges): void {

    if (this.isDynamicQR != true) {

      if (changes['qrData'] && !changes['qrData'].firstChange) {
        this.generateQRCodes();
      }

    }

  }

  private generateQRCodes(): void {
    this.qrCodeImages = [];


    let qrString = JSON.stringify(this.qrData, null, 2);



    QRCode.toDataURL(qrString)
      .then(url => {
        this.qrCodeImages.push(url);
      })
      .catch(err => {
        console.error('Error generating QR Code:', err);
      });



  }

  downloadQRCode(): void {

    if (this.qrCodeImages.length === 0) {
      console.error('No hay imágenes de códigos QR generadas para descargar.');
      return;
    }

    const qrImage = this.qrCodeImages[0];

    // Draw dynamic QR
    if (this.isDynamicQR) {
      const canvas = document.createElement('canvas');
      const context = canvas.getContext('2d');
      const img = new Image();

      img.onload = () => {
        const padding = 0;
        const marginLeft = 20;
        const marginRight = 20;
        const marginTop = 10;
        let textHeight = 30;
        let labelHeight = 30;
        let infrastructureHeight = 20;

        const scaleFactor = 2.5;
        const qrWidth = img.width * scaleFactor;
        const qrHeight = img.height * scaleFactor;

        canvas.width = qrWidth;
        canvas.height = qrHeight + textHeight + padding + labelHeight + infrastructureHeight;

        let fontSize = 20;
        context.font = `bold ${fontSize}px Arial`;
        context.fillStyle = 'black';
        context.textBaseline = 'middle';

        let textWidth = context.measureText(this.nombreEquipoConcatenado).width;

        while (textWidth > qrWidth - marginLeft - marginRight) {
          fontSize--;
          context.font = `bold ${fontSize}px Arial`;
          textWidth = context.measureText(this.nombreEquipoConcatenado).width;
          textHeight = fontSize + 10;
        }

        canvas.height = qrHeight + textHeight + padding + labelHeight + infrastructureHeight;

        context.drawImage(img, 0, textHeight + padding, qrWidth, qrHeight);
        context.fillStyle = 'white';
        context.fillRect(0, canvas.height - labelHeight - infrastructureHeight - padding / 2, canvas.width, labelHeight + infrastructureHeight + padding / 2);
        context.fillStyle = 'black';
        context.textAlign = 'left';

        // Dibuja infrastructureFullName primero (en la parte superior)
        context.font = `bold ${fontSize}px Arial`;
        context.fillText(this.infrastructureFullName, padding + marginLeft, marginTop + canvas.height - labelHeight / 2 - textHeight - infrastructureHeight + infrastructureHeight / 2);

        // Dibuja nombreEquipoConcatenado debajo de infrastructureFullName
        context.font = `bold ${fontSize}px Arial`;
        context.fillText(this.nombreEquipoConcatenado, padding + marginLeft, (marginTop + 10) + canvas.height - labelHeight / 2 - textHeight);

        canvas.toBlob(blob => {
          saveAs(blob, `${this.nombreEquipoConcatenado}_dynamic_${this.equipmentID}.png`);
        });
      };

      img.onerror = err => {
        console.error('Error al cargar la imagen QR:', err);
      };

      img.src = qrImage;

      // Draw static QR
    } else {

      const canvas = document.createElement('canvas');
      const context = canvas.getContext('2d');
      const img = new Image();

      img.onload = () => {
        const padding = 0;
        const marginLeft = 20;
        const marginRight = 20;
        const marginTop = 10;
        let textHeight = 30;
        let labelHeight = 30;
        let infrastructureHeight = 20;

        canvas.width = img.width;
        canvas.height = img.height + textHeight + padding + labelHeight + infrastructureHeight;

        let fontSize = 20;
        context.font = `bold ${fontSize}px Arial`;
        context.fillStyle = 'black';
        context.textBaseline = 'middle';

        let textWidth = context.measureText(this.qrData["name"]).width;

        while (textWidth > img.width - marginLeft - marginRight) {
          fontSize--;
          context.font = `bold ${fontSize}px Arial`;
          textWidth = context.measureText(this.qrData["name"]).width;
          textHeight = fontSize + 10;
        }

        canvas.height = img.height + textHeight + padding + labelHeight + infrastructureHeight;
        context.drawImage(img, 0, textHeight + padding);
        context.fillStyle = 'white';
        context.fillRect(0, canvas.height - labelHeight - infrastructureHeight - padding / 2, canvas.width, labelHeight + infrastructureHeight + padding / 2);
        context.fillStyle = 'black';
        context.textAlign = 'left';
        context.font = `bold ${fontSize}px Arial`;

        // Draw the infrastructureFullName first (above nombreEquipoConcatenado)
        context.fillText(this.infrastructureFullName, padding + marginLeft, marginTop + canvas.height - labelHeight / 2 - textHeight - infrastructureHeight + infrastructureHeight / 2);

        // Draw the QR name (nombreEquipoConcatenado) below infrastructureFullName
        context.fillText(this.qrData["name"], padding + marginLeft, (marginTop+10) + canvas.height - labelHeight / 2 - textHeight);

        // Draw the translated first and last port
        const translatedText = this.translate.instant('infrastructure-odf.port', { firstPort: this.firstPort1, lastPort: this.lastPort1 });
        context.fillText(translatedText, padding + marginLeft, (marginTop+20) + canvas.height - labelHeight / 2 - infrastructureHeight);

        canvas.toBlob(blob => {
          saveAs(blob, `${this.qrData["name"]}_slot_${this.index}.png`);
        });
      };

      img.onerror = err => {
        console.error('Error al cargar la imagen QR:', err);
      };

      img.src = qrImage;
    }
  }

  generateDynamicQRCode(): void {
    const dynamicUrl = `https://qr.dev.fiberprosolution.com/home/${this.equipmentID}`;

    QRCode.toDataURL(dynamicUrl)
      .then(url => {
        this.qrCodeImages.push(url);
      })
      .catch(err => {
        console.error('Error generating Dynamic QR Code:', err);
      });
  }


}

