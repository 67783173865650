import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { ContainerHomeComponent } from './general/container/home/container-home.component';
import { DeviceHomeComponent } from './general/device/home/device-home.component';
import { ElementTypeHomeComponent } from './general/element-type/home/element-type-home.component';
import { EquipmentTypeHomeComponent } from './general/equipment-type/home/equipment-type-home.component';
import { FeasibilityHomeComponent } from './general/feasibility/home/feasibility-home.component';
import { MapViewerComponent } from './general/map-viewer/map-viewer.component';
import { SplitterTypeComponent } from './general/splitter-type/home/splitter-type.component';
import { HomeSurveyComponent } from './general/survey/home/home-survey.component';
import { TraceHomeComponent } from './general/trace/home/trace-home.component';
import { UsersHomeComponent } from './general/users/home/users-home.component';
import { InfrastructureHomeComponent } from './general/infrastructure/home/infrastructure-home.component';
import { TraceDiagramComponent } from './@shared/components/d3/horizontal-tree-container-device/trace-diagram/trace-diagram.component';

const routes: Routes = [
  { path: 'map-viewer', component: MapViewerComponent },
  { path: 'survey', component: HomeSurveyComponent },
  { path: 'feasibility', component: FeasibilityHomeComponent },
  { path: 'devices', component: DeviceHomeComponent },
  { path: 'element-type', component: ElementTypeHomeComponent },
  { path: 'equipment-type', component: EquipmentTypeHomeComponent },
  { path: 'trace', component: TraceHomeComponent },
  { path: 'container', component: ContainerHomeComponent },
  { path: 'splitter-type', component: SplitterTypeComponent },
  { path: 'users', component: UsersHomeComponent },
  { path: 'infrastructure', component: InfrastructureHomeComponent },
  { path: ':platform/trace-diagram/:mslink', component: TraceDiagramComponent },
  { path: 'trace-diagram', component: TraceDiagramComponent },
  //Fallback when no prior route is matched
  { path: '**', redirectTo: '', pathMatch: 'full' },
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule],
  providers: [],
})
export class AppRoutingModule { }
