<div class="main">

    <div class="header floating-container">
        <div class="row align-items-center">

            <div class="col-auto">
                <h3 class="title-header">
                    {{ containerName }}
                </h3>
            </div>

            <div class="col-auto">
                <div class="button-container my-3">
                    <dx-button icon="fas fa-file-download" class="button main-background-theme"
                        [text]="'general.download' | translate" (click)="downloadD3Graph()">
                    </dx-button>
                </div>
            </div>

            <div class="col-auto">
                <div class="select-container d-flex flex-wrap justify-content-end">
                    <select #graphSelect (change)="onGraphSelect(graphSelect.value)" placeholder="Seleccionar gráfico">
                        <option *ngFor="let graph of graphs" [value]="graph.name">{{ graph.name }}</option>
                    </select>
                </div>
            </div>

            <div class="col-auto">
                <div class="search">
                    <input type="text" [(ngModel)]="searchTerm" placeholder="Buscar..." (input)="highlightMatches()" />

                    <div class="buttons-container">
                        <dx-button icon="fas fa-angle-left" (click)="previousMatch()"></dx-button>
                        <p class="match-info" *ngIf="match.length > 0">{{ currentMatchIndex + 1 }}/{{ match.length }}
                        </p>
                        <dx-button icon="fas fa-angle-right" (click)="nextMatch()"></dx-button>
                    </div>

                </div>
            </div>

            <!-- <div class="col-auto"> 
            <div class="search-container d-flex align-items-center">
                <input type="text" [(ngModel)]="serviceName" placeholder="Buscar..." (ngModelChange)="searchService()">
                <dx-button class="button search-button" [text]="'anterior' | translate" >
                </dx-button>
                <dx-button class="button reset-button" [text]="'siguiente' | translate" >
                </dx-button>
            </div>
        </div> -->

        </div>
    </div>

    <div class="tab-panel">
        <dx-tab-panel>
            <dxi-item title="Cables asociados"></dxi-item>
        </dx-tab-panel>
    </div>
</div>

<div class="graph" style="max-height: 100%; overflow-y: auto;" (scroll)="onScroll($event)">
    <div #svgContainer class="graphs"></div>
  </div>
  
  <dx-popup *ngIf="displayTraceabilityDiagramModal" [(visible)]="displayTraceabilityDiagramModal"
            [closeOnOutsideClick]="true" [title]="fiberNameSelected" [showTitle]="true" [height]="750"
            [width]="500">
    <div *dxTemplate="let data of 'content'">
        <app-traceability-diagram [traceabilityDiagram]="traceabilityDiagramData"></app-traceability-diagram>
    </div>
</dx-popup>

<dx-load-panel shadingColor="rgba(0,0,0,0.4)" [visible]="loading" [showIndicator]="true" [showPane]="true"
    [shading]="true" [closeOnOutsideClick]="false">
</dx-load-panel>