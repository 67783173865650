import { Component, Input, OnInit, OnChanges, SimpleChanges, Output, EventEmitter } from '@angular/core';
import { IStep } from '@app/@shared/model/interface/iStep.model';
import { SingleNode, ITraceabilityDiagram } from '@app/@shared/model/interface/iTraceability-diagram';

@Component({
  selector: 'app-traceability-diagram',
  templateUrl: './traceability-diagram.component.html',
  styleUrls: ['./traceability-diagram.component.scss'],
})
export class TraceabilityDiagramComponent implements OnInit, OnChanges {
  @Input() showDistance: boolean;
  @Input() traceabilityDiagram: ITraceabilityDiagram;
  @Input() textBoxValue: string;
  @Output() switchChanged = new EventEmitter<boolean>();
  multiplesSteps: IStep[][] = [];

  constructor() {}

  ngOnInit(): void {
    this.initializeSteps();
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes['traceabilityDiagram']) {
      
      this.initializeSteps();
    }
  }

  initializeSteps(): void {
    if (this.traceabilityDiagram && this.traceabilityDiagram.traceability) {
      const multipleNodes: SingleNode[][] = this.traceabilityDiagram.traceability;
      this.multiplesSteps = multipleNodes.map(nodes => this.convertNodesToSteps(nodes));
      
    } else {
      console.error('Traceability Diagram data is missing or malformed');
    }
  }

  createStep(currentStep: number, title: string, subtitle: string, path: string, elementID: number, type: string): IStep {
    return { id: currentStep, title, subtitle, path: `${path}/${elementID}`, type };
  }

  convertNodeToStep(node: SingleNode, index: number): IStep {
    return this.createStep(index + 1, node.name, node.description, node.path, node.elementID, node.type);
  }

  convertNodesToSteps(nodes: SingleNode[]): IStep[] {
    return nodes.map((node, index) => this.convertNodeToStep(node, index));
  }

  onValueChanged(e: {value: boolean}) {
    console.log("switch", e);
    this.switchChanged.emit(e.value);
}
}
