import { Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges, ViewChild } from '@angular/core';

@Component({
  selector: 'proximity-detail',
  templateUrl: './proximity-detail.component.html',
  styleUrls: ['./proximity-detail.component.scss']
})
export class ProximityDetailComponent implements OnInit, OnChanges {
  public data = [{}];
  public dataAvailableServices = [{}];
  public dataServicesNotAvailable = [{}];
  public selectedRowData: any;

  @Input() dataAvailableServicesReceived: boolean;
  @Input() onProximitySearch: any;
  @Input() selectedServiceOrElement: any;
  @Output() selectedServiceToShow: EventEmitter<any> = new EventEmitter<any>();

  constructor() { }
  ngOnChanges(changes: SimpleChanges): void {
    if (changes['dataAvailableServicesReceived']) {
      if (this.dataAvailableServicesReceived == true) {
        this.getPrincipalHeadendFromService();
      }
    }
  }

  ngOnInit(): void {
    // this.dataAvailableServices = [];
    // this.dataServicesNotAvailable = [];

  }

  showService(event: any) {
    this.selectedRowData = event.data;
    // console.log(this.selectedRowData);
    this.selectedServiceToShow.emit(this.selectedRowData);
  }


  async getPrincipalHeadendFromService(): Promise<void> {
    for (const service of this.dataAvailableServices as any[]) {
      const serviceAny: any = service;

      if (serviceAny.serviceAttributes?.infrastructure) {
        const principalHeadendName: string = await this.getLastHeadendName(serviceAny.serviceAttributes.infrastructure);
        await this.assignPrincipalHeadendToServiceData(serviceAny, principalHeadendName);
      } else {
        await this.assignPrincipalHeadendToServiceData(serviceAny, '-');
      }
    }

    this.dataAvailableServices = (this.dataAvailableServices as any[]).filter((service: any) => service.principalHeadend !== "-");


    for (const service of this.dataServicesNotAvailable as any[]) {
      const serviceAny: any = service;

      if (serviceAny.serviceAttributes?.infrastructure) {
        const principalHeadendName: string = await this.getLastHeadendName(serviceAny.serviceAttributes.infrastructure);
        await this.assignPrincipalHeadendToServiceData(serviceAny, principalHeadendName);
      } else {
        await this.assignPrincipalHeadendToServiceData(serviceAny, '-');
      }
    }
    this.dataServicesNotAvailable = (this.dataServicesNotAvailable as any[]).filter((service: any) => service.principalHeadend !== "-");
    // console.log("Filtered dataServicesNotAvailable:", this.dataServicesNotAvailable);
    this.dataAvailableServicesReceived = false;
  }

  private getLastHeadendName(infrastructure: any[]): string {
    if (infrastructure.length > 0 && infrastructure[infrastructure.length - 1].elementType.filePath == 'headend.png') {
      return infrastructure[infrastructure.length - 1].name;
    }
    return '-';
  }

  private assignPrincipalHeadendToServiceData(service: any, name: string): void {
    service.principalHeadend = name;
  }

}
